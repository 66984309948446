import debounce from "lodash.debounce";
import { calculateRates } from "./ratesCalculator";
import { Bounce, toast } from "react-toastify";
import { CloseButton } from "../../../components/CloseButton";

export const mapSingleCountryDetails = ({
  singleCountryDetails,
  setDeliveryOptions,
  setBeneficiaries,
  setReceiverCurrency,
  setCurrencies,
  senderCurrency,
}) => {
  const { beneficiaries, currencies, deliveryOptions } = singleCountryDetails;

  let mappedDeliveryOptions = deliveryOptions?.map((option) => ({
    ...option,
    name: option.serviceName,
  }));

  let mappedBeneficiaries = beneficiaries?.map((beneficiary) => ({
    ...beneficiary,
    name: beneficiary.firstName + " " + beneficiary.lastName,
  }));

  let mappedReceiverCurrency = currencies?.map((data) => ({
    name: data.currencyCode,
    id: data.id,
  }));

  let addedReceiverCurrencies = mappedBeneficiaries ? mappedReceiverCurrency : [];

  setDeliveryOptions(mappedDeliveryOptions);
  setBeneficiaries(mappedBeneficiaries);
  setReceiverCurrency(mappedReceiverCurrency);
  setCurrencies([...senderCurrency, ...addedReceiverCurrencies]);
};

export const countryData = (countriesData, receivingCountryID) => {
  return countriesData?.filter((country) => country.id === receivingCountryID)[0];
};

export const getBeneficiariesByDeliveryOption = (beneficiaries, deliveryOption) =>
  beneficiaries?.length > 0 ? beneficiaries.filter((beneficiary) => beneficiary[`${deliveryOption}`].length > 0) : [];

export const updateRatesValues = ({
  result,
  setFormData,
  formData,
  receiverCurrency,
  senderCurrency,
  selectedCurrencyID,
  senderCurrencyID,
}) =>
  setFormData({
    ...formData,
    fees: result?.fees,
    exciseDuty: result?.exciseDutyAmount,
    exchangeRate: result?.exchangeRate,
    senderCurrency: senderCurrency[0]?.name,

    receiverCurrency: formData.currencyID !== senderCurrency[0]?.id ? formData.currency : receiverCurrency[0].name,
    receiverAmount: selectedCurrencyID === senderCurrencyID ? result?.recieverAmount : formData?.amount,
    senderAmount: result?.senderAmount,
    discount: result?.discount?.tariffDiscountAmount,
    promoCode: result?.discount?.promotionCode,
  });

export const calculateExchangeRates = async ({
  kycDetails,
  senderCurrency,
  formData,
  receiverCurrency,
  senderService,
  receiverService,
  setFormData,
  setDisableBtn,
  setLoadingRates,
}) => {
  let result = await calculateRates({
    fromCountryID: Number(kycDetails?.countryID),
    fromCurrencyID: senderCurrency[0]?.id,
    toCountryID: formData.receivingCountryID,
    toCurrencyID: formData?.currencyID !== senderCurrency[0]?.id ? formData?.currencyID : receiverCurrency[0]?.id,
    serviceTypeID: formData.deliveryOptionID,
    amount: Number(formData.amount),
    paymentService: `${senderService}2${receiverService}`,

    selectedCurrencyID: formData.currencyID,
    senderCurrencyID: senderCurrency[0]?.id,
    receiverCurrencyID: receiverCurrency[0]?.id,
    setDisableBtn,
    setLoadingRates,
  });

  updateRatesValues({
    result,
    setFormData,
    formData,
    receiverCurrency,
    senderCurrency,
    selectedCurrencyID: formData.currencyID,
    senderCurrencyID: senderCurrency[0]?.id,
  });
  setDisableBtn(false);

  if (result?.discount?.promotionCode) {
    toast.success(
      <div>
        <h3 className="text-center">***Congratulations!***</h3>
        {/* <h6> You've qualified for a {result?.discount?.tariffDiscountAmount}.00 discount.</h6> */}
        <h6>You've qualified for a free transfer for this transaction.</h6>
      </div>,
      {
        position: "top-center",
        closeButton: CloseButton,
        toastId: "promoToast",
        icon: false,
        autoClose: 10000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
      }
    );
  }
};

// wait for amount change after a second to fetch rates
// export const delayedCurrencyConverterQuery = debounce(
//   async ({
//     kycDetails,
//     senderCurrency,
//     formData,
//     receiverCurrency,
//     senderService,
//     receiverService,
//     setFormData,
//     setDisableBtn,
//   }) => {
//     let result = await calculateRates({
//       fromCountryID: Number(kycDetails?.countryID),
//       fromCurrencyID: senderCurrency[0]?.id,
//       toCountryID: formData.receivingCountryID,
//       toCurrencyID: receiverCurrency[0]?.id,
//       serviceTypeID: formData.deliveryOptionID,
//       amount: Number(formData.amount),
//       paymentService: `${senderService}2${receiverService}`,

//       selectedCurrencyID: formData.currencyID,
//       senderCurrencyID: senderCurrency[0]?.id,
//       receiverCurrencyID: receiverCurrency[0]?.id,
//     });

//     updateRatesValues({
//       result,
//       setFormData,
//       formData,
//       receiverCurrency,
//       senderCurrency,
//     });
//     setDisableBtn(false);
//   },
//   1000
// );
