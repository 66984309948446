import * as Yup from "yup";
import { phoneRegExp } from "../../../../utils/validations/regex";
import { yesterday } from "../../beneficiaryUtil";

export const personalInfoSchema = ({
  paymentService,
  activeServiceProvider,
  documentTypes,
  documentTypeID,
  countryName,
  countryID,
  countrySupportsRelation,
}) =>
  Yup.object().shape({
    nickName: Yup.string().nullable().required("Nick name is required"),
    firstName:
      paymentService === "C2C" || countryID === 198
        ? Yup.string()
            .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed")
            .required("First Name is required")
        : Yup.string(),
    lastName:
      paymentService === "C2C" || countryID === 198
        ? Yup.string()
            .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed")
            .required("Last Name is required")
        : Yup.string(),

    phoneNumber:
      countryID !== 198 && !countrySupportsRelation
        ? Yup.string().matches(phoneRegExp, "Phone number is not valid").required("Mobile number is required")
        : Yup.string(),
    documentTypeID:
      (activeServiceProvider === "MASTERCARD" || countryName === "UNITED ARAB EMIRATES") && documentTypes.length > 0
        ? Yup.string().required("Document type is required")
        : Yup.string(),
    idNumber:
      (activeServiceProvider === "MASTERCARD" || countryName === "UNITED ARAB EMIRATES") && documentTypes.length > 0
        ? Yup.string().required("Document number is required")
        : Yup.string().nullable(),
    documentExpiresOn:
      (activeServiceProvider === "MASTERCARD" || countryName === "UNITED ARAB EMIRATES") &&
      documentTypeID !== 29 &&
      documentTypes.length > 0
        ? Yup.string()
            .nullable()
            .test("documentExpiresOn", "Date cannot be in the past", function (value) {
              let val = new Date(value);
              return val >= yesterday();
            })
            .required("Expiry date is required")
        : Yup.string().nullable(),
    businessDescription:
      paymentService === "C2B" && countryID === 198
        ? Yup.string()
            .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed")
            .required("Business Description is required")
        : Yup.string().nullable(),
    businessRegistrationIssuedBy:
      paymentService === "C2B" && countryID === 198
        ? Yup.string()
            .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed")
            .required("Business Registration Issuer is required")
        : Yup.string().nullable(),
    typeOfBusiness:
      paymentService === "C2B" && countryID === 198
        ? Yup.string()
            .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed")
            .required("Type Of Business is required")
        : Yup.string().nullable(),
  });
