import { useEffect, useState } from "react";
import axios from "../api/axios/axios";

export default function useFetchBridgeBankBranches(
  countryId,
  bankCode,
  paymentService
) {
  const [bridgeBankBranches, setBridgeBankBranches] = useState([]);

  useEffect(() => {
    if (countryId && bankCode && paymentService) {
      axios
        .get(
          `/api/Utility/GetBridgeBankBranches/${countryId}/${bankCode}/${paymentService}`
        )
        .then((res) => {
          setBridgeBankBranches(res.data);
        })
        .catch((err) => {
          setBridgeBankBranches([]);
        });
    }
  }, [countryId, bankCode, paymentService]);

  return { bridgeBankBranches, setBridgeBankBranches };
}
