import { useEffect, useState } from "react";
import axios from "axios";

export default function useFetchCountryDeliveryOptionFields(serviceTypeID, countryID) {
  const [countryDeliveryOptionFields, setCountryDeliveryOptionFields] = useState([]);
  const fieldType = (fieldName, formInputType) => {
    let countriesIDs = [13, 224, 208, 198];
    switch (fieldName) {
      case "bankName":
        return countriesIDs.includes(countryID) ? "Select" : formInputType;
      case "bankBranch":
        return countriesIDs.includes(countryID) ? "Select" : formInputType;
      case "accountNumber":
        return "Password";
      case "confirmAccountNumber":
        return "Password";
      default:
        return "Text";
    }
  };

  let orderField = (field, index) => {
    switch (field) {
      case "swiftCode":
        return 1;
      case "bankName":
        return 2;
      case "bankBranch":
        return 3;
      case "bankBranchCode":
        return 4;
      case "accountName":
        return 5;
      case "accountNumber":
        return 6;
      case "confirmAccountNumber":
        return 7;
      case "sortCode":
        return 8;
      case "confirmSortCode":
        return 9;
      case "phoneNumber" || "bankPhoneNumber":
        return 10;
      default:
        return index;
    }
  };

  useEffect(() => {
    if (serviceTypeID && countryID) {
      axios
        .get(
          `/api/CountryDeliveryOptionFields/GetCountryDeliveryOptionFields?ServiceTypeID=${serviceTypeID}&CountryID=${countryID}`,
          { withCredentials: false }
        )
        .then((res) => {
          let mappedData = res.data.result.map((data) => ({
            ...data,
            index: orderField(data.fieldName, data.index),
            formInputType: fieldType(data.fieldName, data.formInputType),
          }));

          mappedData.sort((a, b) => (a.index > b.index ? 1 : -1));

          return setCountryDeliveryOptionFields(mappedData);
        })
        .catch((err) => {
          setCountryDeliveryOptionFields([]);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceTypeID, countryID]);

  return { countryDeliveryOptionFields, setCountryDeliveryOptionFields };
}
