import React, { useContext } from "react";

import { FormContext } from "../../../Beneficiary";
import MobileDetails from "./MobileDetails";

function MobileDetailsContainer() {
  const { activeStepIndex, setActiveStepIndex, formData } = useContext(FormContext);

  const onSubmit = (values) => {
    formData.setPersonalInfo({ ...formData.personalInfo, phoneNumber: values.walletMobileNumber });
    formData.setDeliveryType({ ...formData.deliveryType, mobileDetails: values });
    setActiveStepIndex(activeStepIndex + 1);
  };

  let props = { formData, onSubmit };

  return <MobileDetails props={props} />;
}

export default MobileDetailsContainer;
