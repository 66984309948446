/* eslint-disable array-callback-return */
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { dynamicStyle } from "../utils/FlagList";
import { getCountryName } from "../utils/MobileCodesList";

function SampleNextArrow(props) {
  const { className, onClick } = props;
  return (
    <div
      className={className}
      style={{ padding: "0px", background: "grey", borderRadius: "50%" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, onClick } = props;
  return (
    <div
      className={className}
      style={{ padding: "0px", background: "grey", borderRadius: "50%" }}
      onClick={onClick}
    />
  );
}

const CustomSlider = (props) => {
  var settings = {
    dots: false,
    speed: 1000,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 4000,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const allRates = (item, index) => (
    <div key={index}>
      <table height="100%" width="100%">
        <tbody>
          <tr>
            <td colsapn="2" align="left" className="td-padding" width="100%">
              <h5>
                <b>Exchange Rates</b>
              </h5>
            </td>
          </tr>
          <tr height="20px"></tr>
          <tr className="">
            <td width="50%" className="td-padding text-left">
              <div className="send-to-country  currency-card">
                <h4>{getCountryName(item.total.toCountryCode)}</h4>
                <figure>
                  {/* TOD:: make a sprite */}
                  <div
                    className="flag_img"
                    style={dynamicStyle(item.total.toCountryCode)}
                  ></div>
                  {/* <img src={FlagList(item.total.toCountryCode)} alt="" /> */}
                </figure>
              </div>
              <div>
                <p>{item.total.toCurrencyName}</p>
              </div>
            </td>
            <td width="50%" className="td-padding" valign="top">
              <div className="exchg-rate">
                <h4>
                  {item.total.rate > 1 ? (
                    <>
                      {item.total.fromCurrencyCode} 1 ={" "}
                      {item.total.toCurrencyCode} &nbsp;{item.total.rate}
                    </>
                  ) : (
                    <>
                      {item.total.toCurrencyCode} 1 ={" "}
                      {1 / item.total.rate
                        ? (1 / item.total.rate).toFixed(6)
                        : item.total.rate}{" "}
                      {item.total.fromCurrencyCode}
                    </>
                  )}
                </h4>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );

  return (
    <div>
      <Slider {...settings}>
        {props.filterBy
          ? allRates(
              props.data.filter((item) => {
                return item.toCountryCode === props.filterBy.toCountryCode;
              })[0],
              0
            )
          : props.data &&
            props.data.map((item, index) => {
              // if (item.total.featured === 1) {
              return allRates(item, index);
              // }
            })}
      </Slider>
    </div>
  );
};

export default CustomSlider;
