import { ErrorMessage, Form, Formik } from "formik";
import React, { useContext } from "react";
import Select from "react-select";
import * as Yup from "yup";

import useFetchOperatingRegions from "../../../hooks/useFetchOperatingRegions";
import { CustomStyle, defaultStyle } from "../../../utils/styles";
import { FormContext } from "../Registration";

const ValidationSchema = Yup.object().shape({
  operatingCountryID: Yup.string().required("Select your country"),
});

function Country() {
  const { activeStepIndex, setActiveStepIndex, formData, setFormData } =
    useContext(FormContext);


  const { operatingRegions } = useFetchOperatingRegions();

  const handleOperatingRegion = (val, setFieldValue) => {
    setFieldValue("operatingCountryID", val.id);
  };

  const onSubmit = (values) => {
    let region = operatingRegions.filter(
      (region) => region.id === values.operatingCountryID
    )[0];

    let code = region?.callingCode;

    const data = { ...formData, ...values, code };
    setFormData(data);
    setActiveStepIndex(activeStepIndex + 1);
  };

  const regionName = (regionId) => {
    let region = operatingRegions.filter((region) => region.id === regionId)[0];
    if (region) {
      return region.name;
    }
  };

  return (
    <Formik
      initialValues={{ operatingCountryID: formData.operatingCountryID }}
      validationSchema={ValidationSchema}
      onSubmit={(val) => onSubmit(val)}
    >
      {({
        handleBlur,
        handleSubmit,
        setFieldValue,
        errors,
        touched,
        values,
      }) => (
        <Form
          id="registration-form"
          className="w-100 row justify-content-center "
          onSubmit={handleSubmit}
        >
          <div className="h3 text-center my-4 step-title">
            Where are you located?
          </div>

          <div className="form-group col-12 col-sm-8 col-lg-6 col-xl-4">
            {errors.operatingCountryID}
            <Select
              value={{ name: regionName(values.operatingCountryID) }}
              name="operatingCountryID"
              onChange={(val) => handleOperatingRegion(val, setFieldValue)}
              options={operatingRegions}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              placeholder="Select country"
              onBlur={handleBlur}
              noOptionsMessage={() => "Country is loading..."}
              styles={
                errors.operatingCountryID && touched.operatingCountryID
                  ? CustomStyle
                  : defaultStyle
              }
            />
          </div>
          <div className="error-text col-12 text-center my-2">
            <ErrorMessage name="operatingCountryID" />
          </div>
          <div className="col-12 col-sm-8 col-lg-6 col-xl-4">
            <button className="btn btn-orange w-100" type="submit">
              Next
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default Country;
