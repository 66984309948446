/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import manageBeneficiariesIcon from "../assets/images/icons/beneficiaries-icon.png";
import dashboardIcon from "../assets/images/icons/dashboard-icon.png";
import logoutIcon from "../assets/images/icons/logout.png";
import profileIcon from "../assets/images/icons/profile-icon.png";
import transactionsIcon from "../assets/images/icons/transactions-icon.png";
import logo from "../assets/images/upesi-logo.png";
import LogoutModal from "../components/LogoutModal";

export default function Navigation() {
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const menuRef = useRef();

  const handleLogout = () => {
    localStorage.clear();
    navigate("/login");
  };

  const openModal = () => {
    setModal(true);
    closeSidebar();
  };

  const closeModal = () => {
    setModal(false);
  };

  const closeSidebar = () => {
    menuRef.current.checked = false;
  };

  return (
    <>
      {modal ? (
        <LogoutModal
          show={modal}
          close={closeModal}
          handleLogout={handleLogout}
        />
      ) : null}
      <nav style={{ display: "flex" }} className="desktop-header-wrapper">
        <div className="logo-wrapper">
          <Link to={"/"} style={{ cursor: "pointer" }}>
            <img src={logo} alt="Upesi Logo" />
          </Link>
        </div>
        <div className="header-account-wrapper">
          <nav role="navigation">
            <div id="menuToggle">
              <input ref={menuRef} type="checkbox" />
              <span></span>
              <span></span>
              <span></span>

              <ul id="menu">
                <li>
                  <Link to="/dashboard" onClick={closeSidebar}>
                    <figure>
                      <img src={dashboardIcon} alt="Dashboard" />
                    </figure>
                    <h6>Dashboard</h6>
                  </Link>
                </li>
                <li>
                  <Link to={"/profile"} onClick={closeSidebar}>
                    <figure>
                      <img src={profileIcon} alt="Profile" />
                    </figure>
                    <h6>Profile</h6>
                  </Link>
                </li>
                <li>
                  <Link to={"/transactions"} onClick={closeSidebar}>
                    <figure>
                      <img src={transactionsIcon} alt="Transactions" />
                    </figure>
                    <h6>Transactions</h6>
                  </Link>
                </li>
                <li>
                  <Link to={"/manage-beneficiaries"} onClick={closeSidebar}>
                    <figure>
                      <img src={manageBeneficiariesIcon} alt="Beneficiaries" />
                    </figure>
                    <h6>Manage Beneficiaries</h6>
                  </Link>
                </li>

                <li>
                  <Link to={"/money-request-list"} onClick={closeSidebar}>
                    <figure>
                      <img src={manageBeneficiariesIcon} alt="Beneficiaries" />
                    </figure>
                    <h6>Money Request List</h6>
                  </Link>
                </li>
                <li>
                  <Link to={"/contact"} onClick={closeSidebar}>
                    <figure>
                      <img src={manageBeneficiariesIcon} alt="Beneficiaries" />
                    </figure>
                    <h6>Contact Us</h6>
                  </Link>
                </li>
                <li>
                  <a onClick={openModal}>
                    <figure>
                      <img src={logoutIcon} alt="logout" />
                    </figure>
                    <h6>Logout</h6>
                  </a>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </nav>
    </>
  );
}
