import React, { useState, useEffect } from 'react'
import advert_popup from '../../../../assets/images/advert-popup.jpg'
import { XCircleFill } from 'react-bootstrap-icons'
import styles from './advert_popup.module.css'
const FloatingWindow = ({ onClose }) => {
  const [isDraggable, setIsDraggable] = useState(false)
  const [position, setPosition] = useState({ x: 0, y: 0 })

  useEffect(() => {
    const centerX = (window.innerWidth - 200) / 3
    const centerY = (window.innerHeight - 200) / 3
    setPosition({ x: centerX, y: centerY })
  }, [])

  const handleMouseMove = e => {
    if (isDraggable) {
      setPosition({
        x: position.x + e.movementX,
        y: position.y + e.movementY
      })
    }
  }

  return (
    <div className={`${styles.popup_overlay}`}>
      <div
        className={`${styles.popup_content}`}
        style={{
          position: 'absolute',
          left: `${position.x}px`,
          top: `${position.y}px`,
          cursor: isDraggable ? 'grabbing' : 'grab'
        }}
        onMouseDown={() => setIsDraggable(true)}
        onMouseUp={() => setIsDraggable(false)}
        onMouseMove={handleMouseMove}
        onMouseLeave={() => setIsDraggable(false)}
      >
        <button className={`${styles.close_btn}`} onClick={onClose}>
          <XCircleFill />
        </button>
        <div className={`${styles.advert_popup}`}>
          <img src={advert_popup} alt='advert-popup' />
        </div>
      </div>
    </div>
  )
}

export default FloatingWindow
