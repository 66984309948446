import { useEffect, useState } from "react";
import axios from "../api/axios/axios";

export default function useFetchBeneficiaryRelationships() {
  const [beneficiaryRelationships, setBeneficiaryRelationships] =
    useState(null);

  useEffect(() => {
    let isMounted = true;
    axios
      .get(`/api/Beneficiary/GetRelationships`)
      .then((res) => {
        if (isMounted) setBeneficiaryRelationships(res.data);
      })
      .catch((err) => {
        if (isMounted) setBeneficiaryRelationships(null);
      });

    return () => (isMounted = false);
  }, []);

  return { beneficiaryRelationships, setBeneficiaryRelationships };
}
