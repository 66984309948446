import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ContentLoader from "react-content-loader";
import axios from "../api/axios/axios";
import { useNavigate } from "react-router-dom";
import  {
  getBeneficiaryServiceType,
} from "../hooks/useFetchAllBeneficiaryDetailsById";

export default function FeaturedBeneficiaries() {
  const navigate = useNavigate();
  const [beneficiaries, setBeneficiaries] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [moneyRequestList, setMoneyRequestList] = useState([]);

  useEffect(() => {
    RequestMoneyList();
    axios
      .post(`/api/Beneficiary/GetBeneficiaries`, { all: true })
      .then((res) => {
        if (res.data.items) {
          setBeneficiaries(res.data.items.slice(0, 3));
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        setBeneficiaries([]);
      });

    return () => {
      setBeneficiaries([]); // unmount the state
    };
  }, []);

  const RequestMoneyList = () => {
    axios
      .get(`/api/Beneficiary/GetBeneficiaryTransactionRequests/`)
      .then((res) => {
        setMoneyRequestList(res.data.items);
      })
      .catch((err) => {});
  };

  const contentLoader = (index) => (
    <div key={index} className="single-beneficiary">
      <ContentLoader
        speed={2}
        width={94}
        height={90}
        viewBox="0 0 94 90"
        backgroundColor="#091837"
        foregroundColor="#ffffff"
      >
        <rect x="3" y="66" rx="0" ry="0" width="81" height="9" />
        <rect x="3" y="81" rx="0" ry="0" width="81" height="5" />
        <rect x="14" y="6" rx="10" ry="10" width="60" height="53" />
      </ContentLoader>
    </div>
  );

  const editMoneyTransfer = async (obj) => {
    await getBeneficiaryServiceType(obj, navigate);
  };

  return (
    <div className="div-60 money-half-input order-first">
      <div className="beneficiary-row">
        {moneyRequestList?.length ? (
          moneyRequestList?.length === 1 ? (
            <div className="alert-box-req-mon">
              You've got a request to Send Money to{" "}
              {moneyRequestList[0].recipientFirstName}{" "}
              {moneyRequestList[0].recipientLastName}.
              <button
                className="alert-box-req-mon-btn"
                onClick={() => navigate("/money-request-list")}
              >
                Click here
              </button>
            </div>
          ) : (
            <div className="alert-box-req-mon">
              You've got multiple requests to Send Money.
              <button
                className="alert-box-req-mon-btn"
                onClick={() => navigate("/money-request-list")}
              >
                Click here
              </button>
            </div>
          )
        ) : (
          <div></div>
        )}
        {isLoading
          ? [1, 2, 3].map((_, index) => contentLoader(index))
          : beneficiaries.map((beneficiary, index) => (
              <div
                key={index}
                className="single-beneficiary"
                onClick={() => editMoneyTransfer(beneficiary)}
              >
                <div className="add-beneficiary-div">
                  <div className="add-beneficiary-initial dark-blue-bg">
                    <span>
                      {beneficiary.firstName
                        ? beneficiary.firstName.charAt(0).toUpperCase() +
                          beneficiary.lastName.charAt(0).toUpperCase()
                        : "NA"}
                    </span>
                  </div>
                  <div className="add-beneficiary-name">
                    <p>{beneficiary.firstName}</p>
                    <p>{beneficiary.lastName}</p>
                  </div>
                </div>
              </div>
            ))}

        <div className="single-beneficiary">
          <Link to="/add-beneficiary" className="link-text">
            <div className="add-beneficiary-div">
              <div className="add-beneficiary">
                <p>
                  <i className="fa fa-plus" aria-hidden="true"></i>
                </p>
              </div>
              <div className="add-beneficiary-name">
                <p>Add Beneficiary</p>
                <p></p>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}
