import { useContext, useState } from "react";
import * as Yup from "yup";
import DocPreviewModal from "../../../components/DocPreviewModal";
import { SUPPORTED_FORMATS } from "../../../utils/fileToDataUri";
import { FormContext } from "../SendMoney";
import UpesiFormik from "../UpesiFormik";

export default function AdditionalInformation() {
  const {
    setActiveStepIndex,
    formData,
    fields,
    onSubmit,
    handleValueChange,
    doc,
    complianceAmountLimit,
    paymentService, 
    fileRef,
  } = useContext(FormContext);

  const initialStepData = {
    ...formData,
    isPurposeOfSending: true,
    isSourceOfFund: true,
  };

  const [isOpenDocPreview, setIsOpenDocPreview] = useState(false);

  const openDocPreview = () => {
    setIsOpenDocPreview(true);
  };

  const closeDocPreview = () => {
    setIsOpenDocPreview(false);
  };

  const stepValidationSchema = () =>
    Yup.object().shape({
      purposeOfSending: Yup.string()?.when("isPurposeOfSending", {
        is: true,
        then: Yup.string().required("Select Purpose of Sending"),
        otherwise: Yup.string(),
      }),
      sourceOfFund: Yup.string()?.when("isSourceOfFund", {
        is: true,
        then: Yup.string().required("Select Source of funds"),
        otherwise: Yup.string(),
      }),
      cashPickupLocation:
        formData.deliveryOptionID === 2 &&
        Yup.string().required("Cash pickup location is required"),
      docUpload:
        Number(formData.senderAmount) >=
          complianceAmountLimit?.complianceAmount || paymentService === "C2B"
          ? Yup.mixed()
              .required("Select your Document to Upload")
              .test("fileType", "Please select a valid document", (value) =>
                SUPPORTED_FORMATS.includes(value?.type)
              )
              .test(
                "fileSize",
                "File size is more than 1MB",
                (value) => !value || (value && value?.size <= 1048576)
              )
          : Yup.string(),
    });

  const filteredAdditionalInfoFields = fields["additionalInformation"].filter(
    (field) => field.show
  );

  const propsData = {
    setActiveStepIndex,
    formData, 
    stepFields: filteredAdditionalInfoFields,
    initialStepData,
    onSubmit,
    handleValueChange,
    stepValidationSchema,
    formId: "additional-information-info",
    doc,
    isOpenDocPreview,
    openDocPreview,
    closeDocPreview,
    fileRef,
  };

  return (
    <>
      {openDocPreview && (
        <DocPreviewModal
          isOpenDocPreview={isOpenDocPreview}
          doc={doc}
          closeDocPreview={closeDocPreview}
          docFile={formData?.docUpload}
        />
      )}
      <UpesiFormik props={propsData} />
    </>
  );
}
