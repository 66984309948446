import { ErrorMessage, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { ArrowUpRight } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";

import axios from "../api/axios/axios";
import EncryptPassword from "../utils/EncrypPassword";
import { setSessionData } from "../utils/session";

const LoginSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
  password: Yup.string().min(6, "Too Short!").max(20, "Too Long!").required("Required"),
});
const LoginWithOTPSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
  password: Yup.string().min(6, "Too Short!").max(20, "Too Long!").required("Required"),
  OTP: Yup.string().min(6, "Invalid").max(6, "Invalid").required("Required"),
});

const Login = (props) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [resendLoading, setResendLoading] = useState();
  const [error, setError] = useState("");
  const [showOTP, setShowOTP] = useState(false);

  const [seconds, setSeconds] = useState();
  const [isActive, setIsActive] = useState(false);
  const [resend, setResend] = useState(false);

  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [hidePassword] = useState(true);

  function startTimer() {
    setSeconds(120);
    setIsActive(true);
  }

  useEffect(() => {
    let interval = null;
    if (isActive) {
      interval = setInterval(() => {
        setSeconds((seconds) => seconds - 1);
      }, 1000);
      if (seconds === 0) {
        clearInterval(interval);
        setShowOTP(false);
        setResend(true);
      }
    } else if (!isActive && seconds !== 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isActive, seconds]);

  const sendOtp = (email, encryptedPassword) => {
    setResendLoading(true);
    axios
      .post(`/api/user/login`, {
        email: email,
        password: encryptedPassword,
      })
      .then((res) => {
        if (res.data.code === "200") {
          setShowOTP(true);
          setIsLoading(false);
          startTimer();
          setResend(false);
          setResendLoading(false);
        } else {
          toast.error(res.data.message);
          setError("Invalid credentials.");
          setIsLoading(false);
        }
      })
      .catch((err) => {
        if (err.response?.data) {
          toast.error(err.response.data.message);
        }
        setError("Invalid credentials.");
        setIsLoading(false);
      });
  };

  const doLogin = (values) => {
    setError("");
    setIsLoading(true);
    const encryptedPassword = EncryptPassword(values.password);
    setEmail(values.email);
    setPassword(encryptedPassword);
    if (showOTP) {
      axios
        .post(`/api/user/login`, {
          email: values.email,
          password: encryptedPassword,
          authCode: values.OTP,
        })
        .then((res) => {
          window.dataLayer.push({ event: "user-login" });
          setIsLoading(false);
          window.dataLayer.push({ event: "user-login" });
          setSessionData("token", res.data.token);
          navigate("/dashboard");
        })
        .catch((err) => {
          if (err.response.data) {
            toast.error(err.response.data.message);
          }
          setError("Invalid credentials.");
          setIsLoading(false);
        });
    } else {
      sendOtp(values.email, encryptedPassword);
    }
  };

  const LoginCard = () => (
    <Card className="login-Card">
      <Card.Body>
        <Formik
          initialValues={{ email: "", password: "" }}
          validationSchema={showOTP ? LoginWithOTPSchema : LoginSchema}
          onSubmit={(val) => doLogin(val)}
        >
          {({ handleChange, handleBlur, handleSubmit, errors, touched }) => (
            <Form onSubmit={handleSubmit}>
              {window.location.search.match("reg=success") && (
                <div className="alert alert-success">
                  Registration successful. Please proceed to login via password and OTP
                </div>
              )}
              {window.location.search.match("unauthorized") && (
                <div className="alert alert-danger">Unauthorized! Session expired or requires login.</div>
              )}
              {error && <div className="alert alert-danger">{error}</div>}

              <Col className="mb-5 mx-lg-5">
                <div className="mt-5">
                  <input
                    type="email"
                    name="email"
                    onFocus={() => setError("")}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Your Email Address"
                    style={{
                      border: errors.email && touched.email ? "1px solid red" : "",
                    }}
                    className={`form-input form-control`}
                  />
                </div>
                <div className="error-text mb-3 login-error">
                  <ErrorMessage name="email" />
                </div>
                <div className="">
                  <input
                    type={hidePassword ? "password" : "text"}
                    name="password"
                    onFocus={() => setError("")}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Password"
                    style={{
                      border: errors.password && touched.password ? "1px solid red" : "",
                    }}
                    className={`form-input form-control`}
                  />
                </div>
                <div className="error-text mb-3 login-error">
                  <ErrorMessage name="password" />
                </div>
                {showOTP && (
                  <>
                    <div className="">
                      <input
                        type="text"
                        name="OTP"
                        onFocus={() => setError("")}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Enter OTP"
                        className={`form-input form-control`}
                        style={{
                          border: errors.OTP && touched.OTP ? "1px solid red" : "",
                        }}
                      />
                    </div>
                    <div className="error-text login-error">
                      <ErrorMessage name="OTP" />
                    </div>
                    <input
                      className="time"
                      onChange={() => {}}
                      style={{
                        margin: "15px 0px 0",
                        backgroundColor: "rgb(199, 214, 253)",
                        border: "none",
                        color: "rgb(26, 34, 58)",
                        padding: "13px 10px",
                        borderRadius: "6px",
                        width: "40%",
                        transition: "all 0.9s ease 0s",
                        fontSize: "13px",
                        fontWeight: "500",
                        textAlign: "center",
                      }}
                      value={`00: ${seconds}s`}
                    />
                  </>
                )}
                {resend && (
                  <>
                    <input
                      type="button"
                      style={{
                        margin: "15px 0px 0",
                        backgroundColor: "rgb(199, 214, 253)",
                        border: "none",
                        color: "rgb(26, 34, 58)",
                        padding: "13px 10px",
                        borderRadius: "6px",
                        width: "40%",
                        transition: "all 0.9s ease 0s",
                        textTransform: "uppercase",
                        fontSize: "13px",
                      }}
                      onClick={() => sendOtp(email, password)}
                      value="Resend Otp"
                    />
                    {resendLoading ? <div className="loading"></div> : null}
                  </>
                )}

                <div className="submit-input justify-content-start mt-3">
                  <button
                    type="submit"
                    disabled={isLoading || resend}
                    style={{
                      margin: "15px 0px 0",
                      border: "none",
                      color: "rgb(255, 255, 255)",
                      padding: "13px 10px",
                      borderRadius: "6px",
                      width: "100%",
                      transition: "all 0.9s ease 0s",
                    }}
                    className={`load-button ${isLoading ? "spin" : "reset-btn-submit"}`}
                  >
                    {showOTP ? "Login" : "Continue"}
                    <span className="spinner"></span>
                  </button>
                </div>
              </Col>
            </Form>
          )}
        </Formik>

        <Card bg="light" className="mx-lg-5 mb-5 login-inner-Card">
          <Row
            className="border-bottom"
            onClick={() => {
              navigate("/forgot-password");
            }}
          >
            <Col className="p-3 text-start col-11" id="btn-pointer">
              <span>
                Forgot Password?
                <span className="login-primary-Color"> Reset it here</span>
              </span>
            </Col>
            <Col className="justify-content-end col-1 p-1">
              <ArrowUpRight size={20} className="login-primary-Color align-top"></ArrowUpRight>
            </Col>
          </Row>

          <Row
            onClick={() => {
              navigate("/register");
            }}
          >
            <Col className="p-3 text-start col-11" id="btn-pointer">
              <span>
                New to Upesi Money Transfer? <span className="login-primary-Color"> Create account here</span>
              </span>
            </Col>
            <Col className="justify-content-end col-1  p-1">
              <ArrowUpRight size={20} className="login-primary-Color align-top"></ArrowUpRight>
            </Col>
          </Row>
        </Card>
      </Card.Body>
    </Card>
  );

  return (
    <>
      <div id="particles-js"></div>

      <Container fluid className="login-container mb-5 align-items-center">
        <Container className="w-lg-75 w-sm-95">
          <Row>
            <Col sm={12} lg={6} className="p-lg-5 align-items-center">
              <h1 className="p-lg-5 login-text">Sign in and Send money to your friends and family</h1>
            </Col>
            <Col sm={12} lg={6} className="p-lg-5">
              {LoginCard()}
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
};

export default Login;
