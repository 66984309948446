import videoSource from "../../../../assets/images/countries-flag/rwanda-coming-soon.mp4";
import "./Rwanda.css";
export default function Rwanda() {
  return (
    <div>
      <video className="full-page-video" autoPlay muted loop>
        <source src={videoSource} type="video/mp4" />
      </video>
      <h1 className="wave-text">
        <span className="blue">C</span>
        <span className="yellow">o</span>
        <span className="green">m</span>
        <span className="blue">i</span>
        <span className="yellow">n</span>
        <span className="green">g</span>
        <span className="blue px-3"> </span>
        <span className="yellow">S</span>
        <span className="green">o</span>
        <span className="blue">o</span>
        <span className="yellow">n</span>
      </h1>
    </div>
  );
}
