import React from "react";
import { BrowserRouter, Link, Route, Routes } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import Contact from "../screens/Contact";
import ContactForm from "../screens/ContactForm";
import Dashboard from "../screens/Dashboard";
import Kyc from "../screens/Kyc";
import KycFail from "../screens/KycFail";
import KycSuccess from "../screens/KycSuccess";
import Login from "../screens/Login";
import ResetPassword from "../screens/ResetPassword";
import ForgotPassword from "../screens/ForgotPassword";
import ForgotPasswordConfirmation from "../screens/ForgotPasswordConfirmation";

import ManageBeneficiaries from "../screens/ManageBeneficiaries";
import PaymentOptions from "../screens/PaymentOptions";
import Policy from "../screens/Policy";
import Profile from "../screens/Profile";
import ProfileConfirm from "../screens/ProfileConfirm";

import ResetPasswordConfirmation from "../screens/ResetPasswordConfirmation";
import Terms from "../screens/Terms";
import TransactionConfirm from "../screens/TransactionConfirm";
import TransactionDetails from "../screens/TransactionDetails";
import Transactions from "../screens/Transactions";

import AuthRoutes from "./authRoutes";
import Beneficiary from "../screens/Beneficiary/Beneficiary";
import AboutUs from "../screens/Public/Company/AboutUs/AboutUs";
import Kenya from "../screens/Public/Landing/Kenya/Kenya";
import Uganda from "../screens/Public/Landing/Uganda/Uganda";
import Layout from "../screens/Public/Layout/Layout";
import BankDeposit from "../screens/Public/Products/BankDeposit/BankDeposit";
import CashPickup from "../screens/Public/Products/CashPickup/CashPickup";
import MobileMoney from "../screens/Public/Products/MobileMoney/MobileMoney";
import DownloadApp from "../screens/Public/Components/DownLoadApp/DownloadApp.jsx";
import Support from "../screens/Public/Support/Support";
import Registration from "../screens/Registration/Registration";
import MoneyTransferJourney from "../screens/SendMoney/SendMoney";
import Rwanda from "../screens/Public/Landing/Rwanda/Rwanda";

const BaseRouter = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="" element={<Layout />}>
            <Route index element={<Kenya />} />
            <Route path="/ug" element={<Uganda />} />
            <Route path="/rw" element={<Rwanda />} />
            <Route path="/app" element={<DownloadApp />} />
            <Route path="/mobile-money" element={<MobileMoney />} />
            <Route path="/bank-deposit" element={<BankDeposit />} />
            <Route path="/cash-pickup" element={<CashPickup />} />
            <Route path="/about-upesi" element={<AboutUs />} />
            <Route path="/support" element={<Support />} />
            <Route path="/login" element={<AuthRoutes element={<Login />} />} />

            <Route path="/forgot-password" element={<AuthRoutes element={<ForgotPassword />} />} />
            <Route path="/forget-password-success" element={<AuthRoutes element={<ForgotPasswordConfirmation />} />} />
            <Route path="/support" element={<Support />} />
            <Route path="*" element={<AuthRoutes element={<NotFound />} />} />
            <Route path="/reset-password" element={<AuthRoutes element={<ResetPassword />} />} />
            <Route path="/reset-password-success" element={<AuthRoutes element={<ResetPasswordConfirmation />} />} />

            <Route path="/register" element={<AuthRoutes element={<Registration />} />} />

            <Route path="/terms" element={<Terms />} />
            <Route path="/policy" element={<Policy />} />
          </Route>

          <Route path="/contact" element={<PrivateRoute heading="Contact" element={<Contact />} />} />
          <Route path="/contact-form" element={<PrivateRoute element={<ContactForm />} />} />

          <Route path="/kyc-fail" element={<PrivateRoute heading="KYC" element={<KycFail />} />} />
          <Route path="/kyc-success" element={<PrivateRoute element={<KycSuccess />} />} />
          <Route path="/dashboard" element={<PrivateRoute heading="dashboard" element={<Dashboard />} />} />

          <Route
            path="/manage-beneficiaries"
            element={<PrivateRoute heading="manage beneficiaries" element={<ManageBeneficiaries />} />}
          />
          <Route path="/transactions" element={<PrivateRoute heading="transactions" element={<Transactions />} />} />
          <Route
            path="/transactions-details"
            element={<PrivateRoute heading="transactions details" element={<TransactionDetails />} />}
          />
          <Route
            path="/money-transfer"
            element={<PrivateRoute heading="money transfer" element={<MoneyTransferJourney />} />}
          />

          <Route
            path="/payment-options"
            element={<PrivateRoute heading="payment options" element={<PaymentOptions />} />}
          />
          <Route
            path="/transaction-confirmation"
            element={<PrivateRoute heading="transaction confirmation" element={<TransactionConfirm />} />}
          />
          <Route
            path="/add-beneficiary"
            element={<PrivateRoute heading="add beneficiaries" element={<Beneficiary />} />}
          />
          <Route
            path="/edit-beneficiary"
            element={<PrivateRoute heading="Edit beneficiaries" element={<Beneficiary />} />}
          />

          <Route path="/profile" element={<PrivateRoute heading="Profile" element={<Profile />} />} />
          <Route
            path="/profile-confirm"
            element={<PrivateRoute heading="profile confirm" element={<ProfileConfirm />} />}
          />
          <Route path="/kyc" element={<PrivateRoute heading="KYC" element={<Kyc />} />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default BaseRouter;

const NotFound = () => {
  return (
    <>
      <div className="not-found">
        <h1>404</h1>
        <p>Oops! Something is wrong.</p>
        <Link to={"/"} className="primary">
          Go back home page.
        </Link>
      </div>
    </>
  );
};
