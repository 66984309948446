import React from "react";
import mpesa from "../assets/images/logos/wallet-icon1.png";

export default function DashboardModal(props) {
  const handleMobileNumber = (e) => {
    props.handleMobileNumber(e.target.value);
  };

  return (
    <>
      {/* Check Status */}
      {props.status === "Locked" ? (
        <>
          {props.show ? (
            <div className="modal">
              <div className="modal-content">
                <div className="modal-header">
                  <span className="close" onClick={props.close}>
                    ×
                  </span>
                </div>
                <div className="modal-body makepayment-container">
                  <h4 className="text-center">Transaction Locked Status</h4>
                  <section>
                    <div className="">
                      <form className="process-ol">
                        <div className="process-form">
                          <div className="full-input payment-option-text">
                            <p>{props.holdReason}</p>
                          </div>
                        </div>
                      </form>
                    </div>
                  </section>
                </div>
                <div className="modal-footer display-flex">
                  <div className="continue-process close">
                    <button onClick={props.close}>Ok</button>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </>
      ) : (
        ""
      )}

      {/* Check Reason */}
      {props.status === "Cancelled" ? (
        <>
          {props.show ? (
            <div className="modal">
              <div className="modal-content">
                <div className="modal-header">
                  <span className="close" onClick={props.close}>
                    ×
                  </span>
                </div>
                <div className="modal-body makepayment-container">
                  <h4 className="text-center">Transaction Cancelled Reason</h4>
                  <section>
                    <div className="">
                      <form className="process-ol">
                        <div className="process-form">
                          <div className="full-input payment-option-text">
                            <p>{props.failedReason}</p>
                          </div>
                        </div>
                      </form>
                    </div>
                  </section>
                </div>
                <div className="modal-footer display-flex">
                  <div className="continue-process close">
                    <button onClick={props.close}>
                      Ok
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </>
      ) : (
        ""
      )}

      {/* Make Payment */}
      {props.status === "Pending" ? (
        <>
          {" "}
          {props.show ? (
            <div className="modal">
              <div className="modal-content">
                <div className="modal-header">
                  <span className="close" onClick={props.close}>
                    ×
                  </span>
                </div>
                <div className="modal-body makepayment-container">
                  <h4 className="text-center">Make Payment</h4>
                  <section>
                    <div className="">
                      <form className="process-ol">
                        <div className="process-form">
                          <div className="full-input payment-option-image">
                            <img src={mpesa} alt="Mpesa" />
                          </div>
                          <div className="full-input payment-option-text">
                            <p>
                              Enter you Mpesa Phone number i.e(+254700000000)
                            </p>
                          </div>
                          <div className="full-input money-half-input">
                            <input
                              type="text"
                              name=""
                              onChange={handleMobileNumber}
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                  </section>
                </div>
                <div className="modal-footer display-flex">
                  <div className="continue-process close">
                    <bu onClick={props.onSubmit}>
                      SUBMIT
                    </bu>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </>
      ) : (
        ""
      )}
    </>
  );
}
