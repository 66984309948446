export const Nationalities = [
{ name: "Afghan"},
{ name: "Albanian"},
{ name: "Algerian"},
{ name: "American"},
{ name: "Andorran"},
{ name: "Angolan"},
{ name: "Antiguans"},
{ name: "Argentinean"},
{ name: "Armenian"},
{ name: "Australian"},
{ name: "Austrian"},
{ name: "Azerbaijani"},
{ name: "Bahamian"},
{ name: "Bahraini"},
{ name: "Bangladeshi"},
{ name: "Barbadian"},
{ name: "Barbudans"},
{ name: "Batswana"},
{ name: "Belarusian"},
{ name: "Belgian"},
{ name: "Belizean"},
{ name: "Beninese"},
{ name: "Bhutanese"},
{ name: "Bolivian"},
{ name: "Bosnian"},
{ name: "Brazilian"},
{ name: "British"},
{ name: "Bruneian"},
{ name: "Bulgarian"},
{ name: "Burkinabe"},
{ name: "Burmese"},
{ name: "Burundian"},
{ name: "Cambodian"},
{ name: "Cameroonian"},
{ name: "Canadian"},
{ name: "Cape Verdean"},
{ name: "Central African"},
{ name: "Chadian"},
{ name: "Chilean"},
{ name: "Chinese"},
{ name: "Colombian"},
{ name: "Comoran"},
{ name: "Congolese"},
{ name: "Costa Rican"},
{ name: "Croatian"},
{ name: "Cuban"},
{ name: "Cypriot"},
{ name: "Czech"},
{ name: "Danish"},
{ name: "Djibouti"},
{ name: "Dominican"},
{ name: "Dutch"},
{ name: "East Timorese"},
{ name: "Ecuadorean"},
{ name: "Egyptian"},
{ name: "Emirian"},
{ name: "Equatorial Guinean"},
{ name: "Eritrean"},
{ name: "Estonian"},
{ name: "Ethiopian"},
{ name: "Fijian"},
{ name: "Filipino"},
{ name: "Finnish"},
{ name: "French"},
{ name: "Gabonese"},
{ name: "Gambian"},
{ name: "Georgian"},
{ name: "German"},
{ name: "Ghanaian"},
{ name: "Greek"},
{ name: "Grenadian"},
{ name: "Guatemalan"},
{ name: "Guinea-Bissauan"},
{ name: "Guinean"},
{ name: "Guyanese"},
{ name: "Haitian"},
{ name: "Herzegovinian"},
{ name: "Honduran"},
{ name: "Hungarian"},
{ name: "I-Kiribati"},
{ name: "Icelander"},
{ name: "Indian"},
{ name: "Indonesian"},
{ name: "Iranian"},
{ name: "Iraqi"},
{ name: "Irish"},
{ name: "Israeli"},
{ name: "Italian"},
{ name: "Ivorian"},
{ name: "Jamaican"},
{ name: "Japanese"},
{ name: "Jordanian"},
{ name: "Kazakhstani"},
{ name: "Kenyan"},
{ name: "Kittian and Nevisian"},
{ name: "Kuwaiti"},
{ name: "Kyrgyz"},
{ name: "Laotian"},
{ name: "Latvian"},
{ name: "Lebanese"},
{ name: "Liberian"},
{ name: "Libyan"},
{ name: "Liechtensteiner"},
{ name: "Lithuanian"},
{ name: "Luxembourger"},
{ name: "Macedonian"},
{ name: "Malagasy"},
{ name: "Malawian"},
{ name: "Malaysian"},
{ name: "Maldivian"},
{ name: "Malian"},
{ name: "Maltese"},
{ name: "Marshallese"},
{ name: "Mauritanian"},
{ name: "Mauritian"},
{ name: "Mexican"},
{ name: "Micronesian"},
{ name: "Moldovan"},
{ name: "Monacan"},
{ name: "Mongolian"},
{ name: "Moroccan"},
{ name: "Mosotho"},
{ name: "Motswana"},
{ name: "Mozambican"},
{ name: "Namibian"},
{ name: "Nauruan"},
{ name: "Nepalese"},
{ name: "New Zealander"},
{ name: "Ni-Vanuatu"},
{ name: "Nicaraguan"},
{ name: "Nigerian"},
{ name: "Nigerien"},
{ name: "North Korean"},
{ name: "Northern Irish"},
{ name: "Norwegian"},
{ name: "Omani"},
{ name: "Pakistani"},
{ name: "Palauan"},
{ name: "Panamanian"},
{ name: "Papua New Guinean"},
{ name: "Paraguayan"},
{ name: "Peruvian"},
{ name: "Polish"},
{ name: "Portuguese"},
{ name: "Qatari"},
{ name: "Romanian"},
{ name: "Russian"},
{ name: "Rwandan"},
{ name: "Saint Lucian"},
{ name: "Salvadoran"},
{ name: "Samoan"},
{ name: "San Marinese"},
{ name: "Sao Tomean"},
{ name: "Saudi"},
{ name: "Scottish"},
{ name: "Senegalese"},
{ name: "Serbian"},
{ name: "Seychellois"},
{ name: "Sierra Leonean"},
{ name: "Singaporean"},
{ name: "Slovakian"},
{ name: "Slovenian"},
{ name: "Solomon Islander"},
{ name: "Somali"},
{ name: "South African"},
{ name: "South Korean"},
{ name: "Spanish"},
{ name: "Sri Lankan"},
{ name: "Sudanese"},
{ name: "Surinamer"},
{ name: "Swazi"},
{ name: "Swedish"},
{ name: "Swiss"},
{ name: "Syrian"},
{ name: "Taiwanese"},
{ name: "Tajik"},
{ name: "Tanzanian"},
{ name: "Thai"},
{ name: "Togolese"},
{ name: "Tongan"},
{ name: "Trinidadian or Tobagonian"},
{ name: "Tunisian"},
{ name: "Turkish"},
{ name: "Tuvaluan"},
{ name: "Ugandan"},
{ name: "Ukrainian"},
{ name: "Uruguayan"},
{ name: "Uzbekistani"},
{ name: "Venezuelan"},
{ name: "Vietnamese"},
{ name: "Welsh"},
{ name: "Yemenite"},
{ name: "Zambian"},
{ name: "Zimbabwean"},
]