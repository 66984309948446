import { useEffect, useState } from "react";
import axios from "../api/axios/axios";

export default function useFetchCountries() {
  const [selectedCountry, setSelectedCountry] = useState([]);

  // get api for country list
  useEffect(() => {
    axios
      .get(`/api/Utility/GetCountries`)
      .then((res) => {
        setSelectedCountry(res.data);
      })
      .catch((err) => {
        setSelectedCountry([]);
      });
  }, []);

  return { selectedCountry };
}
