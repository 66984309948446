import React from "react";
import styles from "./UgandaCTA.module.css";
import signupImg from "../../../../assets/images/UG-signup-bg.png"
import { useLocation } from "react-router-dom";

const UGSignUpCta = () => {


  const { pathname } = useLocation();

  return (
    <div className='row pt-5'>

      <div className="col-12 col-md-6 col-lg-6 gy-2">
        <div>
          <h1>Safe, Secure & Convenient</h1>
        </div>
        <div className="text-left justify-content-center align-items-center">
          <ul id={styles.list} > 
            <li className={styles.square}>Licensed by Bank of Uganda</li>
            <li className="square">Multi-factor Authentication</li>
            <li className="square">Customer Data Privacy</li>
            <li className="square">Service Available 24/7 from any device</li>
            <li className="square">Not affected by weekends and public holidays</li>
            <li className="square">Instant/same-day delivery</li>
          </ul>
        </div>

        <div className='p-5'>
             {pathname.includes('ug') === false && (
              <button type='button' className='btn btn-lg primary-color-bg'><h2>Sign Up Now</h2></button>
              )}
           </div>
      </div>

      <div className="col-12 col-md-6 col-lg-6">
        <img src={signupImg} alt="sign up background">
        </img>
      </div>
    </div>
  );
};

export default UGSignUpCta;
