import { toast } from "react-toastify";
import axios from "./axios/axios";

export const repeatTransaction = ({ obj, setIsLoading, setId, navigate }) => {
  setIsLoading(true);
  setId(obj.id);
  axios
    .get(`/api/Transaction/GetUserTransactionById/${obj.id}`)
    .then((res) => {
      const {
        receiverCountryID,
        receiverCountryName,
        serviceTypeID,
        serviceName,
        receiverBeneficiaryId,
        senderAmount,
        senderPurposeOfRemittance,
        senderSourceOfFunds,
        receiverPickUpLocationID,
      } = res.data;
      const sendMoneyDetails = {
        countryID: receiverCountryID,
        countryName: receiverCountryName,
        serviceName,
        serviceId: serviceTypeID,
        id: receiverBeneficiaryId,
        amount: senderAmount,
        sourceOfFund: senderSourceOfFunds,
        purposeOfSending: senderPurposeOfRemittance,
        cashPickupLocation: receiverPickUpLocationID,
      };
      navigate("/money-transfer", { state: sendMoneyDetails });
      setIsLoading(false);
    })
    .catch((err) => {
      toast.error("Error to get user transaction by id");
      setIsLoading(false);
    });
};
