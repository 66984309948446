import { createContext, useContext, useState } from "react";

export const CountryContext = createContext();

export const CountryProvider = ({ children }) => {
  const [country, setCountry] = useState("");

  const setCountryValue = (newCountry) => {
    setCountry(newCountry);
  };

  return (
    <CountryContext.Provider value={{ country, setCountry: setCountryValue }}>
      {children}
    </CountryContext.Provider>
  );
};
export const useCountry = () => {
  const context = useContext(CountryContext);
  if (!context) {
    throw new Error("Use Country must be used within Country provider");
  }
  return context;
};
