import React, { useEffect, useMemo, useRef, useState } from "react";
import { Container, Nav, NavDropdown, Navbar, Offcanvas } from "react-bootstrap";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";

import Kenya from "../../../../assets/images/countries-flag/KE.png";
import Rwanda from "../../../../assets/images/countries-flag/RW.png";
import Uganda from "../../../../assets/images/countries-flag/UG.png";
import user from "../../../../assets/images/icons/user.svg";
import Logo from "../../../../assets/images/upesi-logo.png";
import LogoutModal from "../../../../components/LogoutModal";
import useWindowDimensions from "../../../../hooks/useWindowDimensions";
import { getSessionData } from "../../../../utils/session";
import styles from "./Header.module.css";

export let KEFlag = (
  <span>
    <img className={styles.currentFlag} src={Kenya} alt="KEFlag" /> KE{" "}
  </span>
);
export let UGFlag = (
  <span>
    <img className={styles.currentFlag} src={Uganda} alt="UGFlag" /> UG{" "}
  </span>
);

const CountryFlag = ({ country }) => {
  const flagSrc = country === "Kenya" ? Kenya : country === "Uganda" ? Uganda : Rwanda;
  return <img src={flagSrc} className={styles.currentFlag} alt={country} title={country} />;
};

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const country = useMemo(() => {
    if (location.pathname === "/") return "/ke";

    return location.pathname;
  }, [location]);

  const isRwanda = useMemo(() => {
    return country.includes("/rw");
  }, [country]);

  const [currentCountry, setCurrentCountry] = useState({});
  const [navbar, setNavbar] = useState(false);
  const [modal, setModal] = useState(false);

  const { width } = useWindowDimensions();

  const mobileNav = useRef();

  const closeOffCanvas = () => mobileNav.current.backdrop?.click();

  const navlinkClass = ({ isActive }) => `${styles.navLink} ${isActive ? `${styles.active}` : ``}`;

  const changeBackground = () => {
    if (window.scrollY >= 66) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  const openModal = () => {
    setModal(true);
  };

  const closeModal = () => {
    setModal(false);
  };

  useEffect(() => {
    changeBackground();
    // adding the event when scroll change background
    window.addEventListener("scroll", changeBackground);

    return () => {
      window.removeEventListener("scroll", changeBackground);
    };
  });

  const countryNavs = [
    {
      country: "Kenya",
      name: "KE",
      flag: <CountryFlag country={"Kenya"} />,
      to: ".",
    },
    {
      country: "Uganda",
      name: "UG",
      flag: <CountryFlag country={"Uganda"} />,
      to: "ug",
    },

    {
      country: "Rwanda",
      name: "RW",
      flag: <CountryFlag country={"Rwanda"} />,
      to: "rw",
    },
  ];

  const navs = countryNavs
    .filter((c) => !country.includes(c.name.toLowerCase()))
    .map((nav) => {
      return (
        <NavDropdown.Item href={nav.to} key={nav.country} className="dropdown-item">
          {nav.flag}
          <span className={"p-1"}>{nav.country}</span>
        </NavDropdown.Item>
      );
    });

  const isLoggedIn = getSessionData("token");
  const handleLogout = () => {
    localStorage.clear();
    setModal(false);
    navigate(`/login?country=${country}`);
  };

  const accountButtons = (
    <div sm={3} className={styles.accountBtn}>
      {isLoggedIn ? (
        <Link className={styles.logoutIcom} to="" onClick={openModal}>
          <img src={user} alt="user logo" />
          <span>Logout</span>
        </Link>
      ) : (
        <>
          <Link className={styles.loginBtn} onClick={closeOffCanvas} to={`/login?country=${country}`}>
            Login
          </Link>
          <Link className={styles.registerBtn} onClick={closeOffCanvas} to="/register">
            Register
          </Link>
        </>
      )}
    </div>
  );

  if (isRwanda) {
    return (
      <Navbar sticky="top" expand="md">
        <Container className={"w-75"}>
          <Navbar.Brand href="/">
            <img src={Logo} width="60" height="60" className="d-inline-block align-top" alt="Upesi Money Transfer" />
          </Navbar.Brand>
          <Nav className="me-auto">
            <NavDropdown
              placement={"right-end"}
              title={
                <img
                  src={Rwanda}
                  width="30"
                  height="30"
                  className="d-inline-block align-top"
                  alt="Rwanda Flag"
                  title={"Rwanda"}
                />
              }
              id=""
            >
              {navs}
            </NavDropdown>
          </Nav>
        </Container>
      </Navbar>
    );
  }

  return (
    <>
      {modal ? <LogoutModal show={modal} close={closeModal} handleLogout={handleLogout} /> : null}
      <Navbar
        sticky="top"
        expand="md"
        className={`${styles.header} ${currentCountry.headerBgColor} ${navbar ? `${styles.fixed_top}` : ""}`}
      >
        <Container className={`${width > 1200 ? "w-75" : ""}`}>
          <NavLink to={country} id="main-logo" className={styles.logo}>
            <img src={Logo} alt="" />
          </NavLink>

          {width < 768 && <>{accountButtons}</>}
          <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-md`} />
          <Navbar.Offcanvas
            ref={mobileNav}
            id={`offcanvasNavbar-expand-md`}
            aria-labelledby={`offcanvasNavbarLabel-expand-md`}
            placement="end"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id={`offcanvasNavbarLabel-expand-md`}>
                <NavLink to={country} onClick={closeOffCanvas} className={styles.logo}>
                  <img src={Logo} alt="" />
                </NavLink>
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="justify-content-around  flex-grow-1 pe-3">
                <NavDropdown
                  className={`${styles.dropdown} ${styles.dropdown_flag}`}
                  title={
                    countryNavs.find((c) => country.includes(c?.name.toLowerCase()))?.flag ?? (
                      <CountryFlag country={"Kenya"} />
                    )
                  }
                  id="nav-dropdown"
                >
                  {navs}
                </NavDropdown>
                {isLoggedIn && (
                  <NavLink to="dashboard" onClick={closeOffCanvas} className={navlinkClass}>
                    Dashboard
                  </NavLink>
                )}
                <NavDropdown className={`${styles.dropdown}`} title="Products" id={`offcanvasNavbarDropdown-expand-md`}>
                  <NavLink to="mobile-money" onClick={closeOffCanvas} className="dropdown-item">
                    Mobile Money
                  </NavLink>
                  <NavDropdown.Divider />
                  <NavLink to="bank-deposit" onClick={closeOffCanvas} className="dropdown-item">
                    Bank Deposit
                  </NavLink>
                  <NavDropdown.Divider />
                  <NavLink to="cash-pickup" onClick={closeOffCanvas} className="dropdown-item">
                    Cash Pickup
                  </NavLink>
                </NavDropdown>
                <NavDropdown className={`${styles.dropdown}`} title="Company" id={`offcanvasNavbarDropdown-expand-md`}>
                  <NavLink to="about-upesi" onClick={closeOffCanvas} className={`dropdown-item ${styles.company}`}>
                    About Us
                  </NavLink>
                  <NavDropdown.Divider />
                </NavDropdown>
                <NavLink to="support" onClick={closeOffCanvas} className={navlinkClass}>
                  Support
                </NavLink>
              </Nav>
              {width > 768 && <>{accountButtons}</>}
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </>
  );
};

export default Header;
