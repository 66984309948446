import { useEffect, useState } from "react";
import axios from "../api/axios/axios";

export default function useFetchBanksBySwiftCode(
  countryId,
  swiftCode,
  setIsLoading
) {
  const [banksBySwiftCode, setBanksBySwiftCode] = useState([]);

  useEffect(() => {
    // MOROCCO=57, EGYPT=224, USA=82 =>170 prev
    // TODO: fetch country id from api, remove hard coding(wrong)
    let swiftCodeCountryIds = [57, 224, 82];
    let isSwiftCodeCountry = swiftCodeCountryIds.includes(countryId);

    if (isSwiftCodeCountry && countryId && swiftCode?.length > 7) {
      setIsLoading(true);
      axios
        .get(`/api/Utility/GetBanksBySwiftCode/${countryId}/${swiftCode}`)
        .then((res) => {
          setIsLoading(false);
          setBanksBySwiftCode(res.data);
        })
        .catch((err) => {
          setIsLoading(false);
          setBanksBySwiftCode([]);
        });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryId, swiftCode]);

  return { banksBySwiftCode, setBanksBySwiftCode };
}
