import React, { useContext, useState } from "react";

import { FormContext } from "../../../Beneficiary";
import CashDetails from "./CashDetails";

function CashDetailsConatiner() {
  const {
    activeStepIndex,
    setActiveStepIndex,
    formData,
    apiData: { cashPickupLocations },
  } = useContext(FormContext);

  const [selectedCashPickupLocation, setSelectedCashPickupLocation] =
    useState(null);

  const handleCashPickupLocation = (val, setFieldValue) => {
    setFieldValue("cashPickupLocation", val.name);
    setSelectedCashPickupLocation(val);
  };

  const onSubmit = (values) => {
    formData.setDeliveryType((prevState) => ({
      ...prevState,
      cashDetails: { ...prevState.cashDetails, ...values },
    }));
    formData.setPersonalInfo({
      ...formData.personalInfo,
      phoneNumber: values.mobileNumber,
    });
    setActiveStepIndex(activeStepIndex + 1);
  };

  let cashProps = {
    formData,
    selectedCashPickupLocation,
    handleCashPickupLocation,
    cashPickupLocations,
    onSubmit,
  };

  return <CashDetails cashProps={cashProps} />;
}

export default CashDetailsConatiner;
