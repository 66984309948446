import classNames from "classnames";
import { ErrorMessage, Form, Formik } from "formik";
import React, { Fragment } from "react";
import Select from "react-select";

import { NumericFormat } from "react-number-format";
import { CustomStyle, defaultStyle } from "../../utils/styles";

export default function UpesiFormik({ props }) {
  const {
    stepValidationSchema,
    onSubmit,
    stepFields,
    formId,
    stepTitle,
    initialStepData,
    handleValueChange,
    doc,
    openDocPreview,
    formData,
    disableBtn,
    fileRef,
    calculateRates,
    loadingRates,
  } = props;

  const getFields = (values, setFieldValue, handleBlur, errors, touched) => {
    return stepFields.map((field, index) => {
      const fieldName = field?.name;
      const fieldId = field?.id;
      const objectVal = field?.objectVal;

      return (
        <Fragment key={`${fieldName}_${index} `}>
          {field?.controlType === "select" && (
            <div
              className={`form-group col-12  ${
                fieldName === "currency" ? "col-sm-4 col-lg-2 col-xl-2" : "col-sm-8 col-lg-6 col-xl-4"
              } `}
            >
              <label>{field?.displayName ?? field?.placeHolder}</label>
              <Select
                name={fieldName}
                value={values[fieldName] && { name: values[fieldName] }}
                onChange={(val) => {
                  handleValueChange({
                    val,
                    fieldName,
                    fieldId,
                    setFieldValue,
                    controlType: field.controlType,
                    objectVal,
                  });
                }}
                options={field.options}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option}
                placeholder={field?.placeHolder}
                onBlur={handleBlur}
                noOptionsMessage={() => field?.noOptionMessage}
                styles={errors[fieldName] && touched[fieldName] ? CustomStyle : defaultStyle}
              />
            </div>
          )}
          {field?.controlType === "input" && (
            <div className="form-group col-12 col-sm-8 col-lg-6 col-xl-4">
              <label>{field?.displayName ?? field?.placeHolder}</label>

              {fieldName === "amount" && <span className={"mx-2"}>{`(${formData["currency"]})`}</span>}

              {field?.type === "number" ? (
                <NumericFormat
                  thousandSeparator={true}
                  name={fieldName}
                  value={values[fieldName]}
                  onValueChange={(values) => {
                    const { value } = values;
                    handleValueChange({
                      val: value,
                      fieldName,
                      setFieldValue,
                      controlType: field.controlType,
                    });
                  }}
                  placeholder={field?.placeHolder}
                  onBlur={handleBlur}
                  className={`form-control form-input ${errors[fieldName] && touched[fieldName] ? "form-error" : ""}`}
                ></NumericFormat>
              ) : (
                <input
                  name={fieldName}
                  value={values[fieldName]}
                  onChange={(e) => {
                    handleValueChange({
                      val: e.target?.value,
                      fieldName,
                      setFieldValue,
                      controlType: field.controlType,
                    });
                  }}
                  placeholder={field?.placeHolder}
                  type={field.type}
                  onBlur={handleBlur}
                  className={`form-control form-input ${errors[fieldName] && touched[fieldName] ? "form-error" : ""}`}
                />
              )}
            </div>
          )}

          {field?.controlType === "file" && (
            <div className="form-group col-12 col-sm-8 col-lg-6 col-xl-4">
              <label>{field?.displayName ?? field?.placeHolder}</label>
              <input
                ref={fileRef}
                name={fieldName}
                onChange={(e) => {
                  handleValueChange({
                    val: e.target?.files,
                    fieldName,
                    setFieldValue,
                    controlType: field.controlType,
                  });
                }}
                type={field.type}
                onBlur={handleBlur}
                className={`form-control form-input ${errors[fieldName] && touched[fieldName] ? "form-error" : ""}`}
              />
              {doc && (
                <button type="button" onClick={openDocPreview} className="btn btn-primary mt-2 btn-sm">
                  Preview Document
                </button>
              )}
            </div>
          )}

          {field?.controlType === "label" && (
            <div className="form-group col-12 col-sm-8 col-lg-6 col-xl-4 align-left">
              <div className="d-flex justify-content-between">
                <div className="">
                  <span> {field.displayName}</span>
                </div>
                {field.name !== "exchangeRate" ? (
                  <div className="label-value">
                    {field.name === "receiverAmount" ? (
                      <span>
                   {values["receiverCurrency"]} {values[fieldName]  || "0.0"}
                      </span>
                    ) : (
                      <span>
                        {values["senderCurrency"]}{" "}
                        {field.displayName === "Fees" && values["discount"] ? (
                          <>
                            <span className="discount-fee">0.0</span>
                            <span className="cancel-fee"> {values["discount"]}.0</span>
                          </>
                        ) : (
                          values[fieldName] || "0.0"
                        )}
                      </span>
                    )}
                  </div>
                ) : (
                  <div className="">
                    {/* {values["senderCurrency"]} */}
                    {!values[fieldName] || values[fieldName] > 1 ? (
                      <span>
                        1 {values["senderCurrency"]} = {values[fieldName] || "0.0"} {values["receiverCurrency"]}
                      </span>
                    ) : (
                      <span>
                        1 {values["receiverCurrency"]} ={" "}
                        {1 / values[fieldName] !== Infinity ? (1 / values[fieldName]).toFixed(6) : values[fieldName]}{" "}
                        {values["senderCurrency"]}
                      </span>
                    )}
                    {/* {values[fieldName] < 1 ? (
                      <span>
                        1 {values["senderCurrency"]} = {values[fieldName]}{" "}
                        {values["receiverCurrency"]}
                      </span>
                    ) : (
                      <span>
                        {values[fieldName]} {values["receiverCurrency"]} = 1{" "}
                        {values["senderCurrency"]}
                      </span>
                    )} */}
                  </div>
                )}
              </div>
            </div>
          )}

          {field?.controlType === "button" && (
            <div className="form-group col-12 col-sm-8 col-lg-6 col-xl-4">
              <button
                disabled={loadingRates}
                type="button"
                className={"btn send-money-btn " + classNames({ spin: loadingRates }, "load-button")}
                onClick={calculateRates}
              >
                {loadingRates ? "Calculating..." : "Calculate"}
                <span className="spinner rates-spinner text-primary"></span>
              </button>
            </div>
          )}
          <div className="error-text col-12 text-center my-2">
            <ErrorMessage name={fieldName} />
          </div>
        </Fragment>
      );
    });
  };

  return (
    <>
      <Formik
        initialValues={{ ...initialStepData }}
        enableReinitialize
        validationSchema={stepValidationSchema}
        onSubmit={(val) => onSubmit(val)}
      >
        {({ handleBlur, handleSubmit, setFieldValue, errors, touched, values }) => (
          <Form id={formId} className="w-100" onSubmit={handleSubmit}>
            <div className="h3 text-center mb-2 step-title">{stepTitle}</div>

            <div className={"row justify-content-center"}>
              <div className={"row d-flex justify-content-center mb-2"}>
                {getFields(values, setFieldValue, handleBlur, errors, touched)}
              </div>

              <div className="col-12 col-sm-8 col-lg-6 col-xl-4">
                <button type="submit" disabled={disableBtn} className={"btn send-money-btn w-100 "}>
                  Next
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}
