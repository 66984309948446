import React from "react";
import { Link } from "react-router-dom";
import check from "../assets/images/icons/check.png";

const ProfileConfirm = () => {
  return (
    <div>
      <section>
        <div className="send-total-wrapper">
          <div className="display-flex full-input send-to-country align-items-center margin-bottom">
            <figure>
              <img src={check} alt="Send to Somali" />
            </figure>
            &nbsp;&nbsp;<h3>Profile Saved Successfully</h3>
            <br />
          </div>
          <br />
          <div className="continue-process display-flex">
            <div className="padding-x steps-inputs-column1">
              <Link to="/dashboard">
                <button>back</button>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ProfileConfirm;
