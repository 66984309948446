import * as Yup from "yup";
import { phoneRegExp } from "../../../../../utils/validations/regex";

export const mobileDetailsSchema = (deliveryOption, countryName) =>
  Yup.object().shape({
    walletMobileNumber: Yup.string()
      .matches(phoneRegExp, "Mobile number is not valid")
      .required("Mobile number is required"),
    walletId:
      deliveryOption === "Mobile Money" && countryName === "CHINA"
        ? Yup.string().required("Wallet ID is required")
        : Yup.string().nullable(),
    // mobileMoneyProviderID: Yup.boolean().oneOf([true], "Mobile Operator is required"),
    // deliveryOption === "Mobile Money" || countryName === "UGANDA"
    // ?

    // : Yup.string().nullable(),
  });
