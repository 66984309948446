import React from "react";
import ArticleWrapper from "../ArticleWrapper";
import Navigation from "../Navigation";

const PrivateHeader = (props) => {
  return (
    <>
      <header className="header-wrapper">
        <Navigation />
      </header>
      <ArticleWrapper heading={props.heading} children={props.children} />
    </>
  );
};

export default PrivateHeader;
