import { useEffect, useState } from "react";
import axios from "../api/axios/axios";

export default function useFetchBeneficiaryBankDetails({
  beneficiaryID,
  deliveryOptionID,
}) {
  const [beneficiaryBankDetails, setBeneficiaryBankDetails] = useState(null);

  useEffect(() => {
    if (beneficiaryID && deliveryOptionID === 1) {
      (async function () {
        try {
          const res = await axios.get(
            `/api/Beneficiary/GetBeneficiaryBankDetails/${beneficiaryID}`
          );

          setBeneficiaryBankDetails(res.data);
        } catch (error) {
          setBeneficiaryBankDetails(null);
        }
      })();
    }
  }, [beneficiaryID, deliveryOptionID]);

  return { beneficiaryBankDetails, setBeneficiaryBankDetails };
}
