/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useState, useEffect, useMemo, useRef, useContext } from "react";
import { toast } from "react-toastify";
import axios from "../api/axios/axios";
import { useNavigate } from "react-router-dom";
import Pagination from "../components/Pagination";
import DashboardModal from "../components/DashboardModal";
import moment from "moment";
import { redirectPaymentOption } from "../api/redirectPaymentOption";
import { ProfileContext } from "../routes/PrivateRoute";

export default function Transactions() {
  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const [initData, setInitData] = useState([]);
  const [userTransactions, setUserTransactions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [message, setMessage] = useState("No beneficiaries added yet");
  const pageSize = 5;
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageData, setCurrentPageData] = useState([]);

  const [modal, setModal] = useState(false);
  const [refId, setRefId] = useState();
  const [mobileNumber, setMobileNumber] = useState();
  const [status, setStatus] = useState();
  const [holdReason, setHoldReason] = useState();
  const [failedReason, setFailedReason] = useState();
  const downloadRef = useRef(null);
  const [base64Pdf, setBase64Pdf] = useState("");

  const { kycDetails } = useContext(ProfileContext);

  useEffect(() => {
    if (base64Pdf !== "") {
      downloadRef.current.download = "download-receipt.pdf";
      downloadRef.current.click();
    }
  }, [base64Pdf]);

  useEffect(() => {
    getUserTransactionsList();
  }, []);

  const handleMobileNumber = (obj) => {
    setMobileNumber(obj);
  };

  const openModal = (obj) => {
    setModal(true);
    setRefId(obj.id);
    setStatus(obj.transactionstatus);
    setHoldReason(obj.holdreason);
    setFailedReason(obj.cancelreason);
  };

  const closeModal = () => {
    setModal(false);
  };

  const getUserTransactionsList = () => {
    setMessage("Loading...");
    axios
      .post(`/api/Transaction/GetUserTransactions`, {
        page: 0,
        size: 0,
        searchByReference: true,
        referenceNumber: "",
        senderFirstName: "",
        senderMiddleName: "",
        senderLastName: "",
        receiverFirstName: "",
        receiverMiddleName: "",
        receiverLastName: "",
        senderGender: "",
        receiverGender: "",
        receiverCountry: 0,
        senderCountry: 0,
        serviceType: 0,
        serviceProvider: 0,
        payerID: 0,
        fromDate: "",
        toDate: "",
        paidOutBy: "",
        paidOutFromDate: "",
        paidOutToDate: "",
        compliance: true,
        preApproved: true,
        senderPaymentReceived: true,
        origin: "",
        senderPayment: "",
        status: "",
      })
      .then((res) => {
        if (res.data) {
          setInitData(res.data);
          setUserTransactions(res.data);
        }
        setMessage("No transaction added yet");
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        setUserTransactions([]);
        setMessage("Unable to get user transactions");
      });
  };

  useMemo(() => {
    const firstPageIndex = (currentPage - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    const beneData = userTransactions.slice(firstPageIndex, lastPageIndex);
    setCurrentPageData(beneData);
  }, [currentPage, userTransactions]);

  const makeOnlinePayment = () => {
    axios
      .post(`/api/Transaction/MakeOnlinePayment`, {
        transactionID: refId,
        msisdn: mobileNumber,
        mpesaCode: "",
      })
      .then((res) => {
        toast.success("Online payment added successfully");
        setModal(false);
        setIsLoading(false);
      })
      .catch((err) => {
        toast.error("Error make online payment");
        setIsLoading(false);
      });
  };

  const repeatTransaction = (obj) => {
    setIsLoading(true);
    axios
      .get(`/api/Transaction/GetUserTransactionById/${obj.id}`)
      .then((res) => {
        navigate("/edit-money-transfer", { state: res.data });
        setIsLoading(false);
      })
      .catch((err) => {
        toast.error("Error to get user transaction by id");
        setIsLoading(false);
      });
  };

  const openTransactionDetails = (obj) => {
    navigate("/transactions-details", { state: obj });
  };

  const downloadReceipt = (obj) => {
    setIsLoading(true);
    axios
      .get(`/api/Transaction/GetPdfReceipt/${obj.id}`)
      .then((res) => {
        setIsLoading(false);
        setBase64Pdf(res.data);
      })
      .catch((err) => {
        toast.error("Error fetching receipt");
        setIsLoading(false);
      });
  };

  const getAction = (items) => {
    if (items.transactionstatus === "Pending") {
      if (checkDateDifference(items)) {
        return (
          <div className="display-caption steps-inputs-wrapper">
            <span className="steps-inputs-column3">
              <button
                className="w-100"
                onClick={() =>
                  redirectPaymentOption({
                    obj: items,
                    setIsLoading,
                    // setId,
                    primaryInfo: kycDetails,
                    navigate,
                  })
                }
              >
                Complete Payment
              </button>
            </span>
          </div>
        );
      } else {
        return (
          <div className="display-caption steps-inputs-wrapper">
            <span className="steps-inputs-column3">
              <button
                style={{
                  backgroundColor: "rgb(199, 214, 253)",
                  color: "grey",
                  borderRadius: " 4px",
                  padding: "13px 40px",
                  fontSize: " 13px",
                }}
                className="w-100"
                disabled
              >
                Failed
              </button>
            </span>
          </div>
        );
      }
    } else if (items.transactionstatus === "Paid Out") {
      return (
        <div className="display-caption steps-inputs-wrapper">
          <span className="steps-inputs-column3" style={{ display: "flex" }}>
            <button style={{ margin: "5px" }} className="w-100" onClick={() => repeatTransaction(items)}>
              Repeat
            </button>
            <button style={{ margin: "5px" }} className="w-100" onClick={() => downloadReceipt(items)}>
              <i className="fa fa-download" aria-hidden="true"></i>Pdf
            </button>
          </span>
        </div>
      );
    } else if (items.transactionstatus === "Locked") {
      return (
        <div className="display-caption steps-inputs-wrapper">
          <span className="steps-inputs-column3">
            <button className="w-100" onClick={() => openModal(items)}>
              Check Status
            </button>
          </span>
        </div>
      );
    } else {
      return (
        <div className="display-caption steps-inputs-wrapper">
          <span className="steps-inputs-column3">
            <button className="w-100" onClick={() => openModal(items)}>
              Check Reason
            </button>
          </span>
        </div>
      );
    }
  };

  const checkDateDifference = (txn) => {
    var now = moment(new Date());
    var start = moment(txn.transaction_date, "DD/MM/YYYY");
    var duration = moment.duration(now.diff(start));
    var days = duration.asDays();
    return days < 3;
  };

  return (
    <>
      {isLoading ? (
        <div className="mainDiv">
          <div className="loading"></div>
        </div>
      ) : null}

      {modal ? (
        <DashboardModal
          show={modal}
          close={closeModal}
          handleMobileNumber={handleMobileNumber}
          onSubmit={makeOnlinePayment}
          status={status}
          holdReason={holdReason}
          failedReason={failedReason}
        />
      ) : null}
      <div className="transactions-wrapper">
        <div className="transaction-table">
          <table>
            <thead>
              <tr>
                <th>Service Name</th>
                <th>Ref No.</th>
                <th>Amount</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {currentPageData.length < 1 ? (
                <tr>
                  <td colSpan="4" style={{ textAlign: "center" }}>
                    {message}
                  </td>
                </tr>
              ) : (
                <>
                  {currentPageData.map((items, index) => {
                    return (
                      <tr key={index}>
                        <td>{items.transaction_type}</td>
                        <td onClick={() => openTransactionDetails(items)}>{items.transaction_ref}</td>
                        <td>{items.send_amount}</td>
                        <td>
                          <span>{items.transactionstatus}</span>
                        </td>
                        <td>{getAction(items)}</td>
                      </tr>
                    );
                  })}
                </>
              )}
            </tbody>
          </table>
        </div>
        <div className="pagination-app">
          <Pagination
            currentPage={currentPage}
            totalCount={userTransactions.length}
            pageSize={pageSize}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </div>
        <a style={{ visibility: "hidden" }} href={`data:application/pdf;base64,${base64Pdf}`} ref={downloadRef}></a>
      </div>
    </>
  );
}
