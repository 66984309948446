import React, { useContext } from "react";
import { FormContext } from "../Beneficiary";
import DeliveryMethodContainer from "../Forms/DeliveryMethod/DeliveryMethodContainer";
import BankDetailsContainer from "../Forms/DeliveryTypes/BankDetails/BankDetailsContainer";
import CashDetailsConatiner from "../Forms/DeliveryTypes/CashDetails/CashDetailsContainer";
import MobileDetailsContainer from "../Forms/DeliveryTypes/MobileDetails/MobileDetailsContainer";
import LocationDetailsContainer from "../Forms/LocationDetails/LocationDetailsContainer";
import PersonalInfoConatiner from "../Forms/PersonalDetails/PersonalDetailsContainer";

function Step() {
  const { activeStepIndex, formData } = useContext(FormContext);

  let stepContent;
  let deliveryOptionId = formData.deliveryMethod.deliveryOptionId;

  switch (activeStepIndex) {
    case 0:
      stepContent = <DeliveryMethodContainer />;
      break;
    case 1:
      stepContent =
        deliveryOptionId === 1 ? (
          <BankDetailsContainer />
        ) : deliveryOptionId === 2 ? (
          <CashDetailsConatiner />
        ) : (
          <MobileDetailsContainer />
        );
      break;
    case 2:
      stepContent = <LocationDetailsContainer />;
      break;
    case 3:
      stepContent = <PersonalInfoConatiner />;
      break;
    default:
      break;
  }

  return stepContent;
}

export default Step;
