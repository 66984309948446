import { useEffect, useState } from "react";
import axios from "../api/axios/axios";

export default function useFetchBeneficiaryLocationDetails({
  beneficiaryID,
  deliveryOptionID,
}) {
  const [beneficiaryLocationDetails, setBeneficiaryLocationDetails] =
    useState(null);

  useEffect(() => {
    if (beneficiaryID && deliveryOptionID === 2) {
      (async function () {
        try {
          const res = await axios.get(
            `/api/Beneficiary/GetBeneficiaryLocationDetails/${beneficiaryID}`
          );

          setBeneficiaryLocationDetails(res.data);
        } catch (error) {
          setBeneficiaryLocationDetails(null);
        }
      })();
    }
  }, [beneficiaryID, deliveryOptionID]);

  return { beneficiaryLocationDetails, setBeneficiaryLocationDetails };
}
