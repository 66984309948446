import { useEffect, useState } from "react";
import axios from "../api/axios/axios";

export default function useFetchCities(countryId, stateCode) {
  const [cities, setCities] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (countryId && stateCode) {
      (async function () {
        try {
          setLoading(true);
          const response = await axios.get(
            `/api/Utility/GetCities/${countryId}/${stateCode}`
          );
          setCities(response.data);
        } catch (err) {
          setError(err);
          setCities([]);
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [countryId, stateCode]);

  return { cities, setCities, error, loading };
}
