import UpesiFormik from "../UpesiFormik";
import { useContext } from "react";
import { FormContext } from "../SendMoney";
import * as Yup from "yup";

export default function BeneficiaryandCountryInfo() {
  const stepValidationSchema = () =>
    Yup.object().shape({
      receivingCountry: Yup.string()?.when("isReceivingCountry", {
        is: true,
        then: Yup.string().required("Select Receiving Country"),
        otherwise: Yup.string(),
      }),
      deliveryOption: Yup.string()?.when("isDeliveryOption", {
        is: true,
        then: Yup.string().required("Select the delivery option"),
        otherwise: Yup.string(),
      }),
    });

  const { countriesData, setActiveStepIndex, formData, singleCountryData, fields, handleValueChange, onSubmit } =
    useContext(FormContext);

  const initialStepData = {
    ...formData,
    isReceivingCountry: true,
    isDeliveryOption: true,
  };

  const propsData = {
    countriesData,
    setActiveStepIndex,
    formData,
    singleCountryData,
    stepFields: fields["beneficiaryAndCountryInfo"],
    initialStepData,
    handleValueChange,
    stepValidationSchema,
    onSubmit,
    formId: "beneficiary-country-info",
  };


  return <UpesiFormik props={propsData} />;
}
