import React, { useState, createContext } from "react";
import "./Registration.css";
import Step from "./Steps/Step";
import Stepper from "./Stepper/Stepper";
export const FormContext = createContext();

const Registration = () => {
  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const [formData, setFormData] = useState({
    personalInfo: { firstName: "", lastName: "", dob: "" },
    email: "",
    mobileNo: "",
    password: "",
    confirmPassword: "",
    terms: false,
    operatingCountryID: "",
    code: "",
  });

  let values = { activeStepIndex, setActiveStepIndex, formData, setFormData };

  return (
    <FormContext.Provider value={values}>
      <div className="registration w-100 d-flex flex-column align-items-center justify-content-start">
        <Stepper />
        <Step />
      </div>
    </FormContext.Provider>
  );
};

export default Registration;
