import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

import axios from "../api/axios/axios";
import EncryptOTP from "../utils/EncrypPassword";

const timeOut = 120;
export default function Modal({ show, close, success, config }) {
  const [isDisabled, setDisabled] = useState(true);
  const [isLoading, setLoding] = useState(true);
  const [input, setInput] = useState("");
  const [otp, setOtp] = useState(null);
  const [error, setError] = useState(null);
  const [start, startCountDown] = useState(false);
  const [countDown, setCountdown] = useState(timeOut);

  const randomOTP = Math.floor(1000 + Math.random() * 9000);

  const encryptedOTP = EncryptOTP(randomOTP.toString());

  useEffect(() => {
    sendOtp(config.type, config.receiver, encryptedOTP);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config]);

  const onChange = (e) => {
    setInput(e.target.value);
  };

  const match = () => {
    if (otp === null) {
      setError("Invalid OTP");
    } else if (input.trim() === "" + otp) {
      success({ option: config.type });
    } else {
      setError("Invalid OTP");
    }
  };

  const otpExpired = () => {
    setOtp(null);
    setDisabled(false);
    startCountDown(false);
    setCountdown(timeOut);
  };

  const resend = () => {
    setDisabled(true);
    setLoding(true);

    sendOtp(config.type, config.receiver, encryptedOTP);
  };

  const clearAndClose = () => {
    setOtp(null);
    close();
  };

  const Timer = ({ time, timerComplete }) => {
    useEffect(() => {
      let mounted = false;
      setTimeout(() => {
        mounted = true;
        if (mounted) {
          countDown - 1 < 0 ? timerComplete() : setCountdown(countDown - 1);
        }
      }, 1000);
      return () => (mounted = false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [countDown, timerComplete]);

    return <>Resend {countDown > 0 ? <>in: {countDown} </> : null}</>;
  };

  const sendOtp = (type, receiver, random) => {
    if (type === "sms") {
      axios
        .get(
          `/api/user/sendverificationsms?receiver=${receiver}&message=${random}`
        )
        .then((response) => {
          if (response.data) {
            setOtp(randomOTP);
            setLoding(false);
            startCountDown(true);
          } else {
            clearAndClose();

            setLoding(false);
            setError("Invalid details");
          }
        })
        .catch((err) => {
          setError("Error sending OTP. Please try again");
          if (err.response.data) toast.error(err.response.data);
          clearAndClose();
        });
    } else if (type === "email") {
      axios
        .get(
          `/api/user/sendverificationemail?receiver=${receiver}&message=${random}`
        )
        .then((response) => {
          if (response.data) {
            setOtp(randomOTP);
            setLoding(false);
            startCountDown(true);
          } else {
            clearAndClose();

            setLoding(false);
            setError("Invalid details");
          }
        })
        .catch((err) => {
          setError("Erorr sending OTP. Please try again");
          if (err.response.data) toast.error(err.response.data);
          clearAndClose();
        });
    }
  };

  return (
    <>
      {show ? (
        <div className="modal ">
          <div className="modal-content">
            <div className="modal-header">
              <button onClick={() => clearAndClose()} className="close-btn">
                &times;
              </button>
            </div>
            <div className="modal-body otp-container">
              <h4 className="text-center">OTP Verification</h4>
              <>
                {isLoading ? (
                  <>
                    <p>Sending...</p>
                    <div className="loader"></div>
                  </>
                ) : (
                  <div className="process-ol">
                    <div className="add-label-input full-input money-half-input">
                      <label className="text-left">Enter OTP</label>
                      <input
                        className="p-0 px-4"
                        type="text"
                        value={input}
                        onFocus={() => setError(null)}
                        onChange={onChange}
                        placeholder=""
                      />
                      {error ? <div className="error-text">{error}</div> : null}
                    </div>
                  </div>
                )}
              </>
            </div>

            <>
              {isLoading ? (
                <></>
              ) : (
                <div className="modal-footer">
                  <div className="display-flex money-half-input align-items-center">
                    <div className="steps-inputs-column2 close">
                      <button
                        disabled={isDisabled}
                        onClick={resend}
                        className={isDisabled ? "mouse-not-allowed" : ""}
                      >
                        {start ? (
                          <Timer time={60} timerComplete={() => otpExpired()} />
                        ) : (
                          <>Resend</>
                        )}
                      </button>
                    </div>
                    <div className="padding-x steps-inputs-column1 close">
                      <button onClick={match}>Submit</button>
                    </div>
                  </div>
                </div>
              )}
            </>
          </div>
        </div>
      ) : null}
    </>
  );
}
