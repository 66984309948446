import React from "react";
import ExchangeRateCard from "../../Components/ExchangeRate/ExchangeRateCard";
import { Container } from "react-bootstrap";
import useWindowDimensions from "../../../../hooks/useWindowDimensions";

const MobileMoney = () => {
  const { width } = useWindowDimensions();
  return (
    <div
      className="container-fluid p-0"
      style={{ display: "flex", flexDirection: "column" }}
    >
      <div className="banner"></div>

      <Container
        className={`container justify-content-center ${
          width > 820 ? "w-75" : ""
        }`}
      >
        <div className="row pt-5">
          <div className="col-12 col-md-6 col-lg-6">
            <div className="row flex-direction-column text-start">
              <div className="col  p-1 w-75" id="swift-secureMoney">
                <h1>Swift Secure Money Transfers</h1>
              </div>

              <div className="col pt-3" id="swift-MobileMoney">
                <div className="">
                  <h1 className="mobile-money">Mobile Money</h1>
                </div>
                <div className="w-75" id="mobile-money-txt">
                  Fast and Convenient. Send money swiftly to your loved ones
                  registered mobile money account. There are no hidden fees, you
                  will see our fees upfront.
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-6 px-5 py-5">
            <ExchangeRateCard />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default MobileMoney;
