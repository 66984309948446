import { isEmptyObject } from "jquery";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "../api/axios/axios";
import defaultAvatar from "../assets/images/icons/user-pic.png";
import { ProfileContext } from "../routes/PrivateRoute";
import { getUserProfile, saveUserProfile } from "../utils/session";

export default function ArticleWrapper(props) {
  const [displayName, setDisplayName] = useState("...");
  const [avatar, setAvatar] = useState();
  const { kycDetails } = useContext(ProfileContext);

  const userProfile = localStorage.getItem("profilePicture");
  const user = getUserProfile();

  useEffect(() => {
    if (!userProfile) {
      getProfilePicture();
    } else {
      setAvatar(userProfile);
    }

    if (isEmptyObject(user)) {
      setDisplayName(kycDetails?.fullName?.toUpperCase());
      saveUserProfile({
        kyc: kycDetails?.kycConfirmation,
        fullName: kycDetails?.fullName?.toUpperCase(),
      });
    } else {
      setDisplayName(user.fullName?.toUpperCase());
    }
  }, [kycDetails?.fullName, kycDetails?.kycConfirmation, user, userProfile]);

  //TODO: create a custom hook
  const getProfilePicture = () => {
    axios
      .get(`/api/Utility/GetProfilePicture`)
      .then((res) => {
        if (res.data) {
          if (res.data) {
            localStorage.setItem("profilePicture", res.data);
            setAvatar(res.data);
          }
        }
      })
      .catch(() => {});
  };


  return (
    <article className="home-wrapper">
      <section>
        <div className="user-profile-details user-profile-bg">
          <div className="breadcrumb-container">
            <div className="user-profile-image">
              <figure>
                {avatar ? (
                  <img
                    style={{
                      border: "2px solid orange",
                      borderRadius: "50%",
                    }}
                    src={`data:image/jpeg;base64,${avatar}`}
                    alt="Avatar"
                  />
                ) : (
                  <img src={defaultAvatar} alt="Avatar" />
                )}
              </figure>
            </div>
            <div className="user-profile-name">
              <h3>
                <Link to={"/Profile"}>{displayName}</Link>
              </h3>
            </div>
          </div>
          <div className="about-upesi-header media-header w-100">
            <h3>
              <span>{props.heading}</span>
            </h3>
          </div>
        </div>

        {props.children}
      </section>
    </article>
  );
}
