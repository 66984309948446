/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { getSessionData } from "../utils/session";

const Policy = () => {
  const isLoggedIn = getSessionData("token");

  return (
    <div>
      <article className={!isLoggedIn ? "security-wrapper" : ""}>
        <section>
          {!isLoggedIn ? (
            <div className="privacy-banner">
              <div className="privacy-header">
                <h3>
                  <span>Privacy Policy</span>
                </h3>
              </div>
              <p>Version: effective on 22 May 2019</p>
            </div>
          ) : (
            ""
          )}

          <div className="security-lists-details">
            <div className="security-details">
              <h4>Privacy Policy</h4>

              <div>
                <h4>1. Introduction</h4>

                <p>
                  This privacy notice aims to inform you about how we collect
                  and process any personal data that we collect from you, or
                  that you provide to us.
                </p>

                <p>
                  It covers information that could identify you (“personal
                  data”) and information that could not. In the context of the
                  law and this notice, “process” means collect, store, transfer,
                  use or otherwise act on information. It tells you about your
                  privacy rights and how the law protects you.
                </p>

                <p>
                  We are committed to protecting your privacy and the
                  confidentiality of your personal data. We undertake to
                  preserve the confidentiality of all information you provide to
                  us and hope that you reciprocate.
                </p>

                <p>
                  Our policy complies with the Kenya Data Protection Act and the
                  Data Protection Regulations.
                </p>

                <p>
                  The law requires us to tell you about your rights and our
                  obligations to you in regard to the processing and control of
                  your personal data.
                </p>
              </div>

              <div>
                <h4>2. Definitions</h4>

                <p>References to “You” means:</p>

                <p>
                  2.1. Customer- the person who subscribes to, uses, or
                  purchases any of our products and services or accesses our
                  websites and includes any person who accesses any of the
                  products and services you have subscribed to.
                </p>

                <p>
                  2.2. Any agent, dealer and/or merchant who has signed an
                  agreement with us and is recognized as a merchant or agent in
                  accordance with any applicable laws or Regulations.
                </p>

                <p>
                  2.3. “Upesi Money Transfer Limited”, “Upesi”, “we” or “us”,
                  “our” and “ours” means Upesi Money Transfer Limited.
                </p>

                <p>
                  2.4. Any visitor that is a person (including
                  contractors/sub-contractors or any third parties) who gains
                  access to any Upesi’s premises.
                </p>

                <p>
                  2.5. Any supplier who has been contracted by Upesi and
                  executed a Supplier contract.
                </p>
                <p>
                  2.6. The word “includes” means that what follows is not
                  necessarily exhaustive and therefore the examples given are
                  not the only things/situations included in the meaning or
                  explanation of that text.
                </p>
              </div>

              <div>
                <h4>3. Collection of Information</h4>

                <p>
                  3.1. We collect your personal information with your knowledge
                  and consent when you do any of the following (please note that
                  this list is not exhaustive):
                </p>
                <p>3.1.1. register for a specific product or services;</p>
                <p>
                  3.1.2. buy, subscribe to, or use of Upesi product or service
                  online, on the cloud, on a mobile or other device or at Upesi
                  Offices;
                </p>
                <p>
                  3.1.3. subscribe to Upesi or third-party premium rates
                  services, Short Message Service (SMS), email or social media
                  platforms;
                </p>
                <p>
                  3.1.4. ask Upesi for more information about a product or
                  service or contact Upesi with a query or complaint;
                </p>
                <p>
                  3.1.5. respond to or participate in a survey, marketing
                  promotion, prize competition or special offer;
                </p>
                <p>
                  3.1.6. visit, access or use Upesi or third-party websites;
                </p>
                <p>
                  3.1.7. We may also collect your information from other
                  organizations including, fraud prevention agencies and
                  business directories;
                </p>
                <p>
                  3.1.8. We may collect your information when you interact with
                  us as a supplier, agent, Service Providers, or dealer as
                  prescribed in this statement;
                </p>
                <p>
                  3.1.9. We also collect information when you visit any of our
                  premises.
                </p>
                <p>
                  3.2. We do not onboard minors (any person under 18 years of
                  age) except where you additionally register on their behalf as
                  their parent and/ or legal guardian. If you allow a child to
                  use our services, you should be aware that their personal
                  information could be collected as described in this policy.
                </p>
              </div>

              <div>
                <h4>4. What Information is collected?</h4>
                <p>
                  4.1. We may collect, use, store and transfer different kinds
                  of personal data about you. We have collated these into groups
                  as follows:
                </p>
                <p>
                  4.1.1. <b>Your identity</b> includes information such as first
                  name, last name, title, date of birth, age, images, and other
                  identifiers that you may have provided at some time.
                </p>
                <p>
                  4.1.2. <b>Your contact information</b> includes information
                  such as email address, telephone numbers and any other
                  information you have given to us for the purpose of
                  communication or meeting.
                </p>
                <p>
                  4.1.3. <b>Recruitment data</b> includes information such as
                  your curriculum vitae, educational background, professional
                  qualifications, reference contact details and every other
                  information you have given us for the purpose of hiring
                  prospective applicants.
                </p>
                <p>
                  4.1.4. <b>Transaction data</b> includes information such as
                  your name, your contact details, your payment card details or
                  other payment details and the transaction details. The source
                  of the transaction data comes from you and/or our payment
                  services provider.
                </p>
                <p>
                  4.1.5. <b>Technical data</b> includes your internet protocol
                  (IP) address, browser type and version, time zone setting and
                  location, browser plug-in types and versions, operating system
                  and platform and other technology on the devices you use to
                  access this website.
                </p>
                <p>
                  4.1.6. <b>Marketing data</b> includes your preferences in
                  receiving marketing from us; communication preferences;
                  responses and actions in relation to your use of our services.
                </p>
                <p>
                  4.1.7. We use <b>Closed Circuit Television (CCTV)</b>{" "}
                  surveillance recordings. CCTV devices are installed at
                  strategic locations to provide a safe and secure environment
                  in all Upesi premises as a part of our commitment to community
                  safety, security and crime prevention.
                </p>
                <p>
                  4.1.8. <b>Accident and incident reporting data</b> We collect
                  your personal information when you visit us for purposes of
                  accident and incident reporting. We will collect personal data
                  from the injured party or person suffering from ill health,
                  such as, Name, Address, Age, next of kin, details of the
                  incident to include any relevant medical history. We have a
                  legal duty to document workplace accidents and incidents and
                  to report certain types of accidents, injuries and dangerous
                  occurrences arising out of its work activity to the relevant
                  enforcing authority.
                </p>
                <p>
                  4.1.9. We may aggregate <b>anonymous data</b> such as
                  statistical or demographic data for any purpose. Anonymous
                  data is data that does not identify you as an individual.
                  Aggregated data may be derived from your personal data but is
                  not considered personal information in law because it does not
                  reveal your identity.
                </p>
              </div>

              <div>
                <h4>5. Use of Information</h4>
                <p>
                  5.1. We may use and analyze your information for the following
                  purposes:
                </p>
                <p>
                  5.1.1. Processing products and services that you have applied
                  for from Upesi or from third parties on our e-commerce
                  platforms;
                </p>
                <p>
                  5.1.2. Billing you for the product and service you have
                  applied for;
                </p>
                <p>5.1.3. Responding to any of your queries or concerns;</p>
                <p>
                  5.1.4. Verifying your identity information through publicly
                  available and/or restricted government databases in order to
                  comply with applicable regulatory requirements;
                </p>
                <p>
                  5.1.5. Keeping you informed generally about new products and
                  services and contacting you with offers or promotions based on
                  how you use our or third-party products and services unless
                  you opt out of receiving such marketing messages (you may
                  contact Upesi at any time to opt out of receiving marketing
                  messages);
                </p>
                <p>
                  5.1.6. to comply with any legal, governmental, or regulatory
                  requirement or for use by our lawyers in connection with any
                  legal proceedings;
                </p>
                <p>
                  5.1.7. In business practices including to quality control,
                  training, and ensuring effective systems operations;
                </p>
                <p>
                  5.1.8. To understand how you use our products and services for
                  purposes of developing or improving products and services;
                </p>
                <p>5.1.9. Preventing and detecting fraud or other crimes;</p>
                <p>
                  5.1.10. For research, statistical, survey and other scientific
                  or business purposes;
                </p>
                <p>
                  5.1.11. Provide aggregated data (which do not contain any
                  information which may identify you as an individual) to third
                  parties for research and scientific purpose;
                </p>
                <p>5.1.12. Administer any of our online platforms/websites.</p>
              </div>

              <div>
                <h4>6. Categories of Data</h4>

                <p>
                  Categories of Personal Data as defined in the Data Protection
                  Act, Laws of Kenya, may be processed depending on the
                  particular types of products and services you have subscribed
                  to or purchased.
                </p>
              </div>

              <div>
                <h4>7. Lawful Basis for processing your information</h4>

                <p>
                  The law requires us to determine defined bases we process
                  different categories of your personal information, and to
                  notify you of the basis for each category. If the basis on
                  which we process your personal information is no longer
                  relevant, then we shall immediately stop processing your data.
                </p>
              </div>

              <div>
                <h4> 8. We Process your information based on the following:</h4>
                <p>
                  <h4>8.1. Contractual obligation with you</h4>
                  When you agree to our terms and conditions, a contract is
                  formed between you and us. In order to carry out our
                  obligations under that contract we must process the
                  information you give us. Some of this information may be
                  personal information.
                  <br />
                  We may use it in order to:
                  <br />
                  <div className={"mx-4"}>
                    • provide you with our services <br />
                    • provide you with suggestions and advice on services.
                    <br />
                  </div>
                  We process this information on the basis that there is a
                  contract between us, or that you have requested we use the
                  information before we enter into a legal contract. We shall
                  continue to process this information until the contract
                  between us ends or is terminated by either party under the
                  terms of the contract.
                </p>

                <p>
                  <h4> 8.2. Your consent </h4>
                  Through certain actions when otherwise there is no contractual
                  relationship between us, such as when you browse our website
                  or ask us to provide you more information about our business,
                  including job opportunities and our services, you provide your
                  consent to us to process your personal information (Name,
                  Email address & Telephone Number).
                  <br />
                  Wherever possible, we aim to obtain your explicit consent to
                  process this information.
                  <br />
                  We continue to process your information on this basis until
                  you withdraw your consent or it can be reasonably assumed that
                  your consent no longer exists.
                  <br />
                  You may withdraw your consent at any time by instructing us at{" "}
                  <a href={"mailto:dpo@upesi.co.ke"}>dpo@upesi.co.ke</a>
                </p>

                <p>
                  <h4>8.3. Legal obligation</h4>
                  Sometimes, we must process your information in order to comply
                  with a statutory obligation.
                  <br />
                  For example, we may be required to give information to legal
                  authorities if they so request or if they have the proper
                  authorization such as a search warrant or court order. This
                  may include your personal information.
                  <br />
                </p>

                <p>
                  <h4>
                    {" "}
                    8.4. To protect your vital interest or that of another data
                    subject
                  </h4>
                  Sometimes, we must process your information in order to
                  protect an interest which is essential for your life or that
                  of another natural person. For example, in emergency medical
                  care, when we need to process your personal data for medical
                  purposes but you are incapable of giving consent to the
                  processing.
                </p>

                <p>
                  <h4>
                    {" "}
                    8.5. Public interest or exercise of official public mandate
                    vested in us
                  </h4>
                  Sometimes we must process your information for the performance
                  of a task carried out in the public interest or in the
                  exercise of official authority vested in us, such as when we
                  are carrying out a specific task in the public interest which
                  is laid down by law.
                </p>
              </div>

              <div>
                <h4>9. Retention of Information</h4>

                <p>
                  Information held about you shall be retained for as long as
                  the purpose for which the information was collected continues.
                  <br /> <br />
                  The information shall then be destroyed unless its retention
                  is required to satisfy legal, regulatory, or accounting
                  requirements or to protect Upesi’s interests. <br /> <br />
                  Except as otherwise mentioned in this privacy notice, we keep
                  your personal information only for as long as required by us:{" "}
                  <br />
                  <div className={"m-xl-4"}>
                    • to provide you with the services you have requested;
                    <br />
                    • to comply with other law, including for the period
                    demanded by our authorities;
                    <br />
                    • to support a claim or defense in court.
                    <br />
                  </div>
                </p>
              </div>

              <div>
                <h4> 10. Disclosure of Information</h4>

                <p>
                  10.1. Any disclosure of your information shall be in
                  accordance with applicable laws and regulations. Upesi shall
                  assess and review each application for information and may
                  decline to grant such information to the requesting party.
                </p>
                <p>10.2. We may disclose your information to:</p>
                <p>
                  10.2.1 law-enforcement agencies, regulatory authorities,
                  courts, or other statutory authorities in response to a demand
                  issued with the appropriate lawful mandate and where the form
                  and scope of the demand is compliant with the law.
                </p>
                <p>
                  10.2.2 Any regulatory, supervisory, governmental, or
                  quasi-governmental authority with jurisdiction over Upesi;
                </p>
                <p>
                  10.2.3 Any agent, contractor or third-party service provider,
                  professional adviser or any other person under a duty of
                  confidentiality to Upesi;
                </p>
                <p>
                  10.2.4 Any financial institution with which Upesi has or
                  proposes to have dealings.
                </p>
                <p>
                  10.2.5 Our subsidiaries, associates, partners, software
                  developers or agents who are involved in delivering Upesi
                  products and services you order or use;
                </p>
                <p>
                  10.2.6 Fraud prevention and Anti-money laundering agencies;
                </p>
                <p>
                  10.2.7 publicly available and/or restricted government
                  databases to verify your identity information in order to
                  comply with regulatory requirements;
                </p>
                <p>
                  10.2.8 Survey agencies that conduct surveys on behalf of
                  Upesi;
                </p>
                <p>
                  10.2.9 Emergency service providers when you make an emergency
                  call (or where such disclosure to emergency service providers
                  is necessary for your rescue, health and safety) including
                  your approximate location;
                </p>
                <p>
                  10.3. We shall not release any information to any individual
                  or entity that is acting beyond its legal mandate.
                </p>
                <p>
                  10.4. We will get your express consent before we share your
                  personal data with any third party for direct marketing
                  purposes.
                </p>
              </div>

              <div>
                <h4> 11. Direct Marketing</h4>

                <p>
                  We may share promotional messages with you from time to time.
                  You can request to opt out at any given time, by following the
                  necessary prompts or contacting us through{" "}
                  <a>0709 656 000.</a>
                </p>
              </div>

              <div>
                <h4>12. The Use of Hyperlinks</h4>
                <p>
                  12.1. Our websites may provide hyperlinks to other locations
                  or websites on the Internet. These hyperlinks lead to websites
                  published or operated by third parties who are not affiliated
                  with or in any way related to us and have been included in our
                  website to enhance your user experience and are presented for
                  information purposes only.
                </p>
                <p>
                  12.2. We do not endorse, recommend, approve or guarantee any
                  third-party products and services by providing hyperlinks to
                  an external website or webpage and do not have any
                  co-operation with such third parties unless otherwise
                  disclosed. We are not in any way responsible for the content
                  of any externally linked website or webpage.
                </p>
                <p>
                  12.3. By clicking on a hyperlink, you will leave the Upesi
                  webpage and accordingly you shall be subject to the terms of
                  use and privacy policy of the other website that you choose to
                  visit.
                </p>
              </div>

              <div>
                <h4>13. The Use Of Cookies</h4>
                <p>
                  We may store some information (using "<b>cookies</b>") on your
                  computer when you visit our websites. This enables us to
                  recognise you during subsequent visits. The type of
                  information gathered is non-personal (such as: the Internet
                  Protocol (IP) address of your computer, the date and time of
                  your visit, which pages you browsed and whether the pages have
                  been delivered successfully.
                </p>

                <p>
                  We may also use this data in aggregate form to develop
                  customised services - tailored to your individual interests
                  and needs. Should you choose to do so, it is possible
                  (depending on the browser you are using), to be prompted
                  before accepting any cookies, or to prevent your browser from
                  accepting any cookies at all. This will however cause certain
                  features of the web site not to be accessible.
                </p>
              </div>

              <div>
                <h4>14. Access to and updating your Information</h4>

                <p>
                  14.1. If you believe that our records contain inaccurate or
                  incomplete information about you, please visit any of our
                  office to make the necessary amendments.
                </p>
                <p>
                  14.2. Some changes will only be made once the necessary
                  supporting documentation has been obtained.
                </p>
                <p>
                  14.3. We will take reasonable steps to investigate your
                  concerns and correct inaccurate information in a timely
                  manner.
                </p>
              </div>

              <div>
                <h4>15. Safeguarding and Protection of Information</h4>

                <p>
                  Upesi has put in place technical and operational measures to
                  ensure integrity and confidentiality of your data via controls
                  around: information classification, access control,
                  cryptography, physical and environmental security and
                  monitoring and compliance.
                </p>
              </div>

              <div>
                <h4> 16. Your Rights</h4>
                <p>
                  16.1. Subject to legal and contractual exceptions, you have
                  rights under Data Protection Laws in relation to your personal
                  data. These are listed below: -
                </p>
                <p>
                  16.1.1 Right to be informed that we are collecting your
                  personal data;
                </p>
                <p>
                  16.1.2 . Right to access personal data and request for
                  information on how we process it;
                </p>
                <p>
                  16.1.3 .Right to request that we correct your personal data
                  where it is inaccurate or incomplete;
                </p>
                <p>
                  16.1.4 Right to request that we erase your personal data
                  noting that we may continue to retain your information if
                  obligated by the law or entitled to do so;
                </p>
                <p>
                  16.1.5 Right to object and withdraw your consent to processing
                  of your personal data. We may continue to process if we have a
                  legitimate or legal reason to do so;
                </p>
                <p>
                  16.1.6 Right to request restricted processing of your personal
                  data noting that we may be entitled or legally obligated to
                  continue processing your data and refuse your request;
                </p>
                <p>
                  16.1.7 Right to request transfer of your personal data in [an
                  electronic format].
                </p>
                <p>
                  16.2. If you wish to exercise any of the rights set out above,
                  please contact us on <a>0709 656 000</a> or{" "}
                  <a href={"mailto:dpo@upesi.co.ke"}>dpo@upesi.co.ke</a>
                </p>
                <p>
                  16.3. We may need to request specific information from you to
                  help us confirm your identity and ensure your right to access
                  your personal data (or to exercise any of your other rights).
                  This is a security measure to ensure that personal data is not
                  disclosed to any person who has no right to receive it. We may
                  also contact you to ask you for further information in
                  relation to your request to speed up our response.
                </p>
                <p>
                  16.4. We try to respond to all legitimate requests within a
                  reasonable time. Occasionally it could take us longer if your
                  request is particularly complex or you have made a number of
                  requests. In this case, we will notify you and keep you
                  updated.
                </p>
              </div>

              <div>
                <h4>17. Communication</h4>
                <p>
                  17.1. If you would like to contact us regarding this privacy
                  policy, you can email us on{" "}
                  <a href={"mailto:dpo@upesi.co.ke"}>dpo@upesi.co.ke</a>
                </p>
                <p>
                  17.2. In providing your telephone, postal and e-mail address,
                  or similar details, you agree that we may contact you by these
                  methods to keep you informed about our products and services
                  or for any other reason. If you prefer not to be kept informed
                  of our products and services, please unsubscribe from the
                  service by following the necessary prompts.
                </p>
                <p>
                  17.3. In order to maintain the security of our systems,
                  protect our staff, record transactions, and, in certain
                  circumstances, to prevent and detect crime or unauthorized
                  activities, Upesi reserves the right to monitor all internet
                  communications including web and email traffic into and out of
                  its domains.
                </p>
              </div>

              <div>
                <h4>18. Right to Lodge Complaint</h4>
                <p>
                  You have the right to lodge a complaint with the relevant
                  supervisory authority that is tasked with personal data
                  protection within the Republic of Kenya
                </p>
              </div>

              <div>
                <h4>19. Non-Compliance With This Policy</h4>
                <p>
                  Upesi shall have the right to stop offering you the service or
                  terminate any agreement with you for failure to comply with
                  the provisions of <b>this policy</b> and reject any
                  application for information contrary to <b>this policy.</b>
                </p>
              </div>

              <div>
                <h4> 20. Amendments to This Policy</h4>

                <p>
                  20.1. We reserve the right to amend its prevailing Data
                  Protection and Privacy Policy at any time and will place any
                  such amendments on this Website. This Data Protection and
                  Policy is not intended to, nor does it, create any contractual
                  rights whatsoever or any other legal rights, nor does it
                  create any obligations on us in respect of any other party or
                  on behalf of any party.
                </p>
                <p>
                  20.2. Your continued access or use of the Website after any
                  modifications have become effective shall be deemed your
                  conclusive acceptance of the modified data.
                </p>
              </div>

              <div>
                <h4> 21. Job Applicants</h4>

                <p>
                  21.1. Personal information provided on our data collection
                  modes in connection with an application for employment will be
                  used to determine your suitability for a position applied for.
                  Your information may also be used to monitor our recruitment
                  initiatives and equal opportunities policies.
                </p>
                <p>
                  21.2. Applicant details may be disclosed to third parties to
                  verify or obtain additional information including education
                  institutions and current/previous employers.
                </p>

                <p>
                  21.3. Unsuccessful applications may be retained to match your
                  skills to future job opportunities. These are usually retained
                  for up to 24 months but please let us know if you do not wish
                  us to retain your information for this purpose.
                </p>
                <p>
                  21.4. If you are providing your details in connection with an
                  application for employment, please refer to our “Data
                  Protection and Privacy Policy – Job Applicants” which will be
                  displayed when you apply (see our ‘Careers’ page). This Policy
                  will not alter or affect any information otherwise provided by
                  you to Upesi.
                </p>
              </div>

              <div>
                <h4>22. Social Media</h4>

                <p>
                  We operate and communicate through our designated channels,
                  pages and accounts on some social media sites to inform, help
                  and engage with our customers.
                </p>
                <p>
                  We monitor and record comments and posts made about us on
                  these channels so as to improve our services.
                </p>

                <p>
                  We are not responsible for any information posted on those
                  sites other than the information posted by our designated
                  officials. We do not endorse the social media sites
                  themselves, or any information posted on them by third parties
                  or other users.
                </p>
                <p>
                  We do not give investment, tax, or other professional advice
                  on social media sites.
                </p>
                <p>
                  When you engage with us through social media your Personal
                  Data may be processed by the site owner. The said procession
                  is outside our control and may be in a country outside Kenya
                  that may have different privacy principles.
                </p>
              </div>
            </div>
          </div>
        </section>
      </article>
    </div>
  );
};

export default Policy;
