import React from "react";

export default function LogoutModal(props) {
  return (
    <>
      {props.show ? (
        <div className="modal">
          <div className="modal-content">
            <div className="modal-header">
              <span className="close" onClick={props.close}>
                ×
              </span>
            </div>
            <div className="modal-body otp-container">
              <h4 className="text-center">Logout</h4>
              <p></p>
              <div className="process-ol">
                <div className="add-label-input full-input money-half-input">
                  <label style={{ textAlign: "center", fontSize: "15px" }}>Are your sure You want to logout ?</label>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <div className="display-flex money-half-input align-items-center">
                <div className="padding-x steps-inputs-column2 close">
                  <button onClick={props.close}>No</button>
                </div>
                <div className="padding-x steps-inputs-column1 close">
                  <button onClick={props.handleLogout}>Yes</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}
