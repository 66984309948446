import { ErrorMessage, Form, Formik } from "formik";
import React, { useContext, useState } from "react";
import * as Yup from "yup";

import { FormContext } from "../Registration";
import OTPVerification from "./OTP";

const ValidationSchema = Yup.object().shape({
  mobileNo: Yup.string()
    .min(9, "Exact 9 digits allowed for mobile number")
    .max(9, "Exact 9 digits allowed for mobile number")
    .matches(/^[0-9\s]+$/, "Only numbers are allowed")
    .required("Invalid mobile number!"),
});

function PhoneNumber() {
  const { activeStepIndex, setActiveStepIndex, formData, setFormData } =
    useContext(FormContext);
  const [showOTPView, setShowOTPView] = useState(false);
  const [config, setConfig] = useState({ type: "", receiver: "" });

  const handleChangeInput = (val, setFieldValue) => {
    setFieldValue("mobileNo", val.target.value, false);
  };

  const nextStep = () => {
    setActiveStepIndex(activeStepIndex + 1);
  };

  const onSubmit = (values) => {
    const data = { ...formData, ...values };

    setFormData(data);
    setConfig({
      type: "sms",
      receiver: `${values.code.replace("+", "")}${values.mobileNo}`,
    });
    setShowOTPView(true);
  };

  return (
    <>
      {showOTPView ? (
        <OTPVerification
          success={nextStep}
          config={config}
          setShowOTPView={setShowOTPView}
        />
      ) : (
        <Formik
          initialValues={{ code: formData.code, mobileNo: formData.mobileNo }}
          validationSchema={ValidationSchema}
          onSubmit={(val) => onSubmit(val)}
        >
          {({
            handleBlur,
            handleSubmit,
            setFieldValue,
            errors,
            touched,
            values,
          }) => (
            <Form
              id="registration-form"
              className="row justify-content-center "
              onSubmit={handleSubmit}
            >
              <div className="h3 text-center my-4 step-title">
                Verify your phone number with a code
              </div>

              <div className="form-group col-12 col-sm-8 col-lg-6 col-xl-4 row">
                <div className="col-2 p-0" id="code-input-section">
                  <input
                    className="form-control form-input"
                    type="text"
                    disabled
                    name="code"
                    value={values.code}
                  />
                </div>
                <div className="col-10 p-0">
                  <input
                    className={`form-control form-input mobile-input  ${
                      errors.mobileNo && touched.mobileNo ? "form-error" : ""
                    }`}
                    value={values.mobileNo}
                    type="number"
                    name="mobileNo"
                    onChange={(val) => handleChangeInput(val, setFieldValue)}
                    onBlur={handleBlur}
                    placeholder="7XXXXXXXX"
                  />
                </div>
              </div>
              <div className="error-text  col-12 text-center my-2">
                <ErrorMessage name="mobileNo" />
              </div>

              <div className="col-12 col-sm-8 col-lg-6 col-xl-4">
                <button className="btn btn-orange w-100" type="submit">
                  Send verification code
                </button>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
}

export default PhoneNumber;
