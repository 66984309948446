import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Dropdown,
  DropdownButton,
  Form,
  InputGroup,
} from "react-bootstrap";
import { useLocation } from "react-router-dom";

import { toast } from "react-toastify";
import axios from "../../../../api/axios/axios";
import useFetchOperationalCountries from "../../../../hooks/useFetchOperationalCountries";
import styles from "./ExchangeRateCard.module.css";

const ExchangeRateCard = () => {
  let nf = new Intl.NumberFormat("en-US", { maximumFractionDigits: 10 });
  const [receivingCountry, setReceivingCountry] = useState(null);
  const [amount, setAmount] = useState(nf.format(0));
  const [receivingAmount, setReceivingAmount] = useState(0.0);
  const [exchangeRate, setExchangeRate] = useState(0.0);
  const [EXRateCalculated, setEXRateCalculated] = useState(false);
  const [value, setValue] = useState("");
  const [userCountryId, setUserCountryId] = useState(212);
  const [isloading, setLoading] = useState(false);

  const { pathname } = useLocation();
  const ugScreen = pathname.includes("ug");

  const { countries } = useFetchOperationalCountries(userCountryId);

  useEffect(() => {
    if (ugScreen) {
      setUserCountryId(78);
    }

    return () => {};
  }, [ugScreen]);

  const handleSelectedCountry = (e) => {
    setValue(e);

    setEXRateCalculated(false);

    setReceivingAmount(0.0);
    setExchangeRate(0.0);

    const country = countries?.find((country) => country.currencyCode === e);
    setReceivingCountry(country);
  };

  const handleformData = (e) => {
    let value = parseFloat(e.target.value.replace(/,/g, ""));

    if (isNaN(value) === true) {
      return setAmount(nf.format(0));
    }

    setAmount(nf.format(value));
  };

  const calculateReceiverAmount = (e) => {
    e.preventDefault();
    setLoading(true);

    if (!receivingCountry?.id || !receivingCountry?.currencyID) {
      setLoading(false);

      return toast.warning("Complete the input first");
    }

    axios
      .post(`/api/Transaction/GetReceiverAmount`, {
        fromCountryID: ugScreen ? 78 : 212,
        fromCurrencyID: ugScreen ? 38 : 92,
        toCountryID: receivingCountry.id,
        toCurrencyID: receivingCountry.currencyID,
        amount: Number(parseFloat(amount.replace(/,/g, ""))),
      })
      .then((res) => {
        setEXRateCalculated(true);
        setReceivingAmount(res.data.recieverAmount);
        setExchangeRate(res.data.exchangeRate);
        setLoading(false);
      })
      .catch((err) => {
        setEXRateCalculated(false);
        setReceivingAmount(0.0);
        setExchangeRate(0.0);
        setLoading(false);
      });
  };

  return (
    <Card className={styles.EX_Rate__Card} bg="white" text="dark">
      <Card.Header className={styles.card_header}>
        <h1 className={styles.card_header__title}>Exchange Rate</h1>
        <h5>
          {!EXRateCalculated
            ? `${ugScreen ? "1 UGX" : "1 KES"} = Receiving Country`
            : receivingAmount > 0 && exchangeRate > 1
            ? `${ugScreen ? "1 UGX" : "1 KES"} = ${exchangeRate} ${
                receivingCountry?.code
              }`
            : `1 ${receivingCountry?.currencyCode} = ${(
                1 / exchangeRate
              ).toFixed(4)} ${ugScreen ? "UGX" : "KES"}`}
        </h5>
      </Card.Header>
      <Card.Body>
        <Form className={styles.form} onSubmit={calculateReceiverAmount}>
          <InputGroup className="mb-3">
            <Form.Control
              placeholder="What You Send"
              aria-label="What You Send"
              aria-describedby="addon1"
              value={amount}
              onChange={handleformData}
              onKeyDown={(e) => {
                if (e.key === ",") {
                  e.preventDefault();
                } else if (e.key === ".") {
                  if (e.target.value.includes(".")) {
                    e.preventDefault();
                  } else {
                    setAmount(e.target.value + e.key);
                    e.preventDefault();
                  }
                }
              }}
              required
            ></Form.Control>

            <InputGroup.Text id="addon1" className={styles.select}>
              <Form.Select id="addon1">
                <option>{ugScreen ? "UGX" : "KES"}</option>
              </Form.Select>
            </InputGroup.Text>
          </InputGroup>
          <InputGroup className="mb-3">
            <Form.Control
              placeholder="What You Receive"
              aria-label="What You Receive"
              aria-describedby="basic-addon2"
              value={nf.format(receivingAmount)}
              readOnly
            />
            <InputGroup.Text id="addon2" className={styles.select}>
              <Form.Control
                id="receiver-input"
                aria-describedby="basic-addon2"
                value={value}
                readOnly
              />
              <DropdownButton
                className={styles.ex_rate_dropdown}
                alignRight
                title=""
                id="dropdown-menu-align-right"
                onSelect={handleSelectedCountry}
              >
                {countries?.length === 0 && (
                  <Dropdown.Item>loading countries... </Dropdown.Item>
                )}
                {countries?.map((country) => {
                  return (
                    <Dropdown.Item
                      eventKey={country.currencyCode}
                      key={country.id}
                    >
                      {country.name}
                    </Dropdown.Item>
                  );
                })}
              </DropdownButton>
            </InputGroup.Text>
          </InputGroup>
          <Form.Text className={styles.info} id="passwordHelpBlock" muted>
            Exchange Rates may vary slighly at time of order
          </Form.Text>

          <Button
            className={`${styles.ex_rate__btn} ${
              isloading ? "load-button spin" : "load-button"
            }`}
            disabled={isloading}
            type="submit"
          >
            {"Calculate"}
            <span className="spinner"></span>
          </Button>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default ExchangeRateCard;
