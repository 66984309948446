import React from "react";
import { Link } from "react-router-dom";
import { version } from "../../utils/version";

const Footer = () => {
  let date = new Date();
  return (
    <footer className="footer-wrapper">
      <div className="bottom-footer-wrapper">
        <div className="footer-copyright">
          <p>
            &copy; {date.getFullYear()} Upesi Money Transfer. All Rights
            Reserved.
          </p>
        </div>
        <div className="footer-links">
          <ul>
            <li>
              <Link to="/policy">Privacy Policy</Link>
            </li>
            <li>
              <Link to="/terms">T&C</Link>
            </li>
          </ul>
        </div>
        <div className="footer-links">
          <ul>
            <li>
              <p className="version">version : {version}</p>
            </li>
          </ul>
        </div>
      </div>
      <Link to="/" className="go-top">
        <p>Go Top</p>
        <i className="fa fa-angle-up" aria-hidden="true"></i>
      </Link>
    </footer>
  );
};

export default Footer;
