export const CustomStyle = {
  option: (provided, state) => ({
    ...provided,
    textAlign: "left",
  }),

  control: (provided, state) => ({
    ...provided,
    border: "1px solid red !important",
    display: "Flex",
    alignItems: "center",
    color: "green !important",
    boxShadow: state.isFocused ? 0 : 0,
    "&:hover": {
      border: "1px solid red ",
    },
  }),
};

export const defaultStyle = {
  option: (provided, state) => ({
    ...provided,
    textAlign: "left",
    // background: "pink !important",
  }),

  singleValue: (provided, state) => ({
    ...provided,
    color: "#455A64 !important",
  }),
  control: (provided, state) => ({
    ...provided,
    background: "#f5f5f5 !important",
    border: "1px solid rgba(15, 28, 56, 0.5)",
    boxShadow: state.isFocused ? 0 : 0,
    "&:hover": {
      border: "1px solid rgba(15, 28, 56, 0.5)",
    },
    cursor: state.isDisabled ? "not-allowed" : "pointer",
    pointerEvents: state.isDisabled ? "visible" : "all",
  }),
};
