import React, { useState } from "react";
import { checkPasswordStrength } from "../utils/PasswordStrength";
import { Formik, Form, ErrorMessage } from "formik";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import * as Yup from "yup";
import { BASE_URL } from "../constants/Configuration";
import axios from "../api/axios/axios";

const ResetPasswordSchema = Yup.object().shape({
  //   email: Yup.string().email("Invalid email").required("Required"),
  newPassword: Yup.string()
    .min(6, "Too Short!")
    .max(20, "Too Long!")
    .required("Required"),
  confirmPassword: Yup.string()
    .min(6, "Too Short!")
    .max(20, "Too Long!")
    .required("Required"),
});

const ResetPassword = (props) => {
  const [params] = useSearchParams();
  const code = params.get("code");
  const email = params.get("user");
  const navigate = useNavigate();
  const [error, setError] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [uiBar, setUiBar] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setconfirmPassword] = useState("");
  const [passwordStrength, setPasswordStrength] = useState("");
  const [hidePassword, setHidePassword] = useState(true);
  const [hideConfirmPassword, setHideConfirmPassword] = useState(true);


  const sendResetLink = (values) => {
    const strength = checkPasswordStrength(values.newPassword);
    if (strength <= 30) {
      alert(
        "Alphanumeric, 6-20 characters with at least one of the following special characters @#$%^&+=]["
      );
      return;
    }
    setIsLoading(true);
    setError(null);
    if (values.newPassword === values.confirmPassword) {
      const body = {
        email: email,
        password: values.newPassword,
        repeatPassword: values.newPassword,
        token: code,
        returnUrl: `${BASE_URL}`,
      };

      axios
        .post(`/api/user/resetpassword`, {
          ...body,
        })
        .then((res) => {
          navigate("/reset-password-success");
          setIsLoading(false);
        })
        .catch((err) => {
          setError("Error resetting password. Please try again");
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
      setError("Passwords do not match.");
    }
  };

  const handleChangeInput = (val, setFieldValue) => {
    setPassword(val.target.value);
    setFieldValue(val.target.name, val.target.value, false);
    if (confirmPassword === val.target.value) {
      const strength = checkPasswordStrength(val.target.value);

      if (strength <= 30) {
        setPasswordStrength("weak");
        // setUiBar("passwordRed");
      } else if (strength <= 50) {
        setPasswordStrength("strong");
        // setUiBar("passwordAmber");
      } else {
        setPasswordStrength("very strong");
        // setUiBar("passwordGreen");
      }
    }
  };

  const setAndCheckConfirmPassword = (e, setFieldValue) => {
    setPasswordStrength("");
    setUiBar("");
    setFieldValue(e.target.name, e.target.value);
    setconfirmPassword(e.target.value);
    if (password === e.target.value) {
      const strength = checkPasswordStrength(e.target.value);

      if (strength <= 30) {
        setPasswordStrength("weak");
        // setUiBar("passwordRed");
      } else if (strength <= 50) {
        setPasswordStrength("strong");
        // setUiBar("passwordAmber");
      } else {
        setPasswordStrength("very strong");
        // setUiBar("passwordGreen");
      }
    }
  };

  const errorComponent = (message, apiError) => (
    <div className="section" style={{ minHeight: apiError ? "0" : "100vh" }}>
      <div
        className="alert alert-danger"
        style={{
          maxWidth: "25rem",
          margin: "0 auto",
        }}
      >
        {apiError ? "" : <h4>Error</h4>}
        <div style={{ margin: "1rem" }}>{message}</div>
        {apiError ? (
          ""
        ) : (
          <p>
            <Link to={"/login"}>Go back to login</Link>
          </p>
        )}
      </div>
    </div>
  );

  const errorMessage = (message) => (
    <div className="alert alert-danger">{message}</div>
  );

  const resetPaswordComponent = (message) => (
    <div className="conatiner-fluid align-items-center">
      <div className="container mb-3 flex-column align-items-center">
        <div>
          <p className="reset-password">Reset Password</p>
        </div>
        <div className="mb-5">
          <h6 className="reset-password-desc">
            Please fill in the fields to reset your password
          </h6>
        </div>
        <div className="create-account-content  mb-5">
          <Formik
            initialValues={{
              email: "",
              newPassword: "",
              confirmPassword: "",
            }}
            validationSchema={ResetPasswordSchema}
            onSubmit={(val) => sendResetLink(val)}
          >
            {({
              handleChange,
              handleBlur,
              handleSubmit,
              errors,
              touched,
              setFieldValue,
            }) => (
              <Form onSubmit={handleSubmit}>
                {message && errorMessage(message)}
                {/* <div>
                  <input
                    className={`form-input form-control`}
                    type="email"
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Your Email Address"
                    style={{
                      border:
                        errors.email && touched.email ? "1px solid red" : "",
                    }}
                  />
                </div>
                <div className="error-text mb-3">
                  <ErrorMessage name="email" />
                </div> */}
                <div className="password-container">
                  <input
                    className={`form-input form-control`}
                    type={hidePassword ? "password" : "text"}
                    name="newPassword"
                    id="password-field"
                    onChange={(e) => handleChangeInput(e, setFieldValue)}
                    onBlur={handleBlur}
                    // className="bg-light"
                    placeholder="New Password"
                    style={{
                      border:
                        errors.newPassword && touched.newPassword
                          ? "1px solid red"
                          : "",
                    }}
                  />

                  <i
                    className={`toggle-password ${
                      hidePassword
                        ? "fa fa-light fa-eye"
                        : "fa fa-thin fa-eye-slash"
                    }`}
                    // className="fa fa-light fa-eye toggle-password"
                    onClick={() => setHidePassword(!hidePassword)}
                  ></i>
                  {/* <span
                            toggle="#password-field"
                            className="fa fa-fw fa-eye field-icon toggle-password"
                          ></span> */}
                </div>
                <div className="error-text mb-3">
                  <ErrorMessage name="newPassword" />
                </div>
                <div className="password-container">
                  <input
                    type={hideConfirmPassword ? "password" : "text"}
                    name="confirmPassword"
                    onChange={(e) =>
                      setAndCheckConfirmPassword(e, setFieldValue)
                    }
                    onBlur={handleBlur}
                    className={`form-input form-control`}
                    placeholder="Confirm Password"
                    style={{
                      border:
                        errors.confirmPassword && touched.confirmPassword
                          ? "1px solid red"
                          : "",
                    }}
                  />
                  <i
                    // className="fa fa-eye  icon login-password-icon"
                    className={`toggle-password ${
                      hideConfirmPassword
                        ? "fa fa-light fa-eye"
                        : "fa fa-thin fa-eye-slash"
                    }`}
                    onClick={() => setHideConfirmPassword(!hideConfirmPassword)}
                  ></i>
                  {/* <span
                            toggle="#password-field"
                             className="fa fa-fw fa-eye field-icon toggle-password"
                          ></span> */}
                </div>
                <div className="error-text mb-3">
                  <ErrorMessage name="confirmPassword" />
                </div>
                <div className={uiBar}></div>
                <p>{passwordStrength ? <>{passwordStrength}</> : null}</p>

                <div className="submit-input">
                  <button
                    type="submit"
                    disabled={isLoading}
                    // style={{
                    //   margin: "15px 0px 0",
                    //   backgroundColor: "rgb(231, 112, 22)",
                    //   border: "none",
                    //   color: "rgb(255, 255, 255)",
                    //   padding: "13px 10px",
                    //   borderRadius: "6px",
                    //   width: "100%",
                    //   transition: "all 0.9s ease 0s",
                    //   textTransform: "uppercase",
                    //   fontSize: "13px",
                    // }}
                    className={
                      isLoading ? "load-button spin" : "reset-btn-submit w-100"
                    }
                  >
                    Submit
                    <span className="spinner"></span>
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>

        <div className="mb-5">
          <p className="terms-privacy">
            By registering, you accept our <span>Terms of use</span> and{" "}
            <span>Privacy Policy</span>
          </p>
        </div>
      </div>
    </div>
  );

  return code
    ? resetPaswordComponent(error)
    : errorComponent("Something went wrong. Please try again.");
};

export default ResetPassword;
