import * as Yup from "yup";
import { phoneRegExp } from "../../../../../utils/validations/regex";

const validateField = (field, fields) => fields.filter(({ fieldName }) => fieldName === field).length;

export const bankDetailsSchema = (fields) =>
  Yup.object().shape({
    swiftCode: validateField("swiftCode", fields)
      ? Yup.string().required("Field is required")
      : Yup.string().nullable(),
    bankName: validateField("bankName", fields) ? Yup.string().required("Bank name is required") : Yup.string(),
    bankBranch: validateField("bankBranch", fields) ? Yup.string().required("Branch name is required") : Yup.string(),
    bankBranchCode: validateField("bankBranchCode", fields)
      ? Yup.string().required("Branch code is required")
      : Yup.string(),
    accountName: validateField("accountName", fields) ? Yup.string().required("Field is required") : Yup.string(),
    accountNumber: validateField("accountNumber", fields) ? Yup.string().required("Field is required") : Yup.string(),
    confirmAccountNumber: validateField("confirmAccountNumber", fields)
      ? Yup.string()
          .required("Field is required")
          .oneOf([Yup.ref("accountNumber"), null], "Confirm field must match")
      : Yup.string(),
    sortCode: validateField("sortCode", fields) ? Yup.string().required("Sort code is required") : Yup.string(),
    confirmSortCode: validateField("confirmSortCode", fields)
      ? Yup.string()
          .required("Confirm sort code is required")
          .oneOf([Yup.ref("sortCode"), null], "Sort code must match")
      : Yup.string(),

    bankMobileNumber: validateField("bankMobileNumber", fields)
      ? Yup.string().matches(phoneRegExp, "Mobile number is not valid").required("Mobile number is required")
      : Yup.string(),
  });
