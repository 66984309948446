import React from "react";
import { useNavigate } from "react-router-dom";
import { getBeneficiaryServiceType } from "../../hooks/useFetchAllBeneficiaryDetailsById";

const BeneficiaryModal = ({
  beneficiaryState,
  setBeneficiaryDetailsMissing,
  setActiveStepIndex,
  setFormData,
}) => {
  const navigate = useNavigate();

  const editBeneficiary = async () => {
    await getBeneficiaryServiceType(
      beneficiaryState,
      navigate,
      "EDIT_BENEFICIARY"
    );
  };

  let closeModalStyle = null;
  const closeModal = () => {
    setBeneficiaryDetailsMissing(false);
    setActiveStepIndex(0);
    setFormData((prevState) => ({
      ...prevState,
      beneficiary: "",
      beneficiaryID: "",
      beneficiaryObject: {},
    }));
  };

  return (
    <div
      style={{
        position: "fixed",
        padding: "0",
        margin: "0",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
        background: "rgb(0, 0, 0,0.5)",
        zIndex: "9999",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          backgroundColor: "white",
          width: "40rem",
          padding: "2rem",
        }}
      >
        <>
          <div className="otp-container">
            <h4 className="text-center">KYC</h4>
            <div className="process-ol">
              <div className="add-label-input full-input money-half-input">
                <label style={{ textAlign: "center", fontSize: "15px" }}>
                  Please fill in the missing beneficiary mobile number
                </label>
              </div>
            </div>
          </div>
          <div className="modal-footer w-100 d-flex justify-content-center">
            {/* <div className="d-flex justify-content-center align-items-center"> */}
            <div className="padding-x steps-inputs-column1 close">
              <button onClick={editBeneficiary}>Edit beneficiary</button>
            </div>
            <div
              style={{
                backgroundColor: "#c7d6fd",
                borderRadius: "4px",
                color: "#1b223a",
                fontSize: "13px",
                padding: "13px 40px",
                cursor: "pointer",
                ...closeModalStyle,
              }}
              onClick={closeModal}
            >
              <button style={{ background: "none" }}>Back</button>
            </div>
            {/* </div> */}
          </div>
        </>
      </div>
    </div>
  );
};

export default BeneficiaryModal;
