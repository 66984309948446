import { toast } from "react-toastify";
import axios from "../../../api/axios/axios";

const getDeliveryOption = (serviceTypeID) => {
  switch (serviceTypeID) {
    case 1:
      return "BANK";
    case 2:
      return "CASH";
    case 3:
      return "MOBILE";
    default:
      return;
  }
};

export const calculateRates = async ({
  fromCountryID,
  fromCurrencyID,
  toCountryID,
  toCurrencyID,
  serviceTypeID,
  amount,
  paymentService,
  selectedCurrencyID,
  senderCurrencyID,
  receiverCurrencyID,
  setDisableBtn,
  setLoadingRates,
}) => {
  if (selectedCurrencyID === senderCurrencyID) {
    return await getReceiverAmount({
      fromCountryID,
      fromCurrencyID,
      toCountryID,
      toCurrencyID,
      serviceTypeID,
      amount,
      paymentService,
      setDisableBtn,
      setLoadingRates,
    });
  } else {
    return await getSenderAmount({
      fromCountryID,
      fromCurrencyID,
      toCountryID,
      toCurrencyID,
      serviceTypeID,
      amount,
      paymentService,
      setDisableBtn,
      setLoadingRates,
    });
  }
};

export const getReceiverAmount = async ({
  fromCountryID,
  fromCurrencyID,
  toCountryID,
  toCurrencyID,
  serviceTypeID,
  amount,
  paymentService,
  setDisableBtn,
  setLoadingRates,
}) => {
  try {
    const res = await axios.post(`/api/Transaction/GetReceiverAmount`, {
      fromCountryID,
      fromCurrencyID,
      toCountryID,
      toCurrencyID,
      serviceTypeID,
      amount,
      paymentService,
      platform: "Webapp",
      deliveryOption: getDeliveryOption(serviceTypeID),
    });
    setDisableBtn(false);
    setLoadingRates(false);
    return {
      ...res.data,
      receiverAmount: res.data.recieverAmount,
      senderAmount: Number(Math.ceil(res.data.sendAmount)),
    };
  } catch (error) {
    setDisableBtn(true);
    setLoadingRates(false);
    toast.error("Unable to get receiver amount");
  }
};

export const getSenderAmount = async ({
  fromCountryID,
  fromCurrencyID,
  toCountryID,
  toCurrencyID,
  serviceTypeID,
  amount,
  paymentService,
  setDisableBtn,
  setLoadingRates,
}) => {
  try {
    const res = await axios.post(`/api/Transaction/GetSenderAmount`, {
      fromCountryID,
      fromCurrencyID,
      toCountryID,
      toCurrencyID,
      serviceTypeID,
      amount,
      paymentService,
      platform: "Webapp",
      deliveryOption: getDeliveryOption(serviceTypeID),
    });
    setDisableBtn(false);
    setLoadingRates(false);
    return {
      ...res.data,
      // receiverAmount: res.data.recieverAmount,
      senderAmount: Number(Math.ceil(res.data.sendAmount)),
    };
  } catch (error) {
    setDisableBtn(true);
    setLoadingRates(false);
    toast.error("Unable to get sender amount");
  }
};
