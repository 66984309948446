/* eslint-disable jsx-a11y/alt-text */
import axios from "axios";
import { ErrorMessage, Form, Formik } from "formik";
import moment from "moment";
import React, { useContext, useState } from "react";
import DatePicker from "react-datepicker";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { ProfileContext } from "../routes/PrivateRoute";

const UploadAdditionalDocuments = () => {
  const navigate = useNavigate();
  const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png", "application/pdf"];
  const [doc, setDoc] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [docExp, setDocExp] = useState("");
  const [docFile, setDocFile] = useState("");

  const { kycDetails } = useContext(ProfileContext);

  const docUploadSchema = Yup.object().shape({
    docUpload: Yup.mixed()
      .required("Select your Document to Upload")
      .test("fileType", "Please select a valid document", (value) => SUPPORTED_FORMATS.includes(value?.type))
      .test("fileSize", "File size is more than 1MB", (value) => !value || (value && value?.size <= 1048576)),
    docExp: Yup.string().required("Document Expiry Date Required"),
    docType: Yup.string().required("Select your Document Type"),
  });

  const docTypeList = [
    { name: "Special Pass", value: "SPECIAL_PASS" },
    { name: "Work Permit", value: "WORK_PERMIT" },
  ];
  // eslint-disable-next-line no-unused-vars
  const [initialValues, setInitialValues] = useState({
    docUpload: "",
    docExp: "",
    docType: "",
  });
  const CustomStyle = {
    control: () => ({
      border: "1px solid red",
      display: "Flex",
      alignItems: "center",
    }),
  };
  const defaultStyle = {
    option: (provided, state) => ({
      ...provided,
      textAlign: "left",
    }),
  };
  const fileToDataUri = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        resolve(event.target.result);
      };
      reader.readAsDataURL(file);
    });
  const uploadDoc = (e) => {
    setDocFile(e.target.files[0]);
    fileToDataUri(e.target.files[0]).then((blob) => {
      setDoc(blob);
    });
  };

  const handleDate = (date, setFieldValue) => {
    setDocExp(moment(date).format("DD/MM/YYYY"));
    setFieldValue("docExp", moment(date).format("DD/MM/YYYY"));
  };
  const submitUploadAddDoc = (values, { setErrors, resetForm }) => {
    let userId = kycDetails?.userID;
    setIsLoading(true);
    let bodyFormData = new FormData();
    bodyFormData.append("DocumentId", `1`);
    bodyFormData.append("DocumentType", `${values.docType}`);
    bodyFormData.append("DocumentExpiry", `${values.docExp}`);
    bodyFormData.append("userId", `${userId}`);
    bodyFormData.append("files", docFile);
    axios
      .post(`/api/Utility/AddAdditionalDocuments`, bodyFormData)
      .then((res) => {
        setIsLoading(false);
        toast.success("Upload of additional documents Success");
        navigate("/dashboard");
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error("Error");
        if (err.response.data.errors.DocumentId) {
          setErrors({ docId: err.response.data.errors.DocumentId[0] });
        }
      });
  };
  return (
    <>
      {isLoading ? (
        <div className="mainDiv" style={{ left: 0 }}>
          <div className="loading"></div>
        </div>
      ) : null}
      <div>
        <div className="modal-content">
          <div className="modal-header"></div>
          <div className="modal-body otp-container">
            <h4 className="text-center">Upload Additional Documents</h4>
            <Formik
              initialValues={initialValues}
              enableReinitialize={true}
              validationSchema={docUploadSchema}
              onSubmit={submitUploadAddDoc}
            >
              {({ handleChange, handleBlur, handleSubmit, setFieldValue, values, errors, touched }) => (
                <Form className="process-ol" onSubmit={handleSubmit}>
                  <div className="add-label-input">
                    <label>Document Type</label>
                    <Select
                      name="docType"
                      options={docTypeList}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.value}
                      placeholder=""
                      styles={errors.docType && touched.docType ? CustomStyle : defaultStyle}
                      onChange={(e) => setFieldValue("docType", e.value)}
                    />
                    <div className="error-text">
                      <ErrorMessage name="docType" />
                    </div>
                  </div>

                  <div className="add-label-input">
                    <label>Document Expiry</label>
                    <DatePicker
                      minDate={new Date()}
                      name="docExp"
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      onChange={(date) => {
                        handleDate(date, setFieldValue);
                      }}
                      value={docExp}
                      styles={errors.docExp && touched.docExp ? CustomStyle : defaultStyle}
                      placeholderText="Date of Expiry"
                    />
                    <div className="error-text">
                      <ErrorMessage name="docExp" />
                    </div>
                  </div>
                  <div style={{ display: "flex" }} className="add-label-input">
                    <div style={{ width: "100%" }}>
                      <label>Document Upload</label>
                      <input
                        onBlur={handleBlur}
                        name="docUpload"
                        type="file"
                        onChange={(e) => {
                          handleChange(e);
                          setFieldValue("docUpload", e.target.files[0]);
                          uploadDoc(e);
                        }}
                      ></input>
                      <div className="error-text">
                        <ErrorMessage name="docUpload" />
                      </div>
                    </div>
                    {doc && docFile.type !== "application/pdf" && (
                      <img style={{ maxWidth: "10rem", maxHeight: "10rem" }} src={doc}></img>
                    )}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                    className="add-label-input"
                  >
                    <button
                      style={{
                        backgroundColor: "#c7d6fd",
                        borderRadius: "4px",
                        color: "#1b223a",
                        fontSize: "13px",
                        padding: "13px 40px",
                        cursor: "pointer",
                      }}
                      onClick={() => navigate("/dashboard")}
                    >
                      Back
                    </button>
                    <button className="send-money-btn" type="submit">
                      Upload
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default UploadAdditionalDocuments;
