import { ErrorMessage, Form, Formik } from "formik";
import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import resetPassword from "../../src/assets/images/Password-reset.png";
import axios from "../api/axios/axios";
import { BASE_URL } from "../constants/Configuration";

const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
});

const ForgotPassword = (props) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState(null);

  const sendResetPasswordLink = (values) => {
    setError(null);
    setIsLoading(true);
    axios
      .post(`/api/user/forgotpassword`, {
        email: values.email,
        returnUrl: `${BASE_URL}/reset-password`,
      })
      .then((res) => {
        navigate("/forget-password-success");
      })
      .catch((err) => {
        setIsLoading(false);
        setError("Error sending password reset link. Please try again.");
      });
  };

  return (
    <>
      <Container fluid className="p-md-5">
        <Row className="mb-5 align-items-center">
          <Col
            sm={12}
            md={5}
            className="p-lg-5 d-flex flex-column  align-items-start justify-content-start"
          >
            <h1 className="px-lg-5 reset-Password-title">Reset password</h1>

            <h3 className="px-lg-5 text-start resetPassword-desc">
              Just enter the email address you registered with and we’ll send
              you a link to reset your password.
            </h3>
            <Row>
              <Col className="">
                <img src={resetPassword}></img>
              </Col>
            </Row>
          </Col>

          <Col lg={5}>
            <Card className="login-Card">
              <div className="my-lg-5">
                <Formik
                  initialValues={{ email: "" }}
                  validationSchema={ForgotPasswordSchema}
                  onSubmit={(val) => sendResetPasswordLink(val)}
                >
                  {({
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    errors,
                    touched,
                  }) => (
                    <Form className="m-5" onSubmit={handleSubmit}>
                      {error && (
                        <div className="alert alert-danger">{error}</div>
                      )}

                      <div className="mt-3">
                        <input
                          type="email"
                          name="email"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="Enter your email address here"
                          className={`form-input form-control`}
                          style={{
                            border:
                              errors.email && touched.email
                                ? "1px solid red"
                                : "",
                          }}
                        />
                      </div>
                      <div className="error-text mb-3">
                        <ErrorMessage name="email" />
                      </div>

                      <div className="submit-input password-reset-btn">
                        <button
                          type="submit"
                          disabled={isLoading}
                          style={{
                            //   margin: "15px 0px 0",
                            backgroundColor: "Transparent",
                            //   border: "none",
                            color: "#FFFFFF",
                            padding: "13px 10px",
                            //   borderRadius: "6px",
                            //   width: "100%",
                            //   transition: "all 0.9s ease 0s",
                            //   // textTransform: "uppercase",
                            //   fontSize: "13px",
                          }}
                          className={
                            isLoading ? "load-button spin" : "load-button"
                          }
                        >
                          Send password reset link
                          <span className="spinner"></span>
                        </button>
                      </div>
                      {/* <p>
                        <Link to={"/login"}>Back to Login</Link>
                      </p>
                      <h4>New to Upesi</h4>
                      <p>Creating an account takes less than a minute</p>
                      <div className="create-acc">
                        <Link to={"/register"}>Create Account</Link>
                      </div> */}
                      {/* <ConfirmationPage 
                        message="Password reset link is sent to your email address."
                        cta="Back to login"
                        ctalink="/login"
                        
                      /> */}
                    </Form>
                  )}
                </Formik>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ForgotPassword;
