import React from "react";

const DocPreviewModal = ({
  isOpenDocPreview,
  doc,
  closeDocPreview,
  docFile,
}) => {
  return (
    <>
      {isOpenDocPreview ? (
        <div className="modal doc-modal">
          <div className="modal-content">
            <div className="modal-header space-between">
              <h4 className="text-center">Document Preview</h4>
              <span className="close" onClick={closeDocPreview}>
                ×
              </span>
            </div>
            <div className="modal-body doc-container">
              <>
                {docFile.type !== "application/pdf" ? (
                  <img
                    style={{ width: "100%" }}
                    src={doc}
                    alt="document preview"
                  ></img>
                ) : (
                  <embed src={doc} width="100%" height="500" />
                )}
              </>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default DocPreviewModal;
