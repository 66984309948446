/* eslint-disable no-unused-vars */
import React, { useContext, useEffect } from "react";
import { FormContext } from "../SendMoney";
import "./SendMoneyStepper.css";
import { toast } from "react-toastify";

function SendMoneyStepper() {
  const { activeStepIndex, setActiveStepIndex } = useContext(FormContext);

  useEffect(() => {
    if (activeStepIndex !== 2) {
      toast.dismiss();
    }

    const stepperItems = document.querySelectorAll(".stepper-item");

    stepperItems.forEach((step, i) => {
      if (i <= activeStepIndex) {
        step.classList.add("bg-active", "text-active");
      } else {
        step.classList.remove("bg-active", "text-active");
      }
    });
  }, [activeStepIndex]);

  const previousBtn = (key) => {
    if (activeStepIndex > key) {
      setActiveStepIndex(key);
    }
  };

  return (
    <div className="stepper">
      <div className="stepper-item" onClick={() => previousBtn(0)}>
        <div className="stepper-label">Delivery Option</div>
      </div>

      <div className={`progress-bar ${activeStepIndex >= 1 ? "progress-bar-active" : ""}`}></div>
      <div className="stepper-item" onClick={() => previousBtn(1)}>
        <div className="stepper-label">Account Info</div>
      </div>

      <div className={`progress-bar ${activeStepIndex >= 2 ? "progress-bar-active" : ""}`}></div>
      <div className="stepper-item" onClick={() => previousBtn(2)}>
        <div className="stepper-label">Rates </div>
      </div>

      <div className={`progress-bar ${activeStepIndex >= 3 ? "progress-bar-active" : ""}`}></div>
      <div className="stepper-item" onClick={() => previousBtn(3)}>
        <div className="stepper-label">Additional Info</div>
      </div>

      <div className={`progress-bar ${activeStepIndex >= 4 ? "progress-bar-active" : ""}`}></div>
      <div className="stepper-item" onClick={() => previousBtn(3)}>
        <div className="stepper-label">Summary</div>
      </div>
    </div>
  );
}

export default SendMoneyStepper;
