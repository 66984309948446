/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import check from "../assets/images/icons/check.png";
import { downloadReceipt } from "../api/downloadReceipt";

const TransactionConfirm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [refId] = useState(location.state.txn);
  useEffect(() => {
    getUserTransactionsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [isLoading, setIsLoading] = useState(false);
  const [, setTransactionStatus] = useState("");
  const getUserTransactionsList = () => {
    setIsLoading(true);
    axios
      .post(`/api/Transaction/GetUserTransactions`, {
        page: 0,
        size: 0,
        searchByReference: true,
        referenceNumber: "",
        senderFirstName: "",
        senderMiddleName: "",
        senderLastName: "",
        receiverFirstName: "",
        receiverMiddleName: "",
        receiverLastName: "",
        senderGender: "",
        receiverGender: "",
        receiverCountry: 0,
        senderCountry: 0,
        serviceType: 0,
        serviceProvider: 0,
        payerID: 0,
        fromDate: "",
        toDate: "",
        paidOutBy: "",
        paidOutFromDate: "",
        paidOutToDate: "",
        compliance: true,
        preApproved: true,
        senderPaymentReceived: true,
        origin: "",
        senderPayment: "",
        status: "",
      })
      .then((res) => {
        if (res) {
          const transactionStat = res.data.filter((i) => {
            return location.state.txn === i.transaction_ref;
          });
          setTransactionStatus(transactionStat[0].transactionstatus);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  // const downloadReceipt = () => {
  //   setIsLoading(true);
  //   axios
  //     .get(`/api/Transaction/GetPdfReceipt/${location.state.txnId}`)
  //     .then((res) => {
  //       const linkSource = `data:application/pdf;base64,${res.data}`;
  //       const downloadLink = document.createElement("a");
  //       const fileName = "receipt.pdf";

  //       downloadLink.href = linkSource;
  //       downloadLink.download = fileName;
  //       downloadLink.click();
  //       setIsLoading(false);
  //     })
  //     .catch((err) => {
  //       setIsLoading(false);
  //     });
  // };

  const redirectPage = () => {
    navigate("/dashboard");
  };

  return (
    <div>
      {isLoading ? (
        <div className="mainDiv">
          <div className="loading"></div>
        </div>
      ) : null}

      <section>
        <div className="send-total-wrapper">
          <div className="display-flex full-input send-to-country align-items-center margin-bottom">
            <figure>
              <img src={check} alt="Send to Somali" />
            </figure>
            &nbsp;&nbsp;<h3>Transaction Confirmation Successful</h3>
            <br />
          </div>
          <br />
          <div className="full-input send-to-country text-center">
            <p>
              <b>STEP 1 :</b> Money Transfer Request to UPESI
            </p>
          </div>
          <div className="full-input send-to-country text-center">
            <p>
              <b>Status :</b> Transaction Successfully booked. Funds awaited
            </p>
          </div>
          <div className="full-input send-to-country text-center">
            <p>
              <b>Your UPESI Transaction no. : </b>
              {refId}
            </p>
          </div>
          <div className="full-input send-to-country text-center">
            <p>To check transaction status go to dashboard</p>
          </div>

          <div className="continue-process display-flex">
            <div
              onClick={redirectPage}
              style={{
                borderRadius: "4px",
                padding: "13px 40px",
                fontSize: "13px",
                outline: "none",
                border: "none",
              }}
            >
              <a
                style={{
                  backgroundColor: "rgb(227, 227, 227)",
                  color: "rgb(102, 102, 102)",
                  cursor: "pointer",
                  transition: "all 0.9s ease 0s",
                textDecoration: "none",
                  fontFamily: "gothammedium, sans-serif",
                }}
              >
                Dashboard
              </a>
            </div>
            <div className="padding-x steps-inputs-column1">
              <button
                className="btn"
                onClick={() =>
                  downloadReceipt({
                    obj: { id: location.state.txnId },
                    setIsLoading,
                    // setId,
                    // setReceiptPdf,
                  })
                }
              >
                Download Receipt &nbsp;
                <i className="fa fa-download" aria-hidden="true"></i>
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default TransactionConfirm;
