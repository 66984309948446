import React from "react";
import { Navigate } from "react-router-dom";
import { getSessionData } from "../utils/session";

const AuthRoutes = (props) => {
  const isLoggedIn = getSessionData("token");
  return (
    <>
      {isLoggedIn ? (
        <>
          <Navigate to="/dashboard" />
        </>
      ) : (
        <>
          {props.element}
        </>
      )}
    </>
  );
};

export default AuthRoutes;
