import React from "react";

const FloatingLabel = ({ label, value, htmlFor }) => {
  return (
    <label className={`floating-label  ${value ? "focused" : ""}`} htmlFor={htmlFor}>
      {label}
    </label>
  );
};

export default FloatingLabel;
