import React, { useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import DashBoardCarousal from "../components/DashBoardCarousal";
import FeaturedBeneficiaries from "../components/FeaturedBeneficiaries";
import ShortSummary from "../components/ShortSummary";
import TransactionHistory from "../components/TransactionHistory";
import { ProfileContext } from "../routes/PrivateRoute";
import { KYC_STATUS } from "../hooks/useFetchUserProfile";

const Dashboard = () => {
  let navigate = useNavigate();

  const { kycDetails } = useContext(ProfileContext);

  function isAddressEmpty(address) {
    for (var i = 0; i < address.length; i++) {
      let isEmpty = address[i].trim().length === 0;
      if (isEmpty) return true;
    }
    return false;
  }

  useEffect(() => {
    if (kycDetails) {
      let address = kycDetails.address ? kycDetails.address.split(",") : "";
      // if kyc status confirmed and address details are missing
      if ((address.length < 4 || isAddressEmpty(address)) && kycDetails?.kycConfirmation === KYC_STATUS.CONFIRMED) {
        return navigate("/profile");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kycDetails]);

  return (
    <>
      <div className="beneficiary-details-wrapper dashboard-wrapper">
        <div className="text-right margin-10-20 sendMoney-mobile-show">
          <div className="steps-inputs-column1">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Link to="/money-transfer">Send Money</Link>
            </div>
          </div>
        </div>
        <FeaturedBeneficiaries />
        <DashBoardCarousal />
        <TransactionHistory />
        <ShortSummary />
      </div>
    </>
  );
};

export default Dashboard;
