export const sendMoneyFields = ({
  countriesData,
  deliveryOptions,
  beneficiaries,
  fundsList,
  purposeList,
  cashPickupLocations,
  showCashPickupInput,
  uploadDocument,
  currencies,
}) => ({
  beneficiaryAndCountryInfo: [
    {
      name: "receivingCountry",
      id: "receivingCountryID",
      objectVal: "receivingCountryObject",
      options: countriesData,
      placeHolder: "Select Receiving Country",
      controlType: "select",
      noOptionMessage: "Loading countries...",
    },
    {
      name: "deliveryOption",
      id: "deliveryOptionID",
      options: deliveryOptions ? deliveryOptions : [],
      placeHolder: "Select Delivery Option",
      controlType: "select",
      noOptionMessage: "Loading delivery options...",
    },
  ],

  accountDetails: [
    {
      name: "beneficiary",
      id: "beneficiaryID",
      objectVal: "beneficiaryObject",
      options: beneficiaries,
      placeHolder: "Select Receiver",
      controlType: "select",
      noOptionMessage: "Loading beneficiaries...",
    },
  ],
  ratesCalculation: [
    {
      name: "currency",
      id: "currencyID",
      options: currencies,
      placeHolder: "Select currency",
      controlType: "select",
      noOptionMessage: "Loading currencies...",
    },
    {
      name: "amount",
      placeHolder: "Amount in ",
      controlType: "input",
      type: "number",
    },
    {
      name: "rateBtn",
      placeHolder: "Calculate",
      controlType: "button",
      type: "button",
    },

    {
      name: "fees",
      displayName: "Fees",
      controlType: "label",
    },
    {
      name: "exciseDuty",
      displayName: "Excise Duty",
      controlType: "label",
    },
    {
      name: "exchangeRate",
      displayName: "Exchange Rate",
      controlType: "label",
    },
    {
      name: "receiverAmount",
      displayName: "Receiver Amount",
      controlType: "label",
    },
    {
      name: "senderAmount",
      displayName: "Total Amount To send",
      controlType: "label",
    },
    {
      name: "discount",
      displayName: "Discount",
      // controlType: "label",
    },
    {
      name: "promoCode",
      displayName: "PromoCode",
      // controlType: "label",
    },
  ],
  additionalInformation: [
    {
      name: "purposeOfSending",
      options: purposeList,
      placeHolder: "Purpose of Sending",
      controlType: "select",
      noOptionMessage: "Loading...",
      show: true,
    },
    {
      name: "sourceOfFund",
      options: fundsList,
      placeHolder: "Source of fund",
      controlType: "select",
      noOptionMessage: "Loading...",
      show: true,
    },
    {
      name: "cashPickupLocation",
      options: cashPickupLocations,
      placeHolder: "Cash Pickup Location",
      controlType: cashPickupLocations.length > 0 ? "select" : "input",
      noOptionMessage: "Loading...",
      show: showCashPickupInput,
    },
    {
      name: "docUpload",
      placeHolder: "Support Document",
      controlType: "file",
      type: "file",
      show: uploadDocument,
    },
  ],
});

export const mapFields = ({ fields, beneficiaryObj, fieldToTypes }) =>
  Object.keys(fields).forEach((key) => {
    fields[key].forEach((fieldObject) => {
      if (fieldObject.name === "receivingCountry" && beneficiaryObj?.countryID) {
        fieldToTypes[fieldObject.name] = beneficiaryObj?.countryName;
        fieldToTypes[fieldObject.id] = beneficiaryObj?.countryID;
      } else if (fieldObject.name === "deliveryOption" && beneficiaryObj?.serviceName) {
        fieldToTypes[fieldObject.name] = beneficiaryObj?.serviceName;
        fieldToTypes[fieldObject.id] = beneficiaryObj?.serviceId;
      } else {
        fieldToTypes[fieldObject.name] = "";
        fieldToTypes[fieldObject.id] = "";
      }
    });
  });
