import React from "react";
import China from "../../../../assets/images/operatingCountries/CN.png";
import Ethiopia from "../../../../assets/images/operatingCountries/ET.png";
import Ghana from "../../../../assets/images/operatingCountries/GH.png";
import India from "../../../../assets/images/operatingCountries/IN.jpg";
import Japan from "../../../../assets/images/operatingCountries/JP.png";
import Kenya from "../../../../assets/images/operatingCountries/KE.webp";
import Nigeria from "../../../../assets/images/operatingCountries/NG.png";
import Philipines from "../../../../assets/images/operatingCountries/PH.png";
import Pakistan from "../../../../assets/images/operatingCountries/PK.png";
import Rwanda from "../../../../assets/images/operatingCountries/RW.svg";
import SouthAfrica from "../../../../assets/images/operatingCountries/SA.png";
import Turkey from "../../../../assets/images/operatingCountries/TR.png";
import Tanzania from "../../../../assets/images/operatingCountries/TZ.png";
import Uganda from "../../../../assets/images/operatingCountries/UG.png";
import Zimbabwe from "../../../../assets/images/operatingCountries/ZW.png";

import Burundi from "../../../../assets/images/operatingCountries/BR.png";
import Cameroon from "../../../../assets/images/operatingCountries/CM.png";
import Egypt from "../../../../assets/images/operatingCountries/EG.png";
import Morocco from "../../../../assets/images/operatingCountries/MA.png";
import Madagascar from "../../../../assets/images/operatingCountries/MG.png";
import Malawi from "../../../../assets/images/operatingCountries/MW.png";
import Zambia from "../../../../assets/images/operatingCountries/ZA.png";

import { useLocation } from "react-router";
import styles from "./Countries.module.css";

let UG = <img src={Uganda} alt="Uganda flag" />;
let CN = <img src={China} alt="China flag" />;
let IN = <img className="border" src={India} alt="India flag" />;
let PK = <img className="border" src={Pakistan} alt="Pakistan flag" />;
let PH = <img className="border" src={Philipines} alt="Philipines flag" />;
let TZ = <img className="border" src={Tanzania} alt="Tanzania flag" />;
let JP = <img className="border" src={Japan} alt="Japan flag" />;
let GH = <img className="border" src={Ghana} alt="Ghana flag" />;

let RW = <img className="border" src={Rwanda} alt="Rwanda flag" />;
let SA = <img className="border" src={SouthAfrica} alt="SouthAfrica flag" />;
let TR = <img className="border" src={Turkey} alt="Turkey flag" />;
let NG = <img className="border" src={Nigeria} alt="Nigeria flag" />;
let ET = <img className="border" src={Ethiopia} alt="Ethiopia flag" />;
let ZW = <img className="border" src={Zimbabwe} alt="Zimbabwe flag" />;
let KE = <img className="border" src={Kenya} alt="Kenya flag" />;

let MW = <img className="border" src={Malawi} alt=" flag" />;
let CM = <img className="border" src={Cameroon} alt=" flag" />;
let MG = <img className="border" src={Madagascar} alt=" flag" />;
let ZM = <img className="border" src={Zambia} alt=" flag" />;
let EG = <img className="border" src={Egypt} alt=" flag" />;
let MA = <img className="border" src={Morocco} alt=" flag" />;
let BR = <img className="border" src={Burundi} alt=" flag" />;

const Countries = () => {
  const { pathname } = useLocation();
  const ugScreen = pathname.includes("ug");

  const oparatingCountries = [
    {
      name: "China",
      flag: CN,
      link: ugScreen
        ? "https://china.upesimts.com/send-money-to-china"
        : "https://china.upesi.co.ke/send-money-to-china",
    },

    {
      name: "India",
      flag: IN,
      link: ugScreen
        ? "https://india.upesimts.com/send-money-to-india"
        : "https://india.upesi.co.ke/send-money-to-india",
    },

    {
      name: "Pakistan",
      flag: PK,
      link: ugScreen
        ? "https://pakistan.upesimts.com/send-money-to-pakistan"
        : "https://pakistan.upesi.co.ke/send-money-to-pakistan",
    },
    ugScreen
      ? {
          name: "Kenya",
          flag: KE,
          link: "https://kenya.upesimts.com/send-money-to-kenya",
        }
      : {
          name: "Uganda",
          flag: UG,
          link: "https://uganda.upesi.co.ke/send-money-to-uganda",
        },
    { name: "Philippines", flag: PH },
    {
      name: "Tanzania",
      flag: TZ,
      link: ugScreen && "https://tanzania.upesimts.com/send-money-to-tanzania",
    },
    {
      name: "Japan",
      flag: JP,
      link: ugScreen && "https://japan.upesimts.com/send-money-to-japan",
    },
    { name: "Ghana", flag: GH },
    {
      name: "Rwanda",
      flag: RW,
      link: ugScreen && "https://rwanda.upesimts.com/send-money-to-rwanda",
    },
    { name: "S.Africa", flag: SA },
    { name: "Turkey", flag: TR },
    { name: "Nigeria", flag: NG },
    { name: "Ethiopia", flag: ET },
    { name: "Zimbabwe", flag: ZW },
    { name: "Burundi", flag: BR },

    { name: "Malawi", flag: MW },
    { name: "Cameroon", flag: CM },
    { name: "Madagascar", flag: MG },
    { name: "Zambia", flag: ZM },
    { name: "Egypt", flag: EG },
    { name: "Morocco", flag: MA },
  ];

  // TODO: make a sprite image

  return (
    <div className={`${styles.countries} row`}>
      <div className="row">
        <div className="row">
          <div className="col-12">
            <h1 className={styles.countries__title}>Countries Hub</h1>
          </div>

          <div className="col-12">
            <h3 className={styles.countries__sub_title}>
              Send Money from {ugScreen ? "UGANDA" : "KENYA"} to :
            </h3>
          </div>
        </div>
        <div className="row align-items-center justify-content-center">
          <div className={`col-9 ${styles.countries__list}`}>
            {oparatingCountries.map((country) => {
              return (
                <div
                  key={country.name}
                  style={{ width: "70px", height: "70px", margin: "7px" }}
                >
                  {country?.link ? (
                    <a target="_blank" href={country.link} rel="noreferrer">
                      {country.flag}
                    </a>
                  ) : (
                    <>{country.flag}</>
                  )}

                  <div className={` ${styles.country_name}`}>
                    <p>{country.name}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Countries;
