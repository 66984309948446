import { toast } from "react-toastify";
import { addTransactionSupportDoc } from "./addTransactionSupportDocument";
import axios from "./axios/axios";

export const AddCustomerTransaction = async ({
  setIsLoading,
  body,
  complianceAmount,
  paymentService,
  files,
  navigate,
  state,
}) => {
  setIsLoading(true);

  const {
    sourceofFunds,
    purposeofRemittance,
    beneficiaryID,
    serviceTypeID,
    beneficiaryBankDetailID,
    beneficiaryMobileWalletDetailID,
    beneficiaryLocationID,
    fromCountryID,
    fromCurrencyID,
    toCountryID,
    toCurrencyID,
    amount, //sender amount
    receiverPickUpLocation,
    discount,
  } = body;

  const { kycDetails } = state;

  try {
    const res = await axios.post(`/api/Transaction/AddCustomerTransaction`, {
      id: 0,
      documentUploadID: 1, // TODO: Should be dynamic, get from user profile details
      paymentOptions: "WALLET(MPESA)",
      platformType: "Webapp",
      sourceofFunds,
      purposeofRemittance,
      beneficiaryID,
      serviceTypeID,
      beneficiaryBankDetailID,
      beneficiaryMobileWalletDetailID,
      beneficiaryLocationID,
      fromCountryID,
      fromCurrencyID,
      toCountryID,
      toCurrencyID,
      amount,
      receiverPickUpLocation,
      discount,
    });

    if (res.data.id === 0) {
      toast.error(res.data.errorMessage);
      setIsLoading(false);
    } else {
      if (amount >= complianceAmount || paymentService === "C2B") {
        // eslint-disable-next-line no-useless-escape
        let txnNumber = res.data.transactionReferenceNumber.replace(/\-.*/, "");

        const docRes = await addTransactionSupportDoc({
          Id: txnNumber,
          files,
          setIsLoading,
          // userId: primaryInfo?.id, // picked from backend
        });

        if (docRes.message === "Success") {
          navigate("/payment-options", {
            state: {
              txn: res.data.transactionReferenceNumber,
              txnId: res.data.id,
              totalAmount: amount,
              kycDetails,
            },
          });
        }
      } else {
        navigate("/payment-options", {
          state: {
            txn: res.data.transactionReferenceNumber,
            txnId: res.data.id,
            totalAmount: amount,
            kycDetails,
          },
        });
      }
    }
  } catch (error) {
    toast.error("Error saving add customer transaction");
    setIsLoading(false);
  }
};
