export const mobileCodesList = [
  { countryCode: "AD", country: "Andorra", countryPhoneCode: "+376" },
  { countryCode: "AE", country: "UAE", countryPhoneCode: "+971" },
  { countryCode: "AF", country: "Africa", countryPhoneCode: "+12" },
  { countryCode: "AG", country: "Antigua and Barbuda", countryPhoneCode: "+268" },
  { countryCode: "AI", country: "Anguilla", countryPhoneCode: "+264" },
  { countryCode: "AL", country: "Albania", countryPhoneCode: "+355" },
  { countryCode: "AM", country: "Armenia", countryPhoneCode: "+374" },
  { countryCode: "AO", country: "Angola", countryPhoneCode: "+244" },
  { countryCode: "AR", country: "Argentina", countryPhoneCode: "+54" },
  { countryCode: "AS", country: "ASDC", countryPhoneCode: "+10" },
  { countryCode: "AT", country: "Austria", countryPhoneCode: "+43" },
  { countryCode: "AU", country: "Australia", countryPhoneCode: "+61" },
  { countryCode: "AZ", country: "Azerbaijan", countryPhoneCode: "+994" },
  { countryCode: "AW", country: "Aruba", countryPhoneCode: "+297" },
  { countryCode: "BA", country: "BOSNIA AND HERZEGOWINA", countryPhoneCode: "+387" },
  { countryCode: "BB", country: "Barbados", countryPhoneCode: "+246" },
  { countryCode: "BD", country: "Bangladesh", countryPhoneCode: "+880" },
  { countryCode: "BE", country: "Belgium", countryPhoneCode: "+32" },
  { countryCode: "BF", country: "Burkina Faso", countryPhoneCode: "+226" },
  { countryCode: "BG", country: "Bulgaria", countryPhoneCode: "+359" },
  { countryCode: "BH", country: "Bahrain", countryPhoneCode: "+973" },
  { countryCode: "BI", country: "Burundi", countryPhoneCode: "+257" },
  { countryCode: "BJ", country: "Benin", countryPhoneCode: "+229" },
  { countryCode: "BM", country: "Bermuda", countryPhoneCode: "+1" },
  { countryCode: "BN", country: "Brunei Darussalam", countryPhoneCode: "+673" },
  { countryCode: "BO", country: "Bolivia", countryPhoneCode: "+591" },
  { countryCode: "BR", country: "Brazil", countryPhoneCode: "+55" },
  { countryCode: "BS", country: "Bahamas", countryPhoneCode: "+242" },
  { countryCode: "BT", country: "Bhutan", countryPhoneCode: "+975" },
  { countryCode: "BW", country: "Botswana", countryPhoneCode: "+267" },
  { countryCode: "BY", country: "Belarus", countryPhoneCode: "+375" },
  { countryCode: "BZ", country: "Belize", countryPhoneCode: "+501" },
  { countryCode: "CA", country: "Canada", countryPhoneCode: "+1" },
  { countryCode: "CD", country: "CONGO, Democratic Republic of (was Zaire)", countryPhoneCode: "+243" },
  { countryCode: "CF", country: "Central African Republic", countryPhoneCode: "+236" },
  { countryCode: "CG", country: "Democratic Republic of the Congo", countryPhoneCode: "+242" },
  { countryCode: "CH", country: "Switzerland", countryPhoneCode: "+41" },
  { countryCode: "CI", country: "Cote dIvoire", countryPhoneCode: "+225" },
  { countryCode: "CK", country: "Cook Islands", countryPhoneCode: "+682" },
  { countryCode: "CM", country: "Cameroon", countryPhoneCode: "+237" },
  { countryCode: "CN", country: "China", countryPhoneCode: "+86" },
  { countryCode: "CO", country: "Colombia", countryPhoneCode: "+57" },
  { countryCode: "CR", country: "Costa Rica", countryPhoneCode: "+506" },
  { countryCode: "CS", country: "Montenegro", countryPhoneCode: "+381" },
  { countryCode: "CU", country: "Cuba", countryPhoneCode: "+53" },
  { countryCode: "CV", country: "Cape Verde", countryPhoneCode: "+238" },
  { countryCode: "CY", country: "Cyprus", countryPhoneCode: "+357" },
  { countryCode: "CZ", country: "Czech Republic", countryPhoneCode: "+420" },
  { countryCode: "DE", country: "Germany", countryPhoneCode: "+49" },
  { countryCode: "DJ", country: "Djibouti", countryPhoneCode: "+253" },
  { countryCode: "DK", country: "Denmark", countryPhoneCode: "+45" },
  { countryCode: "DM", country: "Dominica", countryPhoneCode: "+767" },
  { countryCode: "DO", country: "Dominican Republic", countryPhoneCode: "+809" },
  { countryCode: "DZ", country: "Algeria", countryPhoneCode: "+213" },
  { countryCode: "EC", country: "Ecuador", countryPhoneCode: "+593" },
  { countryCode: "EE", country: "Estonia", countryPhoneCode: "+372" },
  { countryCode: "EG", country: "Egypt", countryPhoneCode: "+20" },
  { countryCode: "ER", country: "Eritrea", countryPhoneCode: "+291" },
  { countryCode: "ES", country: "Spain", countryPhoneCode: "+34" },
  { countryCode: "ET", country: "Ethiopia", countryPhoneCode: "+251" },
  { countryCode: "EU", country: "Eurozone", countryPhoneCode: "+49" },
  { countryCode: "FI", country: "Finland", countryPhoneCode: "+358" },
  { countryCode: "FJ", country: "Fiji", countryPhoneCode: "+679" },
  { countryCode: "FR", country: "France", countryPhoneCode: "+33" },
  { countryCode: "GA", country: "Gabon", countryPhoneCode: "+241" },
  { countryCode: "GB", country: "United Kingdom", countryPhoneCode: "+44" },
  { countryCode: "GD", country: "Grenada", countryPhoneCode: "+473" },
  { countryCode: "GE", country: "Georgia", countryPhoneCode: "+995" },
  { countryCode: "GG", country: "Guernsey", countryPhoneCode: "+44" },
  { countryCode: "GH", country: "Ghana", countryPhoneCode: "+233" },
  { countryCode: "GI", country: "Gibraltar", countryPhoneCode: "+350" },
  { countryCode: "GM", country: "Gambia", countryPhoneCode: "+220" },
  { countryCode: "GN", country: "Guinea", countryPhoneCode: "+224" },
  { countryCode: "GQ", country: "Equatorial Guinea", countryPhoneCode: "+240" },
  { countryCode: "GR", country: "Greece", countryPhoneCode: "+30" },
  { countryCode: "GT", country: "Guatemala", countryPhoneCode: "+502" },
  { countryCode: "GW", country: "GUINEA-BISSAU", countryPhoneCode: "+245" },
  { countryCode: "GY", country: "Guyana", countryPhoneCode: "+592" },
  { countryCode: "HK", country: "Hong Kong", countryPhoneCode: "+852" },
  { countryCode: "HN", country: "Honduras", countryPhoneCode: "+504" },
  { countryCode: "HR", country: "CROATIA", countryPhoneCode: "+385" },
  { countryCode: "HT", country: "Haiti", countryPhoneCode: "+509" },
  { countryCode: "HU", country: "Hungary", countryPhoneCode: "+36" },
  { countryCode: "ID", country: "Indonesia", countryPhoneCode: "+62" },
  { countryCode: "IE", country: "Ireland", countryPhoneCode: "+353" },
  { countryCode: "IL", country: "Israel", countryPhoneCode: "+972" },
  { countryCode: "IM", country: "Isle of Man", countryPhoneCode: "+44" },
  { countryCode: "IN", country: "India", countryPhoneCode: "+91" },
  { countryCode: "IQ", country: "Iraq", countryPhoneCode: "+964" },
  { countryCode: "IR", country: "Iran", countryPhoneCode: "+98" },
  { countryCode: "IS", country: "Iceland", countryPhoneCode: "+354" },
  { countryCode: "IT", country: "Italy", countryPhoneCode: "+9" },
  { countryCode: "JM", country: "Jamaica", countryPhoneCode: "+876" },
  { countryCode: "JO", country: "Jordan", countryPhoneCode: "+962" },
  { countryCode: "JP", country: "Japan", countryPhoneCode: "+81" },
  { countryCode: "KE", country: "Kenya", countryPhoneCode: "+254" },
  { countryCode: "KG", country: "Kyrgyzstan", countryPhoneCode: "+996" },
  { countryCode: "KH", country: "Cambodia", countryPhoneCode: "+855" },
  { countryCode: "KL", country: "Kfgfg uhu", countryPhoneCode: "+86" },
  { countryCode: "KM", country: "Comoros", countryPhoneCode: "+269" },
  { countryCode: "KN", country: "Saint Kitts and Nevis", countryPhoneCode: "+869" },
  { countryCode: "KP", country: "North Korea", countryPhoneCode: "+850" },
  { countryCode: "KR", country: "South Korea", countryPhoneCode: "+82" },
  { countryCode: "KW", country: "Kuwait", countryPhoneCode: "+965" },
  { countryCode: "KY", country: "Cayman Islands", countryPhoneCode: "+345" },
  { countryCode: "KZ", country: "Kazakhstan", countryPhoneCode: "+7" },
  { countryCode: "LA", country: "Laos", countryPhoneCode: "+856" },
  { countryCode: "LB", country: "Lebanon", countryPhoneCode: "+961" },
  { countryCode: "LC", country: "Saint Lucia", countryPhoneCode: "+758" },
  { countryCode: "LI", country: "Liechtenstein", countryPhoneCode: "+423" },
  { countryCode: "LK", country: "Sri Lanka", countryPhoneCode: "+94" },
  { countryCode: "LR", country: "Liberia", countryPhoneCode: "+231" },
  { countryCode: "LS", country: "Lesotho", countryPhoneCode: "+266" },
  { countryCode: "LT", country: "Lithuania", countryPhoneCode: "+370" },
  { countryCode: "LU", country: "Luxembourg", countryPhoneCode: "+352" },
  { countryCode: "LV", country: "Latvia", countryPhoneCode: "+371" },
  { countryCode: "LY", country: "Libya", countryPhoneCode: "+218" },
  { countryCode: "MA", country: "Morocco", countryPhoneCode: "+212" },
  { countryCode: "MC", country: "Monaco", countryPhoneCode: "+377" },
  { countryCode: "MD", country: "MOLDOVA", countryPhoneCode: "+373" },
  { countryCode: "MG", country: "Madagascar", countryPhoneCode: "+261" },
  { countryCode: "MH", country: "Marshall Islands", countryPhoneCode: "+692" },
  { countryCode: "MK", country: "MACEDONIA", countryPhoneCode: "+389" },
  { countryCode: "ML", country: "Mali", countryPhoneCode: "+223" },
  { countryCode: "MM", country: "Myanmar", countryPhoneCode: "+95" },
  { countryCode: "MN", country: "Mongolia", countryPhoneCode: "+976" },
  { countryCode: "MO", country: "MACAU", countryPhoneCode: "+853" },
  { countryCode: "MR", country: "Mauritania", countryPhoneCode: "+222" },
  { countryCode: "MS", country: "Montserrat", countryPhoneCode: "+664" },
  { countryCode: "MT", country: "Malta", countryPhoneCode: "+356" },
  { countryCode: "MU", country: "Mauritius", countryPhoneCode: "+230" },
  { countryCode: "MV", country: "Maldives", countryPhoneCode: "+960" },
  { countryCode: "MW", country: "Malawi", countryPhoneCode: "+265" },
  { countryCode: "MX", country: "Mexico", countryPhoneCode: "+52" },
  { countryCode: "MY", country: "MALAYSIA", countryPhoneCode: "+63" },
  { countryCode: "MZ", country: "Mozambique", countryPhoneCode: "+258" },
  { countryCode: "NA", country: "Namibia", countryPhoneCode: "+264" },
  { countryCode: "NE", country: "Niger", countryPhoneCode: "+227" },
  { countryCode: "NG", country: "Nigeria", countryPhoneCode: "+234" },
  { countryCode: "NI", country: "Nicaragua", countryPhoneCode: "+505" },
  { countryCode: "NL", country: "Netherlands", countryPhoneCode: "+31" },
  { countryCode: "NO", country: "Norway", countryPhoneCode: "+47" },
  { countryCode: "NP", country: "Nepal", countryPhoneCode: "+977" },
  { countryCode: "NR", country: "Nauru", countryPhoneCode: "+674" },
  { countryCode: "NU", country: "Niue", countryPhoneCode: "+683" },
  { countryCode: "NZ", country: "New Zealand", countryPhoneCode: "+64" },
  { countryCode: "OM", country: "Oman", countryPhoneCode: "+968" },
  { countryCode: "PA", country: "Testing Moon", countryPhoneCode: "+67" },
  { countryCode: "PE", country: "Peru", countryPhoneCode: "+51" },
  { countryCode: "PG", country: "Papua New Guinea", countryPhoneCode: "+675" },
  { countryCode: "PH", country: "Philippines", countryPhoneCode: "+63" },
  { countryCode: "PK", country: "Pakistan", countryPhoneCode: "+92" },
  { countryCode: "PL", country: "Poland", countryPhoneCode: "+48" },
  { countryCode: "PS", country: "State of Palestine", countryPhoneCode: "+970" },
  { countryCode: "PT", country: "Portugal", countryPhoneCode: "+351" },
  { countryCode: "PW", country: "Palau", countryPhoneCode: "+680" },
  { countryCode: "PY", country: "Paraguay", countryPhoneCode: "+595" },
  { countryCode: "QA", country: "Qatar", countryPhoneCode: "+974" },
  { countryCode: "RO", country: "Romania", countryPhoneCode: "+40" },
  { countryCode: "RU", country: "Russian Federation", countryPhoneCode: "+7" },
  { countryCode: "RW", country: "Rwanda", countryPhoneCode: "+250" },
  { countryCode: "SA", country: "Saudi Arabia", countryPhoneCode: "+966" },
  { countryCode: "SB", country: "Solomon Islands", countryPhoneCode: "+677" },
  { countryCode: "SD", country: "Sudan", countryPhoneCode: "+249" },
  { countryCode: "SE", country: "Sweden", countryPhoneCode: "+46" },
  { countryCode: "SG", country: "Singapore", countryPhoneCode: "+65" },
  { countryCode: "SI", country: "Slovenia", countryPhoneCode: "+386" },
  { countryCode: "SK", country: "Slovakia", countryPhoneCode: "+421" },
  { countryCode: "SL", country: "Sierra Leone", countryPhoneCode: "+232" },
  { countryCode: "SM", country: "'San' Marino", countryPhoneCode: "+378" },
  { countryCode: "SN", country: "Senegal", countryPhoneCode: "+221" },
  { countryCode: "SO", country: "Somalia", countryPhoneCode: "+252" },
  { countryCode: "SR", country: "Suriname", countryPhoneCode: "+597" },
  { countryCode: "SS", country: "Republic of South Sudan", countryPhoneCode: "+211" },
  { countryCode: "ST", country: "SAO TOME AND PRINCIPE", countryPhoneCode: "+239" },
  { countryCode: "SV", country: "El Salvador", countryPhoneCode: "+503" },
  { countryCode: "SY", country: "Syria", countryPhoneCode: "+963" },
  { countryCode: "SZ", country: "Swaziland", countryPhoneCode: "+268" },
  { countryCode: "TC", country: "Turks and Caicos Islands", countryPhoneCode: "+649" },
  { countryCode: "TD", country: "Chad", countryPhoneCode: "+235" },
  { countryCode: "TG", country: "Togo", countryPhoneCode: "+228" },
  { countryCode: "TH", country: "Thailand", countryPhoneCode: "+66" },
  { countryCode: "TJ", country: "Tajikistan", countryPhoneCode: "+992" },
  { countryCode: "TL", country: "Timor-Leste", countryPhoneCode: "+670" },
  { countryCode: "TM", country: "Turkmenistan", countryPhoneCode: "+993" },
  { countryCode: "TN", country: "Tunisia", countryPhoneCode: "+216" },
  { countryCode: "TO", country: "Tonga", countryPhoneCode: "+676" },
  { countryCode: "TR", country: "Turkey", countryPhoneCode: "+90" },
  { countryCode: "TT", country: "Trinidad and Tobago", countryPhoneCode: "+868" },
  { countryCode: "TW", country: "Taiwan", countryPhoneCode: "+886" },
  { countryCode: "TZ", country: "Tanzania", countryPhoneCode: "+255" },
  { countryCode: "UA", country: "Ukraine", countryPhoneCode: "+380" },
  { countryCode: "UG", country: "Uganda", countryPhoneCode: "+256" },
  { countryCode: "US", country: "United States", countryPhoneCode: "+1" },
  { countryCode: "UY", country: "Uruguay", countryPhoneCode: "+598" },
  { countryCode: "UZ", country: "Uzbekistan", countryPhoneCode: "+998" },
  { countryCode: "VA", country: "Holy See", countryPhoneCode: "+379" },
  { countryCode: "VC", country: "SAINT VINCENT AND THE GRENADINES", countryPhoneCode: "+784" },
  { countryCode: "VE", country: "Venezuela", countryPhoneCode: "+58" },
  { countryCode: "VG", country: "Virgin Islands (UK)", countryPhoneCode: "+284" },
  { countryCode: "VN", country: "Vietnam", countryPhoneCode: "+84" },
  { countryCode: "VU", country: "Vanuatu", countryPhoneCode: "+678" },
  { countryCode: "WS", country: "Samoa", countryPhoneCode: "+685" },
  { countryCode: "XK", country: "Kosovo", countryPhoneCode: "+383" },
  { countryCode: "YE", country: "Yemen", countryPhoneCode: "+967" },
  { countryCode: "ZA", country: "South Africa", countryPhoneCode: "+27" },
  { countryCode: "ZM", country: "Zambia", countryPhoneCode: "+260" },
  { countryCode: "ZW", country: "Zimbabwe", countryPhoneCode: "+263" },
  { countryCode: "GS", country: "South Georgia and the South Sandwich Islands", countryPhoneCode: "+500" },
  { countryCode: "AQ", country: "Antarctica", countryPhoneCode: "+672" },
  { countryCode: "JE", country: "Jersey", countryPhoneCode: "+44" },
];

export const getCountryName = (countryCode) => {
  const country = mobileCodesList.find((c) => c.countryCode === countryCode);
  return country ? country.country : "";
}