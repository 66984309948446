import React from "react";
import { ErrorMessage } from "formik";

const CustomInput = (props) => {
  return (
    <>
      <input
        disabled={props.disabled}
        type={props.type}
        name={props.name}
        placeholder={props.placeholder}
        onChange={props.onChange}
        onBlur={props.onBlur}
        style={props.style}
        value={props.value}
      />
      <div className="error-text">
        <ErrorMessage name={props.name} />
      </div>
    </>
  );
};

export default CustomInput;
