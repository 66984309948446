import { ErrorMessage, Form, Formik } from "formik";
import React from "react";
import Select from "react-select";

import FloatingLabel from "../../../../tools/FloatingLabel";
import { CustomStyle, defaultStyle } from "../../../../utils/styles";

function DeliveryMethod({ propsData }) {
  const {
    countriesData,
    singleCountryData,
    beneficiaryRelationships,
    formData,
    handleCountry,
    handleDeliveryOption,
    handleBeneficiaryType,
    handleRelationship,
    onSubmit,
    countrySupportsRelation,
    deliveryMethodSchema,
    location,benTypes
  } = propsData;

  return (
    <Formik
      initialValues={{ ...formData.deliveryMethod }}
      enableReinitialize
      validationSchema={deliveryMethodSchema}
      onSubmit={(val) => onSubmit(val)}
    >
      {({
        handleBlur,
        handleSubmit,
        setFieldValue,
        errors,
        touched,
        values,
      }) => (
        <Form
          id="registration-form"
          className="w-100 row justify-content-center "
          onSubmit={handleSubmit}
        >
          <div className="h3 text-center mb-2 step-title">
            Receiver Country & Delivery Option
          </div>

          <div className="form-group col-12 col-sm-8 col-lg-6 col-xl-4 focused">
            <FloatingLabel label="Country Name" value={values.countryName} />

            <Select
              name="countryName"
              value={values.countryName && { name: values.countryName }}
              onChange={(val) => handleCountry(val, setFieldValue)}
              options={countriesData}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option}
              placeholder="Select country"
              onBlur={handleBlur}
              styles={
                errors.countryName && touched.countryName
                  ? CustomStyle
                  : defaultStyle
              }
              noOptionsMessage={() => "Loading countries..."}
              id="custom-select"
              isDisabled={location?.state?.action === "EDIT_BENEFICIARY"}
              textFieldProps={{
                label: "Country ....",
                InputLabelProps: {
                  shrink: true,
                },
              }}
            />
          </div>
          <div className="error-text col-12 text-center my-2">
            <ErrorMessage name="countryName" />
          </div>

          <div className="form-group col-12 col-sm-8 col-lg-6 col-xl-4">
            <FloatingLabel
              label="Delivery Option"
              value={values.deliveryOption}
            />
            <Select
              name="deliveryOption"
              value={
                values.deliveryOption && { serviceName: values.deliveryOption }
              }
              onChange={(val) => handleDeliveryOption(val, setFieldValue)}
              options={singleCountryData?.deliveryOptions}
              getOptionLabel={(option) => option.serviceName}
              getOptionValue={(option) => option}
              placeholder="Delivery Option"
              styles={
                errors.deliveryOption && touched.deliveryOption
                  ? CustomStyle
                  : defaultStyle
              }
              noOptionsMessage={() => "Delivery option is loading..."}
              id="custom-select"
              isDisabled={location?.state?.action === "EDIT_BENEFICIARY" && values.deliveryOption}
            />
          </div>
          <div className="error-text col-12 text-center my-2">
            <ErrorMessage name="deliveryOption" />
          </div>

          <div className="form-group col-12 col-sm-8 col-lg-6 col-xl-4">
            <FloatingLabel
              label="Beneficiary Type"
              value={values.beneficiaryType}
            />

            <Select
              name="beneficiaryType"
              value={values.beneficiaryType && { type: values.beneficiaryType }}
              onChange={(val) => handleBeneficiaryType(val, setFieldValue)}
              // options={singleCountryData?.beneficiaryTypes}
              options={benTypes}
              getOptionLabel={(option) => option.type}
              getOptionValue={(option) => option}
              placeholder="Beneficiary Type"
              styles={
                errors.beneficiaryType && touched.beneficiaryType
                  ? CustomStyle
                  : defaultStyle
              }
              noOptionsMessage={() => "Beneficiary Type is loading..."}
              id="custom-select"
              isDisabled={location?.state?.action === "EDIT_BENEFICIARY"}
            />
          </div>
          <div className="error-text col-12 text-center my-2">
            <ErrorMessage name="beneficiaryType" />
          </div>
          {countrySupportsRelation && (
            <>
              <div className="form-group col-12 col-sm-8 col-lg-6 col-xl-4">
                <FloatingLabel
                  label="Relationship"
                  value={values.relationShip}
                />

                <Select
                  name="relationShip"
                  value={
                    values.relationShip && {
                      relation: values.relationShip,
                      id: values.relationshipID,
                    }
                  }
                  onChange={(val) => handleRelationship(val, setFieldValue)}
                  options={beneficiaryRelationships}
                  getOptionLabel={(option) => option.relation}
                  getOptionValue={(option) => option.id}
                  placeholder="Select relation"
                  onBlur={handleBlur}
                  styles={
                    errors.relationShip && touched.relationShip
                      ? CustomStyle
                      : defaultStyle
                  }
                  noOptionsMessage={() => "Loading relations..."}
                />
              </div>
              <div className="error-text col-12 text-center my-2">
                <ErrorMessage name="relationShip" />
              </div>
            </>
          )}
          <div className="col-12 col-sm-8 col-lg-6 col-xl-4">
            <button className="btn btn-orange w-100" type="submit">
              Next
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default DeliveryMethod;
