import { createContext, useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { AddCustomerTransaction } from "../../api/addCustomerTransaction";
import useFetchAllCountriesData from "../../hooks/useFetchAllCountriesData";
import useFetchAvailableCurrencies from "../../hooks/useFetchAvailableCurrencies";
import useFetchBeneficiaryBankDetails from "../../hooks/useFetchBeneficiaryBankDetails";
import useFetchBeneficiaryLocationDetails from "../../hooks/useFetchBeneficiaryLocationDetails";
import useFetchBeneficiaryMobileWalletDetails from "../../hooks/useFetchBeneficiaryMobileWalletDetails";
import useFetchCashPickupLocations from "../../hooks/useFetchCashPickupLocations";
import useFetchComplianceAmountLimit from "../../hooks/useFetchComplianceAmountLimit";
import useFetchReasonOfRemittance from "../../hooks/useFetchReasonOfRemittance";
import useFetchSourceofFunds from "../../hooks/useFetchSourceofFunds";
import { ProfileContext } from "../../routes/PrivateRoute";
import { fileToDataUri } from "../../utils/fileToDataUri";
import BeneficiaryModal from "./BeneficiaryModal";
import KycCheck from "./KycCheck";
import "./SendMoney.css";
import SendMoneyStepper from "./SendMoneyStepper/SendMoneyStepper";
import SendMoneySteps from "./SendMoneySteps/SendMoneySteps";
import { mapFields, sendMoneyFields } from "./sendMoneyUtils/fields";
import {
  calculateExchangeRates,
  countryData,
  getBeneficiariesByDeliveryOption,
  mapSingleCountryDetails,
} from "./sendMoneyUtils/helpers";

let newBeneficiaryAction = { name: "Add New Beneficiary", id: -1 };
export const FormContext = createContext();

export const MoneyTransferJourney = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const beneficiaryObj = location.state;
  const fileRef = useRef();

  const [uploadAddDoc, setUploadAddDoc] = useState(false);
  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const [deliveryOptions, setDeliveryOptions] = useState([]);
  const [beneficiaries, setBeneficiaries] = useState([]);
  const [beneficiariesByServiceType, setBeneficiariesByServiceType] = useState([newBeneficiaryAction]);
  const [receiverCurrency, setReceiverCurrency] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [doc, setDoc] = useState("");
  const [disableBtn, setDisableBtn] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingRates, setLoadingRates] = useState(false);
  const [beneficiaryDetailsMissing, setBeneficiaryDetailsMissing] = useState(false);

  const { countriesData } = useFetchAllCountriesData(
    "IncludeDeliveryMethods=true&IncludeBeneficiaryDetails=true&IncludeCurrencies=true"
  );

  const { kycDetails, cta, kycPath, kycMessage, kycCheck, kycLoader } = useContext(ProfileContext);

  const { complianceAmountLimit } = useFetchComplianceAmountLimit(kycDetails?.countryID);

  // get payment service
  const [senderService] = useState(kycDetails?.isOrganization ? "B" : "C");
  const [receiverService, setReceiverService] = useState("");

  const fieldToTypes = {};
  const [formData, setFormData] = useState(fieldToTypes);

  //sender currency
  const { senderCurrency } = useFetchAvailableCurrencies({
    senderCountryId: Number(kycDetails?.countryID),
    setCurrencies,
    setFormData,
  });

  // FETCH PUPROSE OF REMITTANCE & SOURCE OF FUNDS
  let { fundsList } = useFetchSourceofFunds({
    senderService,
    toCountryID: formData.receivingCountryID,
    receiverService,
    serviceTypeID: formData.deliveryOptionID,
    customerType: formData?.beneficiaryObject?.beneficiaryType,
    selectedBeneficiary: formData?.beneficiaryObject,
  });

  const { purposeList } = useFetchReasonOfRemittance({
    senderService,
    toCountryID: formData.receivingCountryID,
    receiverService,
    serviceTypeID: formData.deliveryOptionID,
    customerType: formData?.beneficiaryObject?.beneficiaryType,
    selectedBeneficiary: formData?.beneficiaryObject,
  });

  const { cashPickupLocations } = useFetchCashPickupLocations(
    beneficiaryObj ? beneficiaryObj.countryID : formData.receivingCountryID,
    formData.deliveryOptionID
  );

  //TODO: get bank details from bankaccount object
  const { beneficiaryBankDetails } = useFetchBeneficiaryBankDetails({
    beneficiaryID: formData.beneficiaryID,
    deliveryOptionID: formData.deliveryOptionID,
  });

  //TODO: get mobile details from bankaccount object
  const { beneficiaryMobileDetails } = useFetchBeneficiaryMobileWalletDetails({
    beneficiaryID: formData.beneficiaryID,
    deliveryOptionID: formData.deliveryOptionID,
  });

  //TODO: get location details from bankaccount object
  const { beneficiaryLocationDetails } = useFetchBeneficiaryLocationDetails({
    beneficiaryID: formData.beneficiaryID,
    deliveryOptionID: formData.deliveryOptionID,
  });

  // MAP OUT ALL SEND MONEY FIELDS
  let fields = sendMoneyFields({
    countriesData,
    deliveryOptions,
    beneficiaries: beneficiariesByServiceType,
    fundsList,
    purposeList,
    cashPickupLocations,
    showCashPickupInput: formData.deliveryOptionID === 2,
    uploadDocument:
      `${senderService}2${receiverService}` === "C2B" ||
      Number(formData.senderAmount) >= complianceAmountLimit?.complianceAmount,
    currencies,
  });

  mapFields({
    fields,
    beneficiaryObj,
    fieldToTypes,
  });

  // filter country deliveryOpt, beneficiaries, currencies -> countryID,
  useEffect(() => {
    if (location.state && countriesData.length > 0) {
      const {
        countryID,
        countryName,
        id,
        serviceId,
        serviceName,
        amount,
        purposeOfSending,
        sourceOfFund,
        cashPickupLocation,
      } = location.state;
      setActiveStepIndex(1);
      // const { countryID, countryName, id, serviceId, serviceName } = location.state;

      let filteredCountryData = countryData(countriesData, countryID);
      let filterBenficiaryByID = filteredCountryData?.beneficiaries.filter((beneficiary) => beneficiary.id === id)[0];

      setActiveStepIndex(amount ? 2 : 1);
      setFormData((prevState) => ({
        ...prevState,
        receivingCountryObject: filteredCountryData,
        receivingCountryID: countryID,
        receivingCountry: countryName,
        deliveryOption: serviceName,
        deliveryOptionID: serviceId,

        beneficiaryID: id,
        beneficiaryObject: filterBenficiaryByID,
        beneficiary: `${filterBenficiaryByID?.firstName} ${filterBenficiaryByID?.lastName}`,
        amount: amount ?? 0,

        purposeOfSending,
        sourceOfFund,
        cashPickupLocation,
      }));
      // }
    }

    return () => {};
  }, [location.state, countriesData]);

  const clearStateOnReceiverCountryChange = () => {
    setBeneficiaryDetailsMissing(false);
    setFormData((prevState) => ({
      ...prevState,
      deliveryOption: "",
      deliveryOptionID: "",
      beneficiary: "",
      beneficiaryID: "",
      beneficiaryObject: {},
      currency: senderCurrency[0]?.name,
      amount: "",
      fees: "",
      discount: "",
      promoCode: "",
      exciseDuty: "",
      exchangeRate: "",
      receiverAmount: "",
      senderAmount: "",
      purposeOfSending: "",
      sourceOfFund: "",
      cashPickupLocation: "",
      receiverCurrency: "",
      senderCurrency: senderCurrency[0]?.name,
    }));
    if (fileRef.current) fileRef.current.value = "";
  };

  //on receiver id change=> get beneficiaries, delivery options, receiver currencies
  useEffect(() => {
    //if receiver country changes , get that country details
    if (formData?.receivingCountryObject) {
      if (!location.state) {
        clearStateOnReceiverCountryChange();
      }
      if (location?.state?.countryID !== formData?.receivingCountryID) {
        window.history.replaceState({}, document.title);
        clearStateOnReceiverCountryChange();
      }

      // update receiver currency code
      setFormData((prevState) => ({
        ...prevState,
        receiverCurrency: formData?.receivingCountryObject?.currencies[0].currencyCode,
      }));

      mapSingleCountryDetails({
        singleCountryDetails: formData?.receivingCountryObject,
        setDeliveryOptions,
        setBeneficiaries,
        setReceiverCurrency,
        setCurrencies,
        senderCurrency,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.receivingCountryObject, formData.receivingCountryID]);

  //filter beneficiaries depending on delivery option selected
  useEffect(() => {
    //clear dependent data to delivery option on change
    if (!location.state || location?.state?.serviceId !== formData.deliveryOptionID) {
      setFormData((prevState) => ({
        ...prevState,
        amount: "",
        beneficiary: "",
        beneficiaryID: "",
        beneficiaryObject: {},
        currency: senderCurrency[0]?.name,
        currencyID: senderCurrency[0]?.id,
      }));
    }
    if (formData.deliveryOptionID === 1) {
      setBeneficiariesByServiceType([
        newBeneficiaryAction,
        ...getBeneficiariesByDeliveryOption(beneficiaries, "bankAccounts"),
      ]);
    } else if (formData.deliveryOptionID === 2) {
      setBeneficiariesByServiceType([
        newBeneficiaryAction,
        ...getBeneficiariesByDeliveryOption(beneficiaries, "cashAccounts"),
      ]);
    } else {
      setBeneficiariesByServiceType([
        newBeneficiaryAction,
        ...getBeneficiariesByDeliveryOption(beneficiaries, "mobileAccounts"),
      ]);
    }

    return () => {};
  }, [formData.deliveryOptionID, beneficiaries, senderCurrency, location.state]);

  //get selected beneficiary service type
  useEffect(() => {
    //prompt user to enter beneficiary phone number if not available or invalid
    if (formData?.beneficiaryObject?.phoneNumber?.length < 9) {
      setBeneficiaryDetailsMissing(true);
    }
    if (formData?.beneficiaryObject?.beneficiaryType)
      setReceiverService(formData?.beneficiaryObject?.beneficiaryType === "Individual" ? "C" : "B");

    return () => {};
  }, [formData?.beneficiaryObject?.beneficiaryType, formData?.beneficiaryObject?.phoneNumber]);

  // calculate rates
  const calculateRates = () => {
    setLoadingRates(true);
    if (
      formData.amount >= complianceAmountLimit?.minAmount ||
      (formData.amount <= complianceAmountLimit?.maxAmount && formData.currency === senderCurrency)
    ) {
      calculateExchangeRates({
        kycDetails,
        senderCurrency,
        formData,
        receiverCurrency,
        senderService,
        receiverService,
        setFormData,
        setDisableBtn,
        setLoadingRates,
      });
    } else {
      setLoadingRates(false);
    }
  };

  const handleValueChange = async ({ val, fieldName, fieldId, controlType, objectVal }) => {
    if (controlType === "select") {
      //add new beneficiary
      if (fieldId === "beneficiaryID" && val.id === -1) {
        const { callingCode, code, id, name } = formData?.receivingCountryObject?.countryDetails;
        const beneData = {
          callingCode,
          countryName: name,
          countryID: id,
          countryCode: code,
          serviceId: formData?.deliveryOptionID,
          serviceName: formData?.deliveryOption,
          currencyId: receiverCurrency[0]?.id,
          receiverCurrencies: receiverCurrency,
          action: "SEND_MONEY_NEW_BENEFICIARY",
        };
        navigate("/add-beneficiary", {
          state: beneData,
        });
        return;
      }

      if (fieldName === "currency") {
        setDisableBtn(true);
      }

      setFormData({
        ...formData,
        [fieldName]: val.name,
        [fieldId]: val.id,
        [objectVal]: val,
      });
    } else if (fieldName === "docUpload") {
      setFormData((prevState) => ({
        ...prevState,
        docUpload: val[0],
      }));
      fileToDataUri(val[0]).then((blob) => {
        setDoc(blob);
      });
    } else {
      let value = val;
      if (fieldName === "amount") {
        setDisableBtn(true);
        setFormData((prevState) => ({ ...prevState, amount: value }));
      } else {
        setFormData({ ...formData, [fieldName]: value });
      }
    }
  };

  const onSubmit = () => {
    setActiveStepIndex(activeStepIndex + 1);
  };

  const createTransaction = async () =>
    await AddCustomerTransaction({
      setIsLoading,
      body: {
        sourceofFunds: formData?.sourceOfFund,
        purposeofRemittance: formData?.purposeOfSending,
        beneficiaryID: formData?.beneficiaryID,
        serviceTypeID: formData?.deliveryOptionID,
        beneficiaryBankDetailID: beneficiaryBankDetails?.id,
        beneficiaryMobileWalletDetailID: beneficiaryMobileDetails?.id,
        beneficiaryLocationID: beneficiaryLocationDetails?.id,
        fromCountryID: kycDetails?.countryID,
        fromCurrencyID: senderCurrency[0]?.id,
        toCountryID: formData?.receivingCountryID,
        toCurrencyID: formData?.currencyID !== senderCurrency[0]?.id ? formData?.currencyID : receiverCurrency[0]?.id,
        amount: formData?.senderAmount,
        receiverPickUpLocation: formData?.cashPickupLocation,
        discount: {
          promotionCode: formData?.promoCode,
          tariffDiscountAmount: formData?.discount,
        },
      },
      complianceAmount: complianceAmountLimit?.complianceAmount,
      paymentService: `${senderService}2${receiverService}`,
      files: formData?.docUpload,
      navigate,
      state: { kycDetails },
    });

  const cancelTransaction = () => {
    setActiveStepIndex(0);
    setFormData((prevState) => ({
      ...prevState,
      receivingCountry: "",
      receivingCountryID: "",
      receivingCountryObject: {},
    }));
    clearStateOnReceiverCountryChange();
  };

  let values = {
    location,
    activeStepIndex,
    setActiveStepIndex,
    fields,
    formData,
    onSubmit,
    handleValueChange,
    doc,
    complianceAmountLimit,
    paymentService: `${senderService}2${receiverService}`,
    cashPickupLocations,
    disableBtn,
    beneficiaryBankDetails,
    beneficiaryMobileDetails,
    beneficiaryLocationDetails,
    createTransaction,
    isLoading,
    senderCurrency,
    cancelTransaction,
    fileRef,
    calculateRates,
    loadingRates,
  };

  const kycCheckProps = {
    uploadAddDoc,
    kycMessage,
    setUploadAddDoc,
    kycPath,
    cta,
  };

  return (
    <>
      {kycLoader ? (
        <div className="mainDiv">
          <div className="loading"></div>
        </div>
      ) : null}

      {kycCheck ? (
        //popup
        <KycCheck {...kycCheckProps} />
      ) : null}
      {beneficiaryDetailsMissing && formData?.receivingCountryObject?.countryDetails?.id === 114 && (
        <BeneficiaryModal
          beneficiaryState={formData?.beneficiaryObject}
          setBeneficiaryDetailsMissing={setBeneficiaryDetailsMissing}
          setActiveStepIndex={setActiveStepIndex}
          setFormData={setFormData}
        />
      )}
      <FormContext.Provider value={values}>
        <div className="sendMoney d-flex flex-column align-items-center justify-content-start">
          <SendMoneyStepper />
          <SendMoneySteps />
        </div>
      </FormContext.Provider>
    </>
  );
};

export default MoneyTransferJourney;
