import { useEffect, useState } from "react";
import axios from "../api/axios/axios";

export default function useFetchComplianceAmountLimit(countryId) {
  const [complianceAmountLimit, setComplianceAmountLimit] = useState([]);
  const [, setError] = useState(null);
  const [, setLoading] = useState(false);

  useEffect(() => {
    if (countryId) {
      (async function () {
        try {
          setLoading(true);
          const response = await axios.get(
            `/api/Transaction/GetComplianceAmountLimit/${countryId}`
          );
          setComplianceAmountLimit(response.data);
        } catch (err) {
          setError(err);
          setComplianceAmountLimit([]);
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [countryId]);

  return { complianceAmountLimit };
}
