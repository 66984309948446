import * as Yup from "yup";

export const locationDetailsSchema = ({ countryName, activeServiceProvider }) =>
  Yup.object().shape({
    state: Yup.string().required("State is required"),
    city: Yup.string().required("City is required"),
    zipCode:
      countryName === "CHINA" ||
      countryName === "JAPAN" ||
      countryName === "PAKISTAN" ||
      activeServiceProvider === "MASTERCARD" ||
      countryName === "CANADA"
        ? Yup.string().required(`${countryName === "CANADA" ? "Postal" : "Zip"} Code is required`)
        : Yup.string().nullable(),
    addressLine:
      countryName === "CHINA" ||
      countryName === "JAPAN" ||
      countryName === "PAKISTAN" ||
      activeServiceProvider === "MASTERCARD" ||
      countryName === "CANADA"
        ? Yup.string().required("Address line is required")
        : Yup.string().nullable(),
    street:
      countryName === "CHINA" ||
      countryName === "JAPAN" ||
      countryName === "PAKISTAN" ||
      activeServiceProvider === "MASTERCARD"
        ? Yup.string().required("Address street is required")
        : Yup.string().nullable(),
    address:
      countryName !== "CHINA" &&
      countryName !== "JAPAN" &&
      countryName !== "PAKISTAN" &&
      activeServiceProvider !== "MASTERCARD" &&
      countryName !== "CANADA"
        ? Yup.string().required("Address is required")
        : Yup.string(),
  });
