import { useContext } from "react";
import * as Yup from "yup";
import { FormContext } from "../SendMoney";
import UpesiFormik from "../UpesiFormik";

export default function RatesCalculation() {
  const {
    setActiveStepIndex,
    formData,
    fields,
    onSubmit,
    handleValueChange,
    complianceAmountLimit,
    disableBtn,
    senderCurrency,
    calculateRates,
    loadingRates,
  } = useContext(FormContext);

  const initialStepData = {
    ...formData,
    
    isReceiverAmount: true,
    isSenderAmount: true,
  };

  const stepValidationSchema = () =>
    Yup.object().shape({
      senderCurrency: Yup.string()?.when("isReceiverAccount", {
        is: true,
        then: Yup.string().required("Select Receiver's Account"),
        otherwise: Yup.string(),
      }),
      receiverCurrency: Yup.string()?.when("isBeneficiary", {
        is: true,
        then: Yup.string().required("Select Beneficiary"),
        otherwise: Yup.string(),
      }),
     
      senderAmount: Yup.number()
        .test(
          "amount",
          `Rate calculation failed. Verify input values and retry. Contact support if the issue persists.`,
          (val) => {
            if (!val || Number(val) === 0 || !formData?.receiverAmount) {
              return 0;
            } else {
              return true;
            }
          }
        )
        .test(
          "amount",
          `Sender amount must be at least ${senderCurrency[0]?.name} ${complianceAmountLimit?.minAmount}`,
          (val) => {
            if (
              val < complianceAmountLimit?.minAmount ||
              formData.currency !== senderCurrency[0]?.name
            ) {
              return Number(val) >= complianceAmountLimit?.minAmount;
            } else {
              return true;
            }
          }
        )
        .test(
          "amount",
          `Sender amount must be less than ${complianceAmountLimit?.maxAmount}`,
          (val) => {
            if (formData.currency !== senderCurrency[0]?.name) {
              return Number(val) <= complianceAmountLimit?.maxAmount;
            } else {
              return true;
            }
          }
        ),
   
      amount: Yup.number()
        .test(
          "amount",
          `Amount Must be greater than ${senderCurrency[0]?.name} ${complianceAmountLimit?.minAmount}`,
          (val) => {
            if (formData.currency === senderCurrency[0]?.name) {
              return Number(val) >= complianceAmountLimit?.minAmount;
            } else {
              return true;
            }
          }
        )
        .test(
          "amount",
          `Amount Must be less than ${
            senderCurrency[0]?.name
          } ${complianceAmountLimit?.maxAmount?.toLocaleString("en-US")}`,
          (val) => {
            if (formData.currency === senderCurrency[0]?.name) {
              return Number(val) <= complianceAmountLimit?.maxAmount;
            } else {
              return true;
            }
          }
        ), 
    });

  const propsData = {
    setActiveStepIndex,
    formData,
    stepFields: fields["ratesCalculation"],
    initialStepData,
    onSubmit,
    handleValueChange,
    stepValidationSchema,
    formId: "account-calculation-info",
    disableBtn,
    calculateRates,
    loadingRates,
  };

  return <UpesiFormik props={propsData} />;
}
