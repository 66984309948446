export const setSessionData = (key, value) => localStorage.setItem(key, value);
export const getSessionData = (key) => localStorage.getItem(key);

export const removeSessionData = (key) => localStorage.removeItem(key);

export const saveUserProfile = (data) => {
  setSessionData("user", JSON.stringify(data));
};
export const getUserProfile = () => {
  const user = getSessionData("user");
  return JSON.parse(user);
};
