import React, { useState } from "react";
import "./Pagination.css";
import { renderPageNumbers } from "./renderPageNumbers";

const PaginationComponent = ({
  handlePrevClick,
  handleNextClick,
  totalPages,
  currentPage,
  handlePageChange,
}) => {
  return (
    <div className="pagination-container">
      <div className="pagination">
        <button className="page-button" onClick={handlePrevClick}>
          Prev
        </button>
        {renderPageNumbers({ totalPages, currentPage, handlePageChange })}
        <button className="page-button" onClick={handleNextClick}>
          Next
        </button>
      </div>
    </div>
  );
};

export default PaginationComponent;
