import React from "react";
import { Button, Row } from "react-bootstrap";
import styles from "./BToB.module.css";
import { useNavigate } from "react-router-dom";

const BToB = () => {
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate("/login");
  };

  return (
    <Row className="mb-3 gy-2" >
      <h1 className={`${styles.B_to_B__title}`}>Business-to-Business</h1>
      <h6 className={`${styles.B_to_B__text}`}>
        We are on a mission to transform the payment ecosystem in Africa, <br />
        and beyond one business at a time
      </h6>
      <Button className={`${styles.btob_btn}`} onClick={handleNavigate} type="submit">
        Get Started
      </Button>
    </Row>
  );
};

export default BToB;
