import { ErrorMessage, Form, Formik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import * as Yup from "yup";

import axios from "../api/axios/axios";
import AirtelWalletIcon from "../assets/images/logos/Airtel_Uganda-Logo.png";
import MTNWalletIcon from "../assets/images/logos/MTN_Group-Logo.png";
import walletIcon from "../assets/images/logos/wallet-icon1.png";
import BackModal from "../components/BackModal";
import { ProfileContext } from "../routes/PrivateRoute";

const paymentOptions = [
  { id: 1, name: "M-PESA STK" },
  { id: 2, name: "M-PESA PAYBILL" },
  { id: 3, name: "PESALINK-NCBA" },
];

const ugandaPaymentOptions = [
  { id: 1, name: "MTN" },
  { id: 2, name: "AIRTEL-MONEY" },
];

// TODO: refactor this file
const PaymentOptions = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [payment, setPayment] = useState("");
  const [computedPaymentOptions, setComputedPaymentOptions] = useState([]);
  const [countryPhoneCode, setCountryPhoneCode] = useState("");

  const [initialValues, setInitialValues] = useState({
    mobileNo: "",
    terms: false,
    docUpload: "",
    paymentOption: "",
  });

  const transactionReviewData = location.state;

  const { kycDetails } = useContext(ProfileContext);

  const { txn } = transactionReviewData;

  useEffect(() => {
    if (parseInt(kycDetails?.countryID) === 212) {
      setComputedPaymentOptions(paymentOptions);
      setCountryPhoneCode("+254");
      setPayment("M-PESA STK");
      setInitialValues({ ...initialValues, paymentOption: "M-PESA STK" });
    } else if (parseInt(kycDetails?.countryID) === 78) {
      setComputedPaymentOptions(ugandaPaymentOptions);
      setCountryPhoneCode("+256");
      setPayment("MTN");
      setInitialValues({ ...initialValues, paymentOption: "MTN" });
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kycDetails]);

  const transactionReviewSchema = Yup.object().shape({
    mobileNo: Yup.string().required("Mobile number is required"),
    terms: Yup.boolean().oneOf([true], "Accept Terms & Conditions is required"),
  });

  const handlePayment = (val) => {
    setPayment(val.name);
    setInitialValues({ ...initialValues, paymentOption: val.name });
  };

  const paymentOption = (values) => {
    axios
      .post(`/api/Transaction/MakeOnlinePayment`, {
        transactionID: transactionReviewData.txnId || transactionReviewData.id,
        msisdn: countryPhoneCode + values.mobileNo,
      })
      .then((response) => {
        toast.success("Online payment added successfully");
        navigate("/transaction-confirmation", {
          state: {
            ...transactionReviewData,
            txn: transactionReviewData.txn,
            profile: kycDetails,
          },
        });
        setIsLoading(false);
      })
      .catch((err) => {
        toast.error("Error make online payment");
        setIsLoading(false);
      });
  };

  const submitTransactionReview = async (values) => {
    setIsLoading(true);

    paymentOption(values);
  };

  const payBillSubmit = async () => {
    navigate("/transaction-confirmation", {
      state: {
        ...transactionReviewData,
        txn: txn,
        profile: kycDetails,
      },
    });
  };

  const redirectPage = () => {
    navigate("/money-transfer");
  };

  const openModal = () => {
    setModal(true);
  };

  const closeModal = () => {
    setModal(false);
  };

  return (
    <>
      {modal ? <BackModal show={modal} close={closeModal} delete={redirectPage} /> : null}

      {isLoading ? (
        <div className="mainDiv">
          <div className="loading"></div>
        </div>
      ) : (
        <div className="beneficiary-details-wrapper">
          <Formik
            initialValues={initialValues}
            validationSchema={transactionReviewSchema}
            onSubmit={(val) => submitTransactionReview(val)}
            enableReinitialize={true}
          >
            {({ handleChange, handleBlur, handleSubmit, setFieldValue, values, errors, touched }) => (
              <Form onSubmit={handleSubmit}>
                <div>
                  <div className="process-ol">
                    <div className="total-pay flex-direction-column align-items-center">
                      <div className="half-input money-half-input">
                        <label>Payment Options</label>
                        <div style={{ display: "block", clear: "both" }}>
                          <Select
                            name="paymentOption"
                            value={{ name: values.paymentOption }}
                            onChange={(val) => handlePayment(val)}
                            options={computedPaymentOptions}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.name}
                            placeholder="Select payment options"
                          />
                        </div>
                        <br />
                      </div>
                    </div>
                  </div>
                  {/* Payment options depending on sender operating country  */}
                  {/* KENYA  */}
                  {parseInt(kycDetails?.countryID) === 212 && (
                    <>
                      {payment === "M-PESA STK" && (
                        <>
                          <div className="process-ol">
                            <div className="total-pay flex-direction-column align-items-center">
                              <div className="half-input payment-option-image">
                                <img src={walletIcon} alt="M-PESA" />
                              </div>
                              <div className="half-input money-half-input">
                                <label>Enter you M-PESA Phone number i.e(700000000)</label>
                                <div style={{ display: "block", clear: "both" }}>
                                  <div className="email-input align-items-center">
                                    <input
                                      type="text"
                                      disabled
                                      defaultValue={"+254"}
                                      style={{
                                        backgroundColor: "rgba(255, 255, 255, 0.1)",
                                        width: "3rem",
                                        padding: "11px 0",
                                        textAlign: "center",
                                        marginRight: "0.4rem",
                                      }}
                                    />

                                    <input
                                      type="number"
                                      name="mobileNo"
                                      placeholder=""
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      style={{
                                        border: errors.mobileNo && touched.mobileNo ? "1px solid red" : "",
                                      }}
                                    />
                                  </div>
                                </div>
                                <br />
                                <div className="error-text">
                                  <ErrorMessage name="mobileNo" />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <input
                              type="checkbox"
                              name="terms"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              style={{
                                border: errors.terms && touched.terms ? "1px solid red" : "",
                              }}
                            />
                            &nbsp;
                            <p style={{ display: "contents" }}>
                              I accept and agree to the
                              <Link to="/terms">Terms &amp; Conditions</Link>
                            </p>
                          </div>
                          <br />
                          <div className="error-text" style={{ textAlign: "center" }}>
                            <ErrorMessage name="terms" />
                          </div>
                          <div className="continue-process display-flex">
                            <div
                              onClick={openModal}
                              style={{
                                borderRadius: "4px",
                                padding: "13px 40px",
                                fontSize: "13px",
                                outline: "none",
                                border: "none",
                              }}
                            >
                              <button
                                className="btn btn-light"
                                style={{
                                  backgroundColor: "white",
                                  color: "rgb(231, 112, 22)",
                                  cursor: "pointer",
                                  transition: "all 0.9s ease 0s",
                                  textDecoration: "none",
                                  fontFamily: "gothammedium, sans-serif",
                                }}
                              >
                                Back
                              </button>
                            </div>
                            <div className="padding-x steps-inputs-column1">
                              <button type="submit">Confirm Transaction</button>
                            </div>
                          </div>
                        </>
                      )}

                      {payment === "M-PESA PAYBILL" && (
                        <>
                          <div className="add-label-input recipient-bank-details full-input instruction-text">
                            <div className="total-pay flex-direction-column align-items-center">
                              <ol>
                                <li className="payment-list">Go to M-PESA on your phone</li>
                                <li className="payment-list">Select Pay Bill option</li>
                                <li className="payment-list">
                                  Enter Business no.{" "}
                                  <span
                                    style={{
                                      fontStyle: "italic",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    593402
                                  </span>
                                </li>
                                <li className="payment-list">
                                  Enter Account no.{" "}
                                  <span
                                    style={{
                                      fontStyle: "italic",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {transactionReviewData.txn.split("-")[0]}
                                  </span>
                                </li>
                                <li className="payment-list">
                                  Enter the Amount. KES{" "}
                                  {transactionReviewData.totalAmount || transactionReviewData.senderAmount}
                                </li>
                                <li className="payment-list">Enter your M-PESA PIN and Send</li>
                                <li style={{ color: "red" }} className="payment-list">
                                  {transactionReviewData.txnId && (
                                    <>
                                      <b>NOTE:</b> Kindly Pay your transaction within 2 hours
                                    </>
                                  )}
                                </li>
                              </ol>
                            </div>
                          </div>

                          <div className="continue-process display-flex">
                            <div
                              onClick={openModal}
                              style={{
                                borderRadius: "4px",
                                padding: "13px 40px",
                                fontSize: "13px",
                                outline: "none",
                                border: "none",
                              }}
                            >
                              <button
                                className="btn btn-light"
                                style={{
                                  display: "inline-block",
                                  padding: "1rem",
                                  backgroundColor: "white",
                                  color: "rgb(231, 112, 22)",
                                  cursor: "pointer",
                                  transition: "all 0.9s ease 0s",
                                  textDecoration: "none",
                                  fontFamily: "gothammedium, sans-serif",
                                }}
                              >
                                Back
                              </button>
                            </div>
                            <div className="padding-x steps-inputs-column1">
                              <button type="submit" onClick={payBillSubmit}>
                                Confirm Transaction
                              </button>
                            </div>
                          </div>
                        </>
                      )}

                      {payment === "PESALINK-NCBA" && (
                        <>
                          <div className="add-label-input recipient-bank-details full-input instruction-text">
                            <div className="total-pay flex-direction-column align-items-center">
                              <ol>
                                <li className="payment-list">Go to Bank App or USSD</li>
                                <li className="payment-list">Select Pesalink</li>
                                <li className="payment-list">Select NCBA as the receiving Bank</li>
                                <li className="payment-list">
                                  Account Name:
                                  <span
                                    style={{
                                      fontStyle: "italic",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    UPESI MONEY TRANSFER LTD
                                  </span>
                                </li>
                                <li className="payment-list">
                                  Account Number:
                                  <span
                                    style={{
                                      fontStyle: "italic",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    7755610077
                                  </span>
                                </li>
                                <li className="payment-list">
                                  Narration/ Purpose/ Reason:
                                  <span
                                    style={{
                                      fontStyle: "italic",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {transactionReviewData.txn.split("-")[0]}
                                  </span>
                                </li>
                                <li className="payment-list">
                                  Amount. KES {transactionReviewData.totalAmount || transactionReviewData.senderAmount}
                                </li>
                              </ol>
                            </div>
                          </div>
                          <div className="continue-process display-flex">
                            <div
                              onClick={openModal}
                              style={{
                                borderRadius: "4px",
                                padding: "13px 40px",
                                fontSize: "13px",
                                outline: "none",
                                border: "none",
                              }}
                            >
                              <button
                                className="btn btn-light"
                                style={{
                                  display: "inline-block",
                                  padding: "1rem",
                                  backgroundColor: "white",
                                  color: "rgb(231, 112, 22)",
                                  cursor: "pointer",
                                  transition: "all 0.9s ease 0s",
                                  textDecoration: "none",
                                  fontFamily: "gothammedium, sans-serif",
                                }}
                              >
                                Back
                              </button>
                            </div>
                            <div className="padding-x steps-inputs-column1">
                              <button type="submit" onClick={payBillSubmit}>
                                Confirm Transaction
                              </button>
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  )}
                  {/* UGANDA */}
                  {parseInt(kycDetails?.countryID) === 78 && (payment === "MTN" || payment === "AIRTEL-MONEY") && (
                    <>
                      <div className="process-ol">
                        <div className="total-pay flex-direction-column align-items-center">
                          <div className="half-input payment-option-image">
                            {payment === "MTN" ? (
                              <img src={MTNWalletIcon} alt="M-PESA" />
                            ) : payment === "AIRTEL-MONEY" ? (
                              <img src={AirtelWalletIcon} alt="M-PESA" />
                            ) : null}
                          </div>
                          <div className="half-input money-half-input">
                            <label>Enter your Phone number i.e(700000000)</label>
                            <div style={{ display: "block", clear: "both" }}>
                              <div className="email-input align-items-center">
                                <input
                                  type="text"
                                  disabled
                                  defaultValue={"+256"}
                                  style={{
                                    backgroundColor: "rgba(255, 255, 255, 0.1)",
                                    width: "3rem",
                                    padding: "11px 0",
                                    textAlign: "center",
                                    marginRight: "0.4rem",
                                  }}
                                />

                                <input
                                  type="number"
                                  name="mobileNo"
                                  placeholder=""
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  style={{
                                    border: errors.mobileNo && touched.mobileNo ? "1px solid red" : "",
                                  }}
                                />
                              </div>
                            </div>
                            <br />
                            <div className="error-text">
                              <ErrorMessage name="mobileNo" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <input
                          type="checkbox"
                          name="terms"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          style={{
                            border: errors.terms && touched.terms ? "1px solid red" : "",
                          }}
                        />
                        &nbsp;
                        <p style={{ display: "contents" }}>
                          I accept and agree to the
                          <Link to="/terms">Terms &amp; Conditions</Link>
                        </p>
                      </div>
                      <br />
                      <div className="error-text" style={{ textAlign: "center" }}>
                        <ErrorMessage name="terms" />
                      </div>
                      <div className="continue-process display-flex">
                        <div
                          onClick={openModal}
                          style={{
                            borderRadius: "4px",
                            padding: "13px 40px",
                            fontSize: "13px",
                            outline: "none",
                            border: "none",
                          }}
                        >
                          <button
                            className="btn btn-light"
                            style={{
                              backgroundColor: "white",
                              color: "rgb(231, 112, 22)",
                              cursor: "pointer",
                              transition: "all 0.9s ease 0s",
                              textDecoration: "none",
                              fontFamily: "gothammedium, sans-serif",
                            }}
                          >
                            Back
                          </button>
                        </div>
                        <div className="padding-x steps-inputs-column1">
                          <button type="submit">Confirm Transaction</button>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </>
  );
};

export default PaymentOptions;
