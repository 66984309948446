import { ErrorMessage, Form, Formik } from "formik";
import moment from "moment";
import React, { useContext, useState } from "react";
import DatePicker from "react-datepicker";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { FormContext } from "../Registration";

const ValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(3, "Too Short!")
    .max(50, "Too Long!")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed")
    .required("Required"),
  lastName: Yup.string()
    .min(3, "Too Short!")
    .max(50, "Too Long!")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed")
    .required("Required"),
  dob: Yup.date()
    .required("Required")
    .test("dob", "Must be 18 years or older", (value) => {
      const age = moment().diff(moment(value), "years");
      return age >= 18;
    }),
  terms: Yup.boolean().oneOf([true], "Accept Terms & Conditions is required"),
});

function PersonalInfo() {
  const { activeStepIndex, setActiveStepIndex, formData, setFormData } =
    useContext(FormContext);
  const [startDate, setStartDate] = useState(new Date());

  const handleDate = (date, setFieldValue) => {
    setStartDate(date);
    setFieldValue("dob", date);
  };

  const onSubmit = (values) => {
    const data = { ...formData, personalInfo: { ...values } };
    setFormData(data);
    setActiveStepIndex(activeStepIndex + 1);
  };

  const dob = new Date();
  const year = dob.getFullYear();
  const month = dob.getMonth();
  const day = dob.getDate();

  return (
    <Formik
      initialValues={{
        firstName: formData.personalInfo.firstName,
        lastName: formData.personalInfo.lastName,
        dob: formData.personalInfo.dob,
        terms: formData.terms,
      }}
      validationSchema={ValidationSchema}
      onSubmit={(val) => onSubmit(val)}
    >
      {({
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        errors,
        touched,
        values,
      }) => (
        <Form
          id="registration-form"
          className="row justify-content-center"
          onSubmit={handleSubmit}
        >
          <div className="h3 text-center my-4 step-title">
            Create your UPESI account
          </div>
          <p className="py-2">
            Already have an account?
            <Link to="/login">&nbsp;&nbsp;Log&nbsp;In</Link>
          </p>
          <div className="form-group col-12 col-sm-8 col-lg-6 col-xl-4">
            <input
              value={values.firstName}
              className={`form-control form-input ${
                errors.lastName && touched.lastName ? "form-error" : ""
              }`}
              type="text"
              name="firstName"
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="First Name"
            />
          </div>
          <div className="error-text col-12 text-center my-2">
            <ErrorMessage name="firstName" />
          </div>
          <div className="form-group col-12 col-sm-8 col-lg-6 col-xl-4">
            <input
              value={values.lastName}
              className={`form-control form-input ${
                errors.lastName && touched.lastName ? "form-error" : ""
              }`}
              type="text"
              name="lastName"
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="Last Name"
            />
          </div>
          <div className="error-text col-12 text-center my-2">
            <ErrorMessage name="lastName" />
          </div>
          <div className="form-group col-12 col-sm-8 col-lg-6 col-xl-4">
            <DatePicker
              value={values.dob && moment(values.dob).format("DD-MM-YYYY")}
              className={`form-control form-input ${
                errors.dob && touched.dob ? "form-error" : ""
              }`}
              name="dob"
              showYearDropdown
              showMonthDropdown
              dropdownMode="select"
              selected={startDate}
              onChange={(date) => handleDate(date, setFieldValue, values)}
              onBlur={handleBlur}
              placeholderText="Date of Birth"
              dateFormat="dd-MM-yyyy"
              maxDate={new Date(year - 18, month, day)}
            />
          </div>
          <div className="error-text col-12 text-center my-2">
            <ErrorMessage name="dob" />
          </div>

          {/*Terms*/}

          <p style={{ padding: "20px 0px 0px" }}>
            <input
              type="checkbox"
              name="terms"
              onChange={handleChange}
              onBlur={handleBlur}
              style={{
                border: errors.terms && touched.terms ? "1px solid red" : "",
              }}
            />
            &nbsp; I agree to the Upesi's &nbsp;
            <Link
              to="/terms"
              target="_blank"
              rel="noreferrer"
              id="termsnConditionBtn"
            >
              &nbsp;Terms&nbsp;Conditions&nbsp;
            </Link>
            &nbsp;and&nbsp;
            <Link
              to="/policy"
              target="_blank"
              rel="noreferrer"
              id="privacyPolicyBtn"
            >
              &nbsp;Privacy&nbsp;Policy&nbsp;
            </Link>
          </p>
          <div className="error-text col-12 text-center my-2">
            <ErrorMessage name="terms" />
          </div>

          {/*Terms*/}

          <div className="col-12 col-sm-8 col-lg-6 col-xl-4">
            <button className="btn btn-orange w-100" type="submit">
              Next
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default PersonalInfo;
