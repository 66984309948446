import { ErrorMessage, Form, Formik } from "formik";
import React, { useContext, useState } from "react";
import * as Yup from "yup";

import { FormContext } from "../Registration";
import OTPVerification from "./OTP";

const ValidationSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
});

function Email() {
  const { activeStepIndex, setActiveStepIndex, formData, setFormData } =
    useContext(FormContext);
  const [showOTPView, setShowOTPView] = useState(false);
  const [config, setConfig] = useState({ type: "", receiver: "" });

  const handleChangeInput = (val, setFieldValue) => {
    setFieldValue(val.target.name, val.target.value, false);
  };

  const nextStep = () => {
    setActiveStepIndex(activeStepIndex + 1);
  };

  const onSubmit = (values) => {
    const data = { ...formData, ...values };
    setFormData(data);
    setConfig({ type: "email", receiver: values.email });
    setShowOTPView(true);
  };

  return (
    <>
      {showOTPView ? (
        <OTPVerification
          success={nextStep}
          config={config}
          setShowOTPView={setShowOTPView}
        />
      ) : (
        <Formik
          initialValues={{ email: formData.email }}
          validationSchema={ValidationSchema}
          onSubmit={(val) => onSubmit(val)}
        >
          {({
            handleBlur,
            handleSubmit,
            setFieldValue,
            errors,
            touched,
            values,
          }) => (
            <Form
              id="registration-form"
              className="row justify-content-center "
              onSubmit={handleSubmit}
            >
              <div className="h3 text-center my-4 step-title">
                First, enter your email address
              </div>

              <div className="form-group col-12 col-sm-8 col-lg-6">
                <input
                  value={values.email}
                  className={`form-control form-input ${
                    errors.email && touched.email ? "form-error" : ""
                  }`}
                  type="email"
                  name="email"
                  onChange={(val) => handleChangeInput(val, setFieldValue)}
                  onBlur={handleBlur}
                  placeholder="Your Email Address"
                />
              </div>
              <div className="error-text  col-12 text-center my-2">
                <ErrorMessage name="email" />

              </div>
              <div className="col-12 col-sm-8 col-lg-6">
                <button className="btn btn-orange w-100" type="submit">
                  Send verification code
                </button>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
}

export default Email;
