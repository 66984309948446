import axios from "./axios/axios";
import { toast } from "react-toastify";

export const redirectPaymentOption = ({ obj, setIsLoading, setId, primaryInfo, navigate }) => {
  setIsLoading(true);
  
  if (setId) {
    setId(obj.id);
  }


  axios
    .get(`/api/Transaction/GetUserTransactionById/${obj.id}`)
    .then((res) => {
      navigate("/payment-options", {
        state: {
          ...res.data,
          txnId: res.data.id,
          txn: res.data.referenceNumber,
          profile: primaryInfo,
          primaryInfo,
        },
      });
      setIsLoading(false);
    })
    .catch((err) => {
      toast.error("Error to get user transaction by id");
      setIsLoading(false);
    });
};
