import React, { useContext } from "react";
import { FormContext } from "../SendMoney";
import BeneficiaryandCountryInfo from "../SendMoneyForms/BeneficiaryandCountryInfo";
import AccountsDetails from "../SendMoneyForms/AccountDetails";
import RatesCalculation from "../SendMoneyForms/RatesCalculation";
import AdditionalInformation from "../SendMoneyForms/AdditionalInformation";
import SendMoneyTransactionReview from "../SendMoneyForms/SendMoneyTransactionReview";

export default function SendMoneySteps() {
  const { activeStepIndex } = useContext(FormContext);

  let stepContent;

  switch (activeStepIndex) {
    case 0:
      stepContent = <BeneficiaryandCountryInfo />;
      break;
    case 1:
      stepContent = <AccountsDetails />;
      break;
    case 2:
      stepContent = <RatesCalculation />;
      break;
    case 3:
      stepContent = <AdditionalInformation />;
      break;
    case 4:
      stepContent = <SendMoneyTransactionReview />;
      break;

    default:
      break;
  }

  return stepContent;
}
