import React from "react";
import * as Icon from "react-bootstrap-icons";
import worldMap from "../../../../assets/svgs/World.svg";
import useWindowDimensions from "../../../../hooks/useWindowDimensions";
import CTA from "../../Components/CTA/CTA";
import "./AboutUs.css";

const AboutUs = () => {
  const { width } = useWindowDimensions();

  return (
    <div
      className="container-fluid"
      style={{ display: "flex", flexDirection: "column" }}
    >
      <div className="row px-lg-5 aboutUs-intro">
        <h1>About Us</h1>
        <h4>We provide fast, secure & reliable money transfers</h4>
      </div>

      <div className={`container ${width > 820 ? "w-75" : ""}`}>
        <div className="row align-items-center gy-5">
          <div className="col-12 col-md-6 col-lg-6 col-xl-6">
            <img
              src={require("../../../../assets/images/upesi-about-us.png")}
              alt=""
            />
          </div>
          <div className="col-12 col-md-6 col-lg-6 text-start col-xl-6">
            <div className="text-left primary-color p-3">
              <h1>Our Story</h1>
            </div>

            <div className="ps-3 aboutus_desc">
              <p className="mb-2">
                {" "}
                Upesi Money Transfer was founded in 2015 to enable our customers
                to safely and quickly send money to family, friends, or for
                business around the world.
              </p>
              <p className="mb-2">
                {" "}
                As a homegrown remittance solution provider, Upesi Money
                Transfer has grown to serve thousands of customers, enabling
                swift money transfers from Kenya - across Africa and the world.
              </p>
              <p className="mb-2">
                Our Founding was based on the need for a faster, more secure,
                and African-based remittance solution. The digital
                transformation in Africa has enabled the penetration of mobile
                and internet services, making it more feasible for an online
                money transfer solution.
              </p>
              <p>
                At Upesi Money Transfer, our strength is our partners and
                professionals from across the financial and remittance ecosystem
                who work around the clock to ensure our customers get the best.
              </p>
              <p>
                We are licensed and regulated by the CBK (Central Bank of Kenya)
              </p>
            </div>
          </div>
        </div>
        <div className="row align-items-start gy-5">
          <div className="col-12 col-md-6 col-lg-6 col-xl-6">
            <div className="text-start primary-color p-3">
              <h1>Our Partners</h1>
            </div>
            <div className="px-3 text-start aboutus_desc">
              <p>
                Through our partnerships, we are making it easier, safer, and
                more convenient to send money to family & friends and for
                business. We are taking international money transfers online and
                this will make digital payments faster, more flexible and less
                costly. Our partnerships have extended our reach to Asia,
                Europe, Middle East and the United States.
              </p>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-6 col-xl-6">
            <img
              src={require("../../../../assets/images/upesi-about-us-partners.png")}
              alt=""
            />
          </div>
        </div>
        <div className="row align-items-top text-start mt-5  dark-blue">
          {/* Our Mission */}
          <div className="col-12 col-md-4 col-lg-4 col-xl-4">
            <div className="p-3">
              <h1>Our Mission</h1>
            </div>
            <div className="p-3 border-end border-2 border-warning">
              To become Africa’s most innovative, customer-centric, remittance
              solution, with the aim of achieving financial inclusion.
            </div>
          </div>

          {/* Our  Vision*/}
          <div className="col-12 col-md-4 col-lg-4 col-xl-4">
            <div className="p-3">
              <h1>Vision</h1>
            </div>
            <div className="p-2 border-end border-2 border-warning">
              To create a connected world where moving money across borders is
              as easy as taking a breath
            </div>
          </div>

          {/* Core Values*/}
          <div className="col-12 col-md-4 col-lg-4 col-xl-4 pb-5">
            <div className="p-3">
              <h1>Core Values</h1>
            </div>
            <div className="m-2">
              <div>
                <Icon.Check2Square
                  color="orange"
                  size="20px"
                ></Icon.Check2Square>{" "}
                Act Like an Owner
              </div>
              <div>
                <Icon.Check2Square
                  color="orange"
                  size="20px"
                ></Icon.Check2Square>{" "}
                Speed is Our Name
              </div>
              <div>
                <Icon.Check2Square
                  color="orange"
                  size="20px"
                ></Icon.Check2Square>{" "}
                If you, Do it Be The best at It
              </div>
              <div>
                <Icon.Check2Square
                  color="orange"
                  size="20px"
                ></Icon.Check2Square>{" "}
                Invest and Simplify
              </div>
              <div>
                <Icon.Check2Square
                  color="orange"
                  size="20px"
                ></Icon.Check2Square>{" "}
                Customer Obsession
              </div>
            </div>
          </div>
        </div>

        <div className="my-5">
          <CTA />
        </div>

        <div className="row mt-5 justify-content-center">
          <div className="col-12 primary-color">
            <h1>OUR TRUSTED NETWORK</h1>
          </div>
          <div className="col-12 p-0 m-0 w-75 justify-content-center align-items-center">
            <img src={worldMap} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
