import { useEffect, useState } from "react";
import debounce from "lodash.debounce";
import axios from "../api/axios/axios";

export default function useFetchSupportedBanksBranches(ifscCode, setIsLoading) {
  const [bankList, setBankList] = useState([]);

  const fetchBanks = async () => {
    setIsLoading(true);
    try {
      let res = await axios.get(
        `/api/Utility/GetSupportedBanksBranches/${ifscCode}`
      );
      setIsLoading(false);
      setBankList(res.data);
    } catch (error) {
      setIsLoading(false);
      setBankList([]);
    }
  };

  const delayedQuery = debounce(fetchBanks, 1000);
  useEffect(() => {
    if (ifscCode) return delayedQuery();
    return delayedQuery.cancel;
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ifscCode]);

  return { bankList, setBankList };
}
