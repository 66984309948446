import { useEffect, useState } from "react";
import axios from "../api/axios/axios";

export default function useFetchCashPickupLocations(
  countryId,
  selectedDeliveryOptionServiceID
) {
  const [cashPickupLocations, setCashPickupLocations] = useState([]);

  useEffect(() => {
    if (countryId && Number(selectedDeliveryOptionServiceID) === 2) {
      axios
        .get(`/api/Utility/GetCashPickupLocations/${countryId}`)

        .then((res) => {
          setCashPickupLocations(res.data.items);
        })
        .catch((err) => {
          setCashPickupLocations([]);
        });
    }
  }, [countryId, selectedDeliveryOptionServiceID]);

  return { cashPickupLocations, setCashPickupLocations };
}
