import React, { useState } from "react";
import { Button, Nav, Row, Tab } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import cellphone from "../../../../assets/images/icons/cellphone.png";
import money from "../../../../assets/images/icons/money.png";
import deposit from "../../../../assets/images/icons/deposit.png";
import styles from "./SendMoney.module.css";

const SendMoney = () => {
  const [key, setKey] = useState("MobileMoney");
  const { pathname } = useLocation();
  const ugScreen = pathname.includes("ug");
  const navigate = useNavigate();

  const navigateToMobileMoney = () => navigate("/mobile-money");
  const navigateToBankDeposit = () => navigate("/bank-deposit");
  const navigateToCashPickup = () => navigate("/cash-pickup");

  return (
    <div className="row pt-5">
      <div className="col-12">
        <h1 className={styles.send_money__title}>
          Send Money your way from {ugScreen ? "Uganda" : "Kenya"} to the world
        </h1>
      </div>

      <div className="col-12">
        <Tab.Container defaultActiveKey="MobileMoney" activeKey={key} onSelect={(k) => setKey(k)}>
          <Row className="d-inline">
            <Nav variant="pills" className="flex-row border shadow p-0">
              <Nav.Item className="col border-end">
                <Nav.Link
                  eventKey="MobileMoney"
                  className={`${styles.tab_item} ${key === "MobileMoney" ? `${styles.tab_active}` : ""}`}
                >
                  <img
                    className={`${styles.tab_icon} ${key === "MobileMoney" ? `${styles.tab_icon__active}` : ""}`}
                    src={cellphone}
                    alt="Cellphone icon"
                  />
                  <span className={`${styles.tab_title} ${key === "MobileMoney" ? `${styles.tab_title__active}` : ""}`}>
                    Mobile Money{" "}
                  </span>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="col border-end">
                <Nav.Link
                  eventKey="BankDeposit"
                  className={`${styles.tab_item} ${key === "BankDeposit" ? `${styles.tab_active}` : ""}`}
                >
                  <img
                    className={`${styles.tab_icon} ${key === "BankDeposit" ? `${styles.tab_icon__active}` : ""}`}
                    src={deposit}
                    alt="Bank deposit icon"
                  />
                  <span className={`${styles.tab_title} ${key === "BankDeposit" ? `${styles.tab_title__active}` : ""}`}>
                    Bank Deposit
                  </span>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="col">
                <Nav.Link
                  eventKey="CashPickup"
                  className={`${styles.tab_item} ${key === "CashPickup" ? `${styles.tab_active}` : ""}`}
                >
                  <img
                    className={`${styles.tab_icon} ${key === "CashPickup" ? `${styles.tab_icon__active}` : ""}`}
                    src={money}
                    alt="Cash icon"
                  />
                  <span className={`${styles.tab_title} ${key === "CashPickup" ? `${styles.tab_title__active}` : ""}`}>
                    Cash Pickup
                  </span>
                </Nav.Link>
              </Nav.Item>
            </Nav>

            <Tab.Content className="pt-5">
              <Tab.Pane eventKey="MobileMoney">
                <p className={`${styles.send_money__info} text-left`}>
                  Fast and Convenient. Send money swiftly to your loved ones registered mobile money account. There are
                  no hidden fees, you will see our fees upfront.
                </p>

                {pathname.includes("ug") === false && (
                  <Button className={styles.send_now_cta} onClick={navigateToMobileMoney} type="submit">
                    Send Now
                  </Button>
                )}
              </Tab.Pane>
              <Tab.Pane eventKey="BankDeposit">
                <p className={`${styles.send_money__info} text-left`}>
                  Your money is safe. You can send money to your recipient’s Bank account around the world. A great way
                  to send a large amount of cash without having to pay high transfer fees.
                  <br />
                  <br />
                  Fast, easy, and convenient.
                </p>
                {pathname.includes("ug") === false && (
                  <Button className={styles.send_now_cta} onClick={navigateToBankDeposit} type="submit">
                    Send Now
                  </Button>
                )}
              </Tab.Pane>
              <Tab.Pane eventKey="CashPickup">
                <p className={`${styles.send_money__info} text-left`}>
                  Send cash to friends and family with Upesi Money Transfer.
                  <br />
                  <br />
                  The recipient can Pick up cash in most countries from our numerous cash pickup locations.
                  <br></br>
                  <br />
                  Enjoy a simple money transfer process, fast service, and low fees.
                </p>
                {pathname.includes("ug") === false && (
                  <Button className={styles.send_now_cta} onClick={navigateToCashPickup} type="submit">
                    Send Now
                  </Button>
                )}
              </Tab.Pane>
            </Tab.Content>
          </Row>
        </Tab.Container>
      </div>
    </div>
  );
};

export default SendMoney;
