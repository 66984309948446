import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import { addBeneficiary } from "../../../../api/saveBeneficiaryDetails";
import { FormContext } from "../../Beneficiary";
import PersonalInfo from "./PersonalDetails";
import { personalInfoSchema } from "./formValidation";

function PersonalInfoConatiner() {
  const {
    formData,
    paymentService,
    activeServiceProvider,
    apiData: { documentTypes, singleCountryData },
    location,
    beneficiaryData,
    countrySupportsRelation,
    loadingdocumentTypes,
    userId,
  } = useContext(FormContext);
  const navigate = useNavigate();

  const [documentTypeID, setDocumentTypeID] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const handleInputChange = (val, setFieldValue) => {
    setFieldValue(val.target.name, val.target.value);
    formData.setPersonalInfo((prevState) => ({
      ...prevState,
      [val.target.name]: val.target.value,
    }));
  };

  const handleDocumentType = (val, setFieldValue) => {
    setDocumentTypeID(val.id);
    setFieldValue("documentTypeID", val.id);
    setFieldValue("documentType", val.name);
  };

  const handleDocumentExpiryDate = (date, setFieldValue) => {
    formData.setPersonalInfo((prevState) => ({
      ...prevState,
      documentExpiresOn: date,
    }));
    setFieldValue("documentExpiresOn", date);
  };

  const { countryID, countryName, deliveryOption, deliveryOptionId } = formData.deliveryMethod;

  const onSubmit = (values) => {
    formData.setPersonalInfo((prevState) => ({ ...prevState, ...values }));
    // TODO: move this to beneficiary.jsx file
    return addBeneficiary({
      userId,
      values: { ...formData, personalInfo: values, paymentService },
      setIsLoading,
      location,
      navigate,
      beneficiaryData: {
        ...beneficiaryData,
        countryID,
        countryName,
        serviceId: deliveryOptionId,
        serviceName: deliveryOption,
      },
      singleCountryData,
    });
  };

  const personalInfoProps = {
    location,
    isLoading,
    formData,
    handleDocumentType,
    activeServiceProvider,
    handleInputChange,
    handleDocumentExpiryDate,
    documentTypes,
    onSubmit,
    paymentService,
    personalInfoSchema: personalInfoSchema({
      paymentService,
      activeServiceProvider,
      documentTypes,
      documentTypeID,
      countryName,
      countryID,
      countrySupportsRelation,
    }),
    countrySupportsRelation,
  };
  return (
    <>
      {loadingdocumentTypes ? (
        <div className="mainDiv">
          <div className="loading"></div>
        </div>
      ) : null}
      <PersonalInfo personalInfoProps={personalInfoProps} />
    </>
  );
}

export default PersonalInfoConatiner;
