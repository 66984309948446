
export const generateOTP = (length = 6) => {
  if (length < 4) {
    throw new Error("Length must be at least 4 for a valid OTP");
  }

  let otp = "";
  const numbers = "0123456789";
  const alphabets = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

  // Add at least two random numbers
  for (let i = 0; i < 2; i++) {
    otp += numbers.charAt(Math.floor(Math.random() * numbers.length));
  }

  // Add at least two random alphabets
  for (let i = 0; i < 2; i++) {
    otp += alphabets.charAt(Math.floor(Math.random() * alphabets.length));
  }

  // Fill the remaining characters with a mix of numbers and alphabets
  const remainingLength = length - otp.length;
  for (let i = 0; i < remainingLength; i++) {
    const isNumber = Math.random() < 0.5;
    otp += isNumber
      ? numbers.charAt(Math.floor(Math.random() * numbers.length))
      : alphabets.charAt(Math.floor(Math.random() * alphabets.length));
  }


  return otp;
};
