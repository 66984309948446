import React from "react";
import ConfirmationPage from "./ConfirmationPage";

export default function ForgotPasswordConfirmation() {
  return (
    <ConfirmationPage
      message="A password reset link has been sent to your email address."
      cta="Back to login"
      ctalink="/login"
    />
  );
}
